import { Context } from '../../../../core/shared/context.model';
import { InjectionToken } from '@angular/core';
import { GenericConstructor } from '../../../../core/shared/generic-constructor';

const singleStatletWrapperComponentMap = new Map();

export const DEFAULT_STATLET_WRAPPER_CONTEXT = Context.StatletCard;

export const SINGLE_STATLET_WRAPPER_COMPONENT_FACTORY = new InjectionToken<(context?: Context) => GenericConstructor<any>>('getComponentForSingleStatletWrapper', {
  providedIn: 'root',
  factory: () => getComponentForSingleStatletWrapper
});

export function rendersSingleStatletWrapper(context: Context = DEFAULT_STATLET_WRAPPER_CONTEXT) {
  return function decorator(component: any) {
    if (!component) {
      return;
    }
    singleStatletWrapperComponentMap.set(context, component);
  };
}

export function getComponentForSingleStatletWrapper(context: Context = DEFAULT_STATLET_WRAPPER_CONTEXT) {
  if (singleStatletWrapperComponentMap.has(context)) {
    return singleStatletWrapperComponentMap.get(context);
  } else {
    return singleStatletWrapperComponentMap.get(DEFAULT_STATLET_WRAPPER_CONTEXT);
  }
}

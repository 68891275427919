<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'dataset.page.titleprefix' | translate}}
    <ds-metadata-values class="d-inline-block" [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control [item]="object"></ds-atmire-saved-item-list-control>
    <ds-dso-page-edit-button class="pl-1" [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'dataset.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-6">
    <div class="mb-2">
      <a *ngIf="hasFiles() | async" [routerLink]="" (click)="onClick('file-section')">
        {{"item.page.link.download" | translate}}
      </a>
    </div>
    <ds-item-page-rights-field [item]="object"
                                [fields]="['dcterms.accessRights', 'unsw.description.embargoNote', 'dcterms.rightsHolder', 'dcterms.rights']"
                                [label]="'item.page.accessTerms'">
    </ds-item-page-rights-field>
    <ds-item-page-uri-separate-value-field [item]="object"
                                           [fieldURI]="'dc.rights.uri'"
                                           [fieldValue]="'dc.rights'"
                                           [label]="">
    </ds-item-page-uri-separate-value-field>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.researchDataCreator']"
                                [label]="'item.page.researchDataCreator'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.leadChiefInvestigator']"
                                [label]="'item.page.leadChiefInvestigator'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.other']"
                                [label]="'item.page.otherContributor'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.corporate']"
                                [label]="'item.page.corporateContributor'">
    </ds-generic-item-page-field>
  </div>

  <div class="col-xs-12 col-md-4">
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.identifier.uri']"
                            [label]="'item.page.permalink'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object" [prefixValue]="'http://dx.doi.org/'"
                            [addPrefixIfDoesNotContains]="['doi.org']"
                            [fields]="['unsw.identifier.doi']"
                            [label]="'item.page.doi'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object" [prefixValue]="'http://dx.doi.org/'"
                            [addPrefixIfDoesNotContains]="['doi.org']"
                            [fields]="['unsw.identifier.doiPublisher']"
                            [label]="'item.page.externalDataRepository'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['unsw.description.storageURL']"
                            [label]="'item.page.storageUrl'">
    </ds-item-page-uri-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.description.contact']"
                                [label]="'item.page.contact'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.date.issued']"
                                [label]="'item.page.publicationDate'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.subject.other']"
                                [label]="'item.page.keyword'">
    </ds-generic-item-page-field>

    <ds-related-entities-metadata-via-search [item]="object"
                                             [relationType]="{
                                            label: 'item.page.relationships.isDatasetOfAffiliatedOrgUnit',
                                            filter: 'isDatasetOfAffiliatedOrgUnit',
                                            configuration: 'orgunit'
                                          }">
    </ds-related-entities-metadata-via-search>

  </div>

  <div class="col-12">

    <!-- Related Datasets -->
    <ds-metadata-representation-list
      [parentItem]="object"
      [itemType]="'Dataset'"
      [metadataFields]="['unsw.isDatasetRelatedToDataset','unsw.isDatasetRelatingToDataset']"
      [label]="'relation.isDatasetRelatedToDataset' | translate">
    </ds-metadata-representation-list>

    <!-- Related Publications -->
    <ds-metadata-representation-list
      [parentItem]="object"
      [itemType]="'Publication'"
      [metadataFields]="['unsw.isPublicationRelatedToDataset']"
      [label]="'relation.isPublicationRelatedToDataset' | translate">
    </ds-metadata-representation-list>

  </div>

  <div class="col-12">
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.relation.fundingAgency', 'unsw.relation.fundingScheme', 'unsw.relation.FunderRefNo']"
                                [separator]="' - '"
                                [label]="'item.page.grants'">
    </ds-generic-item-page-field>
  </div>

  <div class="col-12">
    <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
    <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.license-files" bundle="LICENSE" [item]="object"></ds-item-page-file-section>
    <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.administration-files" bundle="ADMINISTRATION" [item]="object"></ds-item-page-file-section>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="['/items/' + object.id + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
    <div class="mt-2">
      <a [href]="['/items/' + object.id + '/statistics']">
        {{"item.page.link.statistics" | translate}}
      </a>
    </div>
  </div>
</div>

import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AtmireSavedItemListState } from './atmire-saved-item-list.reducer';

export const atmireSavedItemListSelector = createFeatureSelector<AtmireSavedItemListState>('atmireSavedItemList');

/**
 * Select the current list ID from our {@link AtmireSavedItemListState}
 */
export const atmireSavedItemListsSelector =
  (): MemoizedSelector<AtmireSavedItemListState, { [key: string]: string }> => createSelector(
    atmireSavedItemListSelector, (atmireSavedItemListState: AtmireSavedItemListState) => atmireSavedItemListState.currentLists);

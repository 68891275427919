import { Component } from '@angular/core';

@Component({
  selector: 'ds-shib-redirect-page',
  templateUrl: './shib-redirect-page.component.html',
  styleUrls: ['./shib-redirect-page.component.scss']
})
export class ShibRedirectPageComponent {

}

<div class="w-100 search-header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ng-template *ngTemplateOutlet="searchForm"></ng-template>
      </div>
    </div>
  </div>
</div>

<ds-page-with-sidebar [id]="'search-page'" [sidebarContent]="sidebarContent">
    <div class="row">
        <div id="search-content" class="col-12">
            <div class="d-block d-md-none search-controls clearfix">
                <button (click)="openSidebar()" aria-controls="#search-body"
                        class="btn btn-outline-light float-right open-sidebar"><i
                        class="fas fa-sliders"></i> {{"search.sidebar.open"
                        | translate}}
                </button>
            </div>
            <ds-search-results [searchResults]="resultsRD$ | async"
                               [searchConfig]="searchOptions$ | async"
                               [configuration]="configuration$ | async"
                               [sortOptions]="(sortOptions$ | async)"
                               [disableHeader]="!searchEnabled"
                               [context]="context"></ds-search-results>
        </div>
    </div>
</ds-page-with-sidebar>

<ng-template #sidebarContent>
    <ds-search-sidebar id="search-sidebar" *ngIf="!(isXsOrSm$ | async)"
                       [resultCount]="(resultsRD$ | async)?.payload?.totalElements"
                       [searchOptions]="(searchOptions$ | async)"
                       [sortOptions]="(sortOptions$ | async)"
                       [inPlaceSearch]="inPlaceSearch"></ds-search-sidebar>
    <ds-search-sidebar id="search-sidebar-sm" *ngIf="(isXsOrSm$ | async)"
                       [resultCount]="(resultsRD$ | async)?.payload.totalElements"
                       [searchOptions]="(searchOptions$ | async)"
                       [sortOptions]="(sortOptions$ | async)"
                       [inPlaceSearch]="inPlaceSearch"
                       (toggleSidebar)="closeSidebar()">
    </ds-search-sidebar>
</ng-template>

<ng-template #searchForm>
    <ds-themed-search-form *ngIf="searchEnabled" id="search-form" class="py-0"
                    [query]="(searchOptions$ | async)?.query"
                    [scope]="(searchOptions$ | async)?.scope"
                    [currentUrl]="searchLink"
                    [scopes]="(scopeListRD$ | async)"
                    [inPlaceSearch]="inPlaceSearch"
                    [searchPlaceholder]="!!placeholderScope ? ('search.search-form.placeholder' | translate:
                    {'scope': placeholderScope, 'type': placeholderType}) :
                    ('search.search-form.placeholder.scope.default' | translate)"
                    [scopeDisabled]="scopeDisabled" >
    </ds-themed-search-form>
</ng-template>

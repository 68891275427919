import { Component } from '@angular/core';
import { SEARCH_CONFIG_SERVICE } from '../../../../../../../my-dspace-page/my-dspace-page.component';
import { SearchConfigurationService } from '../../../../../../../core/shared/search/search-configuration.service';
import { VocabularyService } from '../../../../../../../core/submission/vocabularies/vocabulary.service';
import { RemoteData } from '../../../../../../../core/data/remote-data';
import { PaginatedList } from '../../../../../../../core/data/paginated-list.model';
import { VocabularyEntry } from '../../../../../../../core/submission/vocabularies/models/vocabulary-entry.model';
import { Observable } from 'rxjs/internal/Observable';
import { hasValue } from '../../../../../../empty.util';
import { VocabularyEntryListElement } from '../models/vocabulary-entry-list-element.model';
import { FormFieldMetadataValueObject } from '../../../../models/form-field-metadata-value.model';
import { PaginationService } from '../../../../../../../core/pagination/pagination.service';
import { AtmireSearchPaginationTabComponent } from '../atmire-search-pagination-tab.component';
import { PaginationComponentOptions } from '../../../../../../pagination/pagination-component-options.model';

@Component({
  selector: 'ds-atmire-search-local-authority-tab',
  styleUrls: ['./atmire-search-local-authority-tab.component.scss'],
  templateUrl: './atmire-search-local-authority-tab.component.html',
  providers: [
    {
      provide: SEARCH_CONFIG_SERVICE,
      useClass: SearchConfigurationService
    }
  ]
})
export class AtmireSearchLocalAuthorityTabComponent extends AtmireSearchPaginationTabComponent<VocabularyEntry> {

  constructor(protected vocabularyService: VocabularyService,
              protected searchService: SearchConfigurationService,
              protected paginationService: PaginationService) {
    super(searchService, paginationService);
  }

  findObjects(query: string, pagination: PaginationComponentOptions): Observable<RemoteData<PaginatedList<VocabularyEntry>>> {
    return this.vocabularyService.getVocabularyEntriesByValue(query, false, this.model.vocabularyOptions, Object.assign({
      elementsPerPage: pagination.pageSize,
      currentPage: pagination.currentPage,
    }));
  }

  getSelectedObjects(object: VocabularyEntry): FormFieldMetadataValueObject[] {
    return this.selectedValues.filter((value) => hasValue(value) && value.authority === object.authority);
  }

  getValueFromObject(object: VocabularyEntry): string {
    return object.display;
  }

  createListElement(object: VocabularyEntry, values: string[], selected: boolean): VocabularyEntryListElement {
    return new VocabularyEntryListElement(object, values, selected);
  }
}

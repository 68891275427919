import { Component } from '@angular/core';
import { getItemPageRoute } from '../../../../../item-page/item-page-routing-paths';
import { DSONameService } from '../../../../../core/breadcrumbs/dso-name.service';
import { ViewMode } from '../../../../../core/shared/view-mode.model';
import { isNotEmpty } from '../../../../empty.util';
import { listableObjectComponent } from '../../../../object-collection/shared/listable-object/listable-object.decorator';
import { AbstractListableElementComponent } from '../../../../object-collection/shared/object-collection-element/abstract-listable-element.component';
import { Item } from '../../../../../core/shared/item.model';
import { TruncatableService } from '../../../../truncatable/truncatable.service';

@listableObjectComponent('Publication', ViewMode.ListElement)
@listableObjectComponent(Item, ViewMode.ListElement)
@Component({
  selector: 'ds-item-list-element',
  styleUrls: ['./item-list-element.component.scss'],
  templateUrl: './item-list-element.component.html'
})
/**
 * The component for displaying a list element for an item of the type Publication
 */
export class ItemListElementComponent extends AbstractListableElementComponent<Item> {
   /**
    * The DSpaceObject of the search result
    */
  dso: Item;
  dsoTitle: string;
  /**
   * Route to the item's page
   */
  itemPageRoute: string;

  public constructor(protected truncatableService: TruncatableService, protected dsoNameService: DSONameService) {
    super();
  }

   /**
    * Retrieve the dso from the search result
    */
  ngOnInit(): void {
    if (this.hasObject()) {
      this.dso = this.object;
      this.dsoTitle = this.dsoNameService.getName(this.dso);
      this.itemPageRoute = getItemPageRoute(this.dso);
      this.linkType = this.linkTypes.ExternalLink;
    }
  }

  hasObject(): boolean {
    return isNotEmpty(this.object);
  }

}

import { Component } from '@angular/core';
import { OrgUnitListElementComponent as BaseComponent } from '../../../../../../../app/entity-groups/research-entities/item-list-elements/org-unit/org-unit-list-element.component';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';

@listableObjectComponent('OrgUnit', ViewMode.ListElement)
@Component({
  selector: 'ds-org-unit-list-element',
  // styleUrls: ['../../../../../../../app/entity-groups/research-entities/item-list-elements/org-unit/org-unit-list-element.component.scss'],
  styleUrls: ['./org-unit-list-element.component.scss'],
  // templateUrl: '../../../../../../../app/entity-groups/research-entities/item-list-elements/org-unit/org-unit-list-element.component.html',
  templateUrl: './org-unit-list-element.component.html',
})
/**
 * The component for displaying a list element for an item of the type Organisation Unit
 */
export class OrgUnitListElementComponent extends BaseComponent {
}

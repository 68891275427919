import { Component, Inject } from '@angular/core';
import { TextMenuItemModel } from './models/text.model';
import { MenuItemType } from '../initial-menus-state';
import { rendersMenuItemForType } from '../menu-item.decorator';
import { MenuItemComponent } from './menu-item.component';
import { Router } from '@angular/router';

/**
 * Component that renders a menu section of type TEXT
 */
@Component({
    selector: 'ds-text-menu-item',
    templateUrl: './text-menu-item.component.html',
})
@rendersMenuItemForType(MenuItemType.TEXT)
export class TextMenuItemComponent extends MenuItemComponent<TextMenuItemModel> {
    item: TextMenuItemModel;

    constructor(@Inject('itemModelProvider') item: TextMenuItemModel,
                public router: Router) {
        super(item, router);
    }
    ngOnInit() {
        super.ngOnInit();
    }
}

import { Component, Input } from '@angular/core';

import { Item } from '../../../../../core/shared/item.model';
import { ItemPageFieldComponent } from '../item-page-field.component';

@Component({
  selector: 'ds-item-page-uri-separate-value-field',
  templateUrl: './uri-separate-value-field.component.html'
})
/**
 * This component can be used to represent a metadata value linked to an uri from another metadata value field on a simple item page.
 * It expects 4 parameters: The item, a separator, the metadata keys for both the value and the uri fields and an i18n key
 * If there are more values for fieldValue than fieldURI => renders remaining fieldValue as non-uri
 * If there are more values for fieldURI than fieldValue => renders remaining fieldURI as uri
 */
export class UriSeparateValueFieldComponent extends ItemPageFieldComponent {

  /**
   * The item to display metadata for
   */
  @Input() item: Item;

  /**
   * Separator string between multiple values of the metadata fields defined
   * @type {string}
   */
  @Input() separator: string;

  /**
   * Field (schema.element.qualifier) used to render their values.
   */
  @Input() fieldValue: string;

  /**
   * Field (schema.element.qualifier) used to render their values.
   */
  @Input() fieldURI: string;

  /**
   * Label i18n key for the rendered metadata
   */
  @Input() label: string;

  /**
   * Get corresponding URI value at given index, or null if there is no uri value at that index
   * @param index   Index of the metadata values of the fieldValue field we are at
   */
  getCorrespondingURI(index: number): string {
    const uriValues = this.item.allMetadata(this.fieldURI);
    if (uriValues.length > index) {
      return uriValues[index].value;
    }
    return null;
  }
}

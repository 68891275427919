import { Component, Input, OnInit } from '@angular/core';

import { Item } from '../../../../../core/shared/item.model';
import { ItemPageFieldComponent } from '../item-page-field.component';
import { MetadataValue } from '../../../../../core/shared/metadata.models';
import { TranslateService } from '@ngx-translate/core';

// tslint:disable-next-line:max-classes-per-file
class ConditionalPrefix {
  constructor(
    public conditionField: string,
    public conditionValue: string,
    public usedField: string,
    public prefixName: string) {
  }
}

const RIGHTS_CONDITIONAL_PREFIX = new ConditionalPrefix('dspace.entity.type', 'Dataset', 'dcterms.rightsHolder', 'rights.copyright');
const COPYRIGHT_CONDITIONAL_PREFIX = new ConditionalPrefix('dc.type', 'Thesis', 'dcterms.rightsHolder', 'rights.copyright');

// tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'ds-item-page-rights-field',
  templateUrl: 'item-page-rights-field.component.html'
})
/**
 * This component is used for displaying the rights metadata of an item
 */
export class ItemPageRightsFieldComponent extends ItemPageFieldComponent implements OnInit {

  publisherLicenseMdField = 'unsw.description.publisherLicense';
  publisherLicenseUriMdField = 'unsw.description.publisherLicenseURI';

  /**
   * The item to display metadata for
   */
  @Input() item: Item;

  @Input() fields: string[];

  /**
   * Label i18n key for the rendered metadata
   */
  @Input() label: string;

  metadataValues: MetadataValue[];

  exceptionList = [COPYRIGHT_CONDITIONAL_PREFIX, RIGHTS_CONDITIONAL_PREFIX];

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.metadataValues = [].concat(
      ...this.fields.map((field) => {
        let fieldValues = this.item.allMetadata(field);
        this.exceptionList.forEach((exception) => {
            fieldValues = this.parseMetadata(this.item, field, fieldValues, exception);
            fieldValues = this.wrapPublisherLicensesInLink(field, fieldValues);
          }
        );
        return fieldValues;
      })
    );
  }

  parseMetadata(item: Item, field: string, values: MetadataValue[], conditionalPrefix: ConditionalPrefix): MetadataValue[] {
    let metadata = values;
    const prefixMessage = this.translate.instant('item.page.prefix.' + conditionalPrefix.prefixName);
    if (field === conditionalPrefix.usedField && item.allMetadataValues(conditionalPrefix.conditionField).includes(conditionalPrefix.conditionValue)) {
      metadata = values.map((value: MetadataValue) => {
        return Object.assign(new MetadataValue(), value, { value: prefixMessage + value.value });
      });
    }
    return metadata;
  }

  /**
   * When the metadata value is associated with {@link publisherLicenseMdField},
   * wrap it in an anchor tag that points to the first value of {@link publisherLicenseUriMdField}.
   * The template of {@link MetadataValuesComponent} has been altered to render html.
   */
  wrapPublisherLicensesInLink(field: string, fieldValues: MetadataValue[]): MetadataValue[] {
    if (field !== this.publisherLicenseMdField) {
      return fieldValues;
    }

    const licenseUri = this.item.firstMetadataValue(this.publisherLicenseUriMdField);

    return fieldValues.map((fieldValue: MetadataValue) => Object.assign(
      new MetadataValue(), fieldValue, {
        value: `<a href="${licenseUri}" target="_blank" rel="noopener noreferrer">${fieldValue.value}</a>`
      }
    ));
  }
}

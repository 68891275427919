import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldMetadataValueObject } from '../../models/form-field-metadata-value.model';
import { hasValue, isNotEmpty } from '../../../../empty.util';
import { AtmireSearchListener } from '../models/atmire-search/atmire-search-listener.model';
import { AtmireSearchEditValueAction } from './atmire-search-edit-value-action.model';
import { Observable } from 'rxjs/internal/Observable';
import { VocabularyService } from '../../../../../core/submission/vocabularies/vocabulary.service';
import { SEARCH_CONFIG_SERVICE } from '../../../../../my-dspace-page/my-dspace-page.component';
import { SearchConfigurationService } from '../../../../../core/shared/search/search-configuration.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { getFirstSucceededRemoteDataPayload } from '../../../../../core/shared/operators';
import { ExternalSourceService } from '../../../../../core/data/external-source.service';
import { PaginatedSearchOptions } from '../../../../search/paginated-search-options.model';
import { PaginationComponentOptions } from '../../../../pagination/pagination-component-options.model';
import { Router } from '@angular/router';
import { AtmireSearchAddValueAction } from './atmire-search-add-value-action.model';

@Component({
  selector: 'ds-dynamic-atmire-search-modal',
  styleUrls: ['./dynamic-atmire-search-modal.component.scss'],
  templateUrl: './dynamic-atmire-search-modal.component.html',
  providers: [
    {
      provide: SEARCH_CONFIG_SERVICE,
      useClass: SearchConfigurationService
    }
  ]
})
export class DynamicAtmireSearchModalComponent implements OnInit {
  query: string;
  selectedValues: FormFieldMetadataValueObject[];
  model: any;
  clickedValue: FormFieldMetadataValueObject;
  initialTab: string;
  searchListener: AtmireSearchListener;

  query$: Observable<string>;
  localAuthoritiesCount$: Observable<number>;
  externalSourceCount$: {
    [externalSource: string]: Observable<number>
  } = {};

  constructor(
    public modal: NgbActiveModal,
    protected searchService: SearchConfigurationService,
    protected vocabularyService: VocabularyService,
    protected externalSourceService: ExternalSourceService,
    protected router: Router,
  ) {
  }

  ngOnInit(): void {
    this.updateSelectedValues();

    this.router.navigate([], {
      queryParams: Object.assign({ query: this.query }),
      queryParamsHandling: 'merge'
    });

    this.query$ = this.searchService.getCurrentQuery(this.query);
    this.model.externalSources.forEach((externalSource) => {
      this.externalSourceCount$[externalSource] = this.query$.pipe(
        switchMap((query) => this.externalSourceService.getExternalSourceEntries(externalSource, new PaginatedSearchOptions({
          query,
          pagination: Object.assign(new PaginationComponentOptions(), {
            pageSize: 1,
          }),
        })).pipe(
          getFirstSucceededRemoteDataPayload(),
          map((entries) => entries.totalElements)
        )),
        startWith(0),
      );
    });
    this.localAuthoritiesCount$ = this.query$.pipe(
      switchMap((query) => {
        if (isNotEmpty(query)) {
          return this.vocabularyService.getVocabularyEntriesByValue(query, false, this.model.vocabularyOptions, Object.assign({
            elementsPerPage: 1
          })).pipe(
            getFirstSucceededRemoteDataPayload(),
            map((entries) => entries.totalElements),
          );
        } else {
          return [0];
        }
      }),
      startWith(0),
    );
  }

  updateModel(model: any) {
    this.model = model;
  }

  updateSelectedValues() {
    this.selectedValues = [];
    if (this.model.repeatable) {
      if (hasValue(this.model.parent) && hasValue(this.model.parent.parent) && isNotEmpty(this.model.parent.parent.groups)) {
        this.selectedValues = this.model.parent.parent.groups.map((group) => group.group[0].value);
      }
    } else {
      this.selectedValues = [this.model.value];
    }
  }

  updateSelectedValueSelect(value, index?: number) {
    if (this.model.repeatable) {
      if (hasValue(index) && index < this.selectedValues.length) {
        this.selectedValues[index] = value;
      } else {
        this.selectedValues.push(value);
      }
    } else {
      this.selectedValues = [value];
    }
  }

  select(action: AtmireSearchAddValueAction) {
    this.searchListener.select$.next(action);
  }

  edit(action: AtmireSearchEditValueAction) {
    this.searchListener.edit$.next(action);
  }

  deselect(index: number) {
    this.selectedValues.splice(index, 1);
    this.searchListener.deselect$.next(index);
  }

}

<header>
    <div class="container">
        <div class="d-flex flex-row justify-content-between">
            <a class="navbar-brand my-2" routerLink="/home">
                <img src="assets/images/dspace-logo.svg" [attr.alt]="'menu.header.image.logo' | translate"/>
            </a>

            <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="navbar navbar-light navbar-expand-md flex-shrink-0 px-0">
                <ds-search-navbar></ds-search-navbar>
                <ds-lang-switch></ds-lang-switch>
                <ds-atmire-saved-item-list-link></ds-atmire-saved-item-list-link>
                <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
                <ds-impersonate-navbar></ds-impersonate-navbar>
                <div class="pl-2">
                    <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                            aria-controls="collapsingNav"
                            aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
                        <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
                    </button>
                </div>
            </nav>
        </div>
    </div>
</header>

import { Component } from '@angular/core';
import { AbstractAtmireSearchListElementComponent } from '../../list-element/abstract-atmire-search-list-element.component';
import { ExternalSourceEntry } from '../../../../../../../../core/shared/external-source-entry.model';
import { ViewMode } from '../../../../../../../../core/shared/view-mode.model';
import { Context } from '../../../../../../../../core/shared/context.model';

@Component({
  selector: 'ds-atmire-search-external-source-entry-list-element',
  styleUrls: ['./atmire-search-external-source-entry-list-element.component.scss'],
  templateUrl: './atmire-search-external-source-entry-list-element.component.html',
})
export class AtmireSearchExternalSourceEntryListElementComponent extends AbstractAtmireSearchListElementComponent<ExternalSourceEntry> {
  viewMode = ViewMode.ListElement;
  context = Context.EntitySearchModal;
}

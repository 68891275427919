import { ThemedComponent } from '../theme-support/themed.component';
import { SearchFormComponent } from './search-form.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DSpaceObject } from '../../core/shared/dspace-object.model';
import { RootComponent } from '../../root/root.component';

@Component({
  selector: 'ds-themed-search-form',
  templateUrl: '../../shared/theme-support/themed.component.html',
})
export class ThemedSearchFormComponent extends ThemedComponent<SearchFormComponent> {
  /**
   * The search query
   */
  @Input() query: string;

  /**
   * True when the search component should show results on the current page
   */
  @Input() inPlaceSearch;

  /**
   * The currently selected scope object's UUID
   */
  @Input() scope = '';

  @Input() currentUrl: string;

  /**
   * The available scopes
   */
  @Input() scopes: DSpaceObject[];

  /**
   * Whether or not the search button should be displayed large
   */
  @Input() large = false;

  /**
   * Whether or not the scope selection should be disabled
   */
  @Input() scopeDisabled = false;

  /**
   * The brand color of the search button
   */
  @Input() brandColor = 'primary';

  /**
   * The placeholder of the search input
   */
  @Input() searchPlaceholder: string;

  /**
   * Output the search data on submit
   */
  @Output() submitSearch = new EventEmitter<any>();

  inAndOutputNames: (keyof SearchFormComponent & keyof this)[] = [
    'query', 'inPlaceSearch', 'scope', 'currentUrl', 'scopes',
    'large', 'scopeDisabled', 'brandColor', 'searchPlaceholder', 'submitSearch',
  ];

  protected getComponentName(): string {
    return 'SearchFormComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/shared/search-form/search-form.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import('./search-form.component');
  }

}

<div class="card" [@focusShadow]="(isCollapsed$ | async)?'blur':'focus'">
    <ds-truncatable [id]="dso.id">
        <div class="position-absolute ml-1">
            <ng-content></ng-content>
        </div>
        <a *ngIf="linkType != linkTypes.None"
           [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
           rel="noopener noreferrer" [routerLink]="[itemPageRoute]"
           class="card-img-top full-width">
            <div>
                <ds-thumbnail [thumbnail]="getThumbnail() | async" [limitWidth]="false">
                </ds-thumbnail>
            </div>
        </a>
        <span *ngIf="linkType == linkTypes.None" class="card-img-top full-width">
            <div>
                <ds-thumbnail [thumbnail]="getThumbnail() | async" [limitWidth]="false">
                </ds-thumbnail>
            </div>
        </span>
        <div class="card-body">
            <ds-truncatable-part [id]="dso.id" [minLines]="3" type="h4">
                <h4 class="card-title text-center" [innerHTML]="firstMetadataValue(['organization.legalName','dc.title'])"></h4>
            </ds-truncatable-part>
        </div>
    </ds-truncatable>
    <ng-content></ng-content>
</div>

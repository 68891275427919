<ng-container *ngVar="(items$ | async) as items">
  <ds-metadata-field-wrapper *ngIf="items.length > 0" [label]="relationType.label">
    <ds-listable-object-component-loader *ngFor="let item of items"
                                         [object]="item" [viewMode]="viewMode">
    </ds-listable-object-component-loader>
    <ds-loading *ngIf="isLoading" message="{{'loading.default' | translate}}"></ds-loading>
    <div class="d-inline-block w-100 mt-2" *ngIf="!isLoading && (!isLastPage || currentPage != 1)">
      <div *ngIf="!isLastPage" id="view-more">
        <a [routerLink]=""
           (click)="getNextPage()">{{'item.page.bitstreams.view-more' | translate }}</a>
      </div>
      <div *ngIf="isLastPage && currentPage != 1" id="view-less">
        <a [routerLink]=""
           (click)="currentPage = undefined; getNextPage();">{{'item.page.bitstreams.collapse' | translate}}</a>
      </div>
    </div>
  </ds-metadata-field-wrapper>
</ng-container>

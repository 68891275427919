/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { SearchResult } from '../../../../app/shared/search/search-result.model';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';

export class DsoSelectorOption {
  id: string;
  name: string;
  dso: SearchResult<DSpaceObject>;

  constructor(dso: SearchResult<DSpaceObject>, name: string) {
    this.dso = dso;
    this.name = name;
    this.id = dso.indexableObject.id;
  }
}

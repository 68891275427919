import { URLCombiner } from '../../core/url-combiner/url-combiner';

/**
 * A class to configure the retrieval of a {@link HALLink}
 */
export class Projection {

  name: string;

  paramKey: string;

  paramValues: string[];

}

export const createProjection = (
  name: string,
  paramKey: string,
  ...paramValues: string[]
): Projection => {
  return {
    name,
    paramKey,
    paramValues
  };
};

export function addProjectionToHref(href: string, projection: Projection): string {
  const args = [];
  args.push(`projection=${projection.name}`);
  projection.paramValues.forEach((param) => {
    args.push(`${projection.paramKey}=${param}`);
  });

  return new URLCombiner(href, `?${args.join('&')}`).toString();

}

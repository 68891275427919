<div class="row">
  <div class="col-4">
    <h3>{{ 'search.sidebar.settings.title' | translate}}</h3>
    <ds-page-size-selector></ds-page-size-selector>
  </div>

  <div class="col-8">
    <ds-search-form [query]="query$ | async"
                    [inPlaceSearch]="true"
                    [searchPlaceholder]="'atmire.submission.search-modal.local-authorities.search-placeholder' | translate">
    </ds-search-form>

    <ds-search-labels [inPlaceSearch]="true"></ds-search-labels>

    <h3>{{ 'atmire.submission.search-modal.local-authorities.search-results' | translate }}</h3>
    <div *ngVar="(RD$ | async) as RD">
      <ds-pagination *ngIf="RD !== null"
        [paginationOptions]="defaultPagination"
        [collectionSize]="RD?.payload?.totalElements"
        [hideGear]="true"
        (pageChange)="onPageChange($event)">
        <ul *ngIf="RD?.hasSucceeded" class="list-unstyled ml-4">
          <li *ngFor="let element of (elements$ | async); let i = index; let last = last" class="mt-4 mb-4 d-flex">
            <input class="form-check-input align-self-center" type="checkbox"
                   [name]="'checkbox' + i"
                   [id]="'object' + i"
                   [(ngModel)]="element.selected"
                   (ngModelChange)="selectElement(element)">
            <ds-atmire-search-vocabulary-entry-list-element class="w-100" [element]="element" (edit)="editElement($event)"></ds-atmire-search-vocabulary-entry-list-element>
          </li>
        </ul>
        <ds-loading *ngIf="RD?.isLoading">{{ 'atmire.submission.search-modal.local-authorities.loading' | translate }}</ds-loading>
        <ds-alert *ngIf="RD?.payload?.page?.length === 0" [type]="AlertTypeEnum.Info" [content]="'atmire.submission.search-modal.local-authorities.empty' | translate"></ds-alert>
      </ds-pagination>
      <ds-alert *ngIf="RD === null" [type]="AlertTypeEnum.Info" [content]="'atmire.submission.search-modal.local-authorities.' + model.name | translate"></ds-alert>
    </div>
  </div>
</div>

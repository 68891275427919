<div class="d-inline-block w-100">
  <div class="d-flex w-100">
    <p class="lead mb-0 mr-auto">{{ title }}</p>
    <div *ngIf="hasExtraMetadata && extraMetadataSectionEnabled" class="metadata-toggle mx-2" role="button" tabindex="0"
          (click)="expanded = !expanded"
          (keyup.enter)="expanded = !expanded"
          (keydown.space)="$event.stopPropagation(); $event.preventDefault()"
          (keyup.space)="expanded = !expanded"
          [attr.aria-expanded]="expanded">
      <i [ngClass]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-left'"></i>
    </div>
  </div>
  <div *ngIf="orcid">
    <a *ngIf="uri" target="_blank" [href]="uri.value">{{ orcid.value }}</a>
    <span *ngIf="!uri">{{ orcid.value }}</span>
  </div>
  <ul class="list-unstyled" *ngIf="grantNo || refNo">
    <li *ngIf="refNo"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.refNo' | translate }}</span>{{ refNo.value }}</li>
    <li *ngIf="grantNo"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.grantNo' | translate }}</span>{{ grantNo.value }}</li>
  </ul>
  <div *ngIf="extraMetadataSectionEnabled"
       class="overflow-hidden"
       [@slide]="expanded ? 'expanded' : 'collapsed'"
       [class]="expanded ? 'visible' : 'invisible'"> <!-- setting visibility: hidden ensures the content isn't included in the tab order-->
    <ul class="list-unstyled">
    <li *ngIf="lastName"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.lastName' | translate }}</span>{{ lastName.value }}</li>
    <li *ngIf="firstName"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.firstName' | translate }}</span>{{ firstName.value }}</li>
    <li *ngIf="personTitle"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.title' | translate }}</span>{{ personTitle.value }}</li>
    <li *ngIf="orcid"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.orcid' | translate }}</span>{{ orcid.value }}</li>
    <li *ngIf="faculty"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.faculty' | translate }}</span>{{ faculty.value }}</li>
    <li *ngIf="school"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.school' | translate }}</span>{{ school.value }}</li>
    <li *ngIf="position"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.position' | translate }}</span>{{ position.value }}</li>

    <li *ngIf="projectTitle"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.projectTitle' | translate }}</span>{{ projectTitle.value }}</li>
    <li *ngIf="projectStartDate"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.projectStartDate' | translate }}</span>{{ projectStartDate.value }}</li>
    <li *ngIf="projectEndDate"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.projectEndDate' | translate }}</span>{{ projectEndDate.value }}</li>
    <li *ngIf="projectDesc"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.projectDesc' | translate }}</span>{{ projectDesc.value }}</li>
    <li *ngIf="fundingAgency"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.fundingAgency' | translate }}</span>{{ fundingAgency.value }}</li>
    <li *ngIf="fundingScheme"><span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.fundingScheme' | translate }}</span>{{ fundingScheme.value }}</li>

    <li *ngIf="grantZID.length > 0">
      <span class="font-weight-bold">{{ 'atmire.submission.search-modal.list-element.grantZID' | translate }}</span>
      <span *ngFor="let grantZ of grantZID; let last = last">{{ grantZ.value }}<span *ngIf="!last">; </span></span>
    </li>

    <ng-container *ngIf="linkedAuthorityValues$">
      <ng-container *ngVar="(linkedAuthorityValues$ | async) as linkedAuthorityValues">
        <li *ngFor="let linkedAuthorityValue of linkedAuthorityValues">
          <span class="font-weight-bold">{{ ('atmire.submission.search-modal.list-element.linked-authority.' + linkedAuthorityValue.link) | translate }}</span>
          <span *ngFor="let linkedAuthority of linkedAuthorityValue.values; let last = last">{{ linkedAuthority }}<span *ngIf="!last">; </span></span>
        </li>
      </ng-container>
    </ng-container>
  </ul></div>
</div>

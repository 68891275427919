<ng-template #descTemplate>
    <span *ngIf="orcidID" class="item-list-job-title">
                  <span [innerHTML]="orcidID"></span>
    </span>
</ng-template>
<ds-truncatable [id]="metadataRepresentation.id">
  <a *ngIf="uri" [innerHTML]="metadataRepresentation.getValue()"
     [ngbTooltip]="orcidID ? descTemplate : null"
     href="{{uri}}"
     target="_blank"></a>
  <span *ngIf="!uri" [innerHTML]="metadataRepresentation.getValue()"
     [ngbTooltip]="orcidID ? descTemplate : null"></span>
</ds-truncatable>
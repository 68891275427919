import { StatletDsoDataTypeService } from './statlet-dso-data-type.service';
import { statletDataTypeService } from './statlet-data-type.decorator';
import { DSONameService } from '../../../../../core/breadcrumbs/dso-name.service';
import { Injectable } from '@angular/core';
import { ItemDataService } from '../../../../../core/data/item-data.service';
import { StatletPointRoute } from '../../data/models/statlet.utils';
import { getEntityPageRoute } from '../../../../../item-page/item-page-routing-paths';

@Injectable()
@statletDataTypeService('dso', 'ITEM')
export class StatletDsoItemDataTypeService extends StatletDsoDataTypeService {
  constructor(public dsoNameService: DSONameService,
              public dataService: ItemDataService) {
    super(dsoNameService);
  }

  getLinkByValue(value: string): StatletPointRoute {
    if (value.match(this.uuidRegex)) {
      return {href: getEntityPageRoute(null, value)};
    } else {
      return null;
    }
  }
}

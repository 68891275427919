import { Component } from '@angular/core';
import { AtmireAuthorityItemMetadataListElementComponent as BaseComponent } from '../../../../../../app/core/atmire-authorities/atmire-authority/atmire-authority-item-metadata-list-element.component';
import { metadataRepresentationComponent, } from '../../../../../../app/shared/metadata-representation/metadata-representation.decorator';
import { MetadataRepresentationType } from '../../../../../../app/core/shared/metadata-representation/metadata-representation.model';
import { Metadata } from '../../../../../../app/core/shared/metadata.utils';
import { URLCombiner } from '../../../../../../app/core/url-combiner/url-combiner';
import { DEFAULT_CONTEXT } from '../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';

@metadataRepresentationComponent('person', MetadataRepresentationType.AuthorityControlled, DEFAULT_CONTEXT, 'unsw')
@Component({
  selector: 'ds-atmire-authority-item-metadata-list-element',
  templateUrl: './atmire-authority-item-metadata-list-element.component.html'
})
/**
 * This component represents a detail page for a single community
 */
export class AtmireAuthorityItemMetadataListElementComponent extends BaseComponent {
  ngOnInit(): void {
    this.orcidID = Metadata.firstValue(this.metadataRepresentation.metadata, 'ac.person.orcid');
    this.uri = `https://orcid.org/${this.orcidID}`;
  }

  get browseLink(): string {
    const display = this.metadataRepresentation.getValue();
    const authority = (this.metadataRepresentation as any).value;

    return new URLCombiner(
      '/browse',
      `author?value=${encodeURI(display)}&authority=${authority}`
      // todo: hard-coding browse endpoint for now.
      //       This will cause a mess if we ever use this for a field that corresponds to a different browse endpoint.
    ).toString();
  }
}

import { Component, Inject } from '@angular/core';
import { MenuItemType } from '../initial-menus-state';
import { rendersMenuItemForType } from '../menu-item.decorator';
import { OnClickMenuItemModel } from './models/onclick.model';
import { Router } from '@angular/router';
import { MenuItemComponent } from './menu-item.component';

/**
 * Component that renders a menu section of type ONCLICK
 */
@Component({
    selector: 'ds-onclick-menu-item',
    styleUrls: ['./onclick-menu-item.component.scss'],
    templateUrl: './onclick-menu-item.component.html'
})
@rendersMenuItemForType(MenuItemType.ONCLICK)
export class OnClickMenuItemComponent extends MenuItemComponent<OnClickMenuItemModel> {
    constructor(@Inject('itemModelProvider') item: OnClickMenuItemModel,
                public router: Router) {
        super(item, router);
    }

    ngOnInit() {
        super.ngOnInit();
    }
}

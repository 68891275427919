<div class="d-flex align-items-center">
  <div class="flex-grow-1">
    <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
      <div class="item-list-title-line">
        <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"
           [routerLink]="[itemPageRoute]" class="lead item-list-title"
           [innerHTML]="dsoTitle"></a>
        <div class="ml-2">
          <ds-atmire-saved-item-list-control [item]="dso"></ds-atmire-saved-item-list-control>
        </div>
      </div>
      <span *ngIf="linkType == linkTypes.None" class="lead item-list-subtitle"
            [innerHTML]="dsoTitle"></span>
      <span class="text-muted">
          <ds-truncatable-part [id]="dso.id" [minLines]="1">
            <ng-container *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">
              (<span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)
            </ng-container>
            <span *ngIf="dso.allMetadata(authorFields).length > 0" class="item-list-authors">
              <span *ngFor="let author of allMetadataValues(authorFields); let last=last;">
                  <span [innerHTML]="author"></span><span *ngIf="!last">; </span>
              </span>
            </span>
          </ds-truncatable-part>
        </span>
      <ds-item-list-repeatable-md-badge [object]="dso" [fields]="['dc.type']"></ds-item-list-repeatable-md-badge>
      <div *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
        <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
          [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
        </ds-truncatable-part>
      </div>
    </ds-truncatable>
  </div>
</div>
<hr/>

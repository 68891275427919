import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StatletDataService } from '../data/services/statlet-data.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'ds-atmire-cua-statlets-show-hide-button',
  templateUrl: './statlets-show-hide-button.component.html',
})
export class StatletsShowHideButtonComponent implements OnInit {
  statletsShown$: Observable<boolean>;

  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();

  constructor(protected statletService: StatletDataService) {
  }

  ngOnInit(): void {
    this.statletsShown$ = this.statletService.statletsShown();
  }

  /**
   * To make the statlets visible
   */
  showStatlets() {
    this.statletService.showStatlets();
    this.show.emit();
  }

  /**
   * To stop displaying the statlets
   */
  hideStatlets() {
    this.statletService.hideStatlets();
    this.hide.emit();
  }
}

/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { DSpaceObjectType } from '../../../../app/core/shared/dspace-object-type.model';
import { SearchFilter } from '../../../../app/shared/search/search-filter.model';
import { PaginatedSearchOptions } from '../../../../app/shared/search/paginated-search-options.model';
import {
  getAllSucceededRemoteDataPayload,
  getFirstSucceededRemoteDataPayload
} from '../../../../app/core/shared/operators';
import { map, switchMap, take } from 'rxjs/operators';
import { DsoSelectorOption } from '../models/dso-selector-option.model';
import { hasNoValue, hasValue, hasValueOperator, isNotEmpty } from '../../../../app/shared/empty.util';
import { Observable, of, zip as observableZip } from 'rxjs';
import { SearchService } from '../../../../app/core/shared/search/search.service';
import { DSONameService } from '../../../../app/core/breadcrumbs/dso-name.service';

/**
 * Service providing utility methods for dso-selector components
 */
@Injectable()
export class DsoSelectorService {
  defaultPagination = { id: 'dso-selector', currentPage: 1, pageSize: 50 } as any;
  singlePagination = { id: 'dso-selector', currentPage: 1, pageSize: 1 } as any;

  constructor(protected searchService: SearchService,
              protected nameService: DSONameService) {
  }

  /**
   * Search for DSOs using the provided query, dso types and filters
   * Return them wrapped in a DsoSelectorOption each, with the already selected objects on top of the list
   * @param query       Query to search objects
   * @param types       Types of DSOs it should return
   * @param filters     Search filters to apply
   * @param selectedIds Already selected object IDs
   */
  getOptions(query: string, types: DSpaceObjectType[], filters: SearchFilter[] = [], ...selectedIds: string[]): Observable<DsoSelectorOption[]> {
    return this.searchService.search(
      new PaginatedSearchOptions({
        query: query,
        dsoTypes: types,
        filters: filters,
        pagination: this.defaultPagination
      })
    ).pipe(
      getAllSucceededRemoteDataPayload(),
      switchMap((list) => {
        const options: DsoSelectorOption[] = list.page.map((searchResult) => new DsoSelectorOption(searchResult, this.nameService.getName(searchResult.indexableObject)));
        let selectedOptions$: Observable<DsoSelectorOption[]>;
        const selectedIdsFiltered = selectedIds.filter((id) => isNotEmpty(id));
        if (isNotEmpty(selectedIdsFiltered)) {
          selectedOptions$ = observableZip(...selectedIdsFiltered.map((id) => this.searchService.search(
            new PaginatedSearchOptions({
              query: `search.resourceid:${id}`,
              dsoTypes: types,
              filters: filters,
              pagination: this.singlePagination
            })
          ).pipe(
            getFirstSucceededRemoteDataPayload(),
            map((searchResultList) => isNotEmpty(searchResultList.page) ? searchResultList.page[0] : null),
            hasValueOperator(),
            map((searchResult) => new DsoSelectorOption(searchResult, this.nameService.getName(searchResult.indexableObject)))
          )));
        } else {
          selectedOptions$ = of([]);
        }
        return selectedOptions$.pipe(
          take(1),
          map((selectedOptions: DsoSelectorOption[]) => {
            const selectedTopOptions = options.filter((option) => hasValue(selectedOptions.find((selectedOption) => selectedOption.id === option.id)));
            const otherOptions = options.filter((option) => hasNoValue(selectedTopOptions.find((selectedTopOption) => selectedTopOption.id === option.id)));
            const otherSelectedOptions = selectedOptions.filter((selectedOption) => hasNoValue(selectedTopOptions.find((selectedTopOption) => selectedTopOption.id === selectedOption.id)));
            const finalOptions = [...selectedTopOptions];
            if (isNotEmpty(query)) {
              // If a query is provided, show the selected options from the list of results on top and the remaining results below
              finalOptions.push(...otherOptions);
            } else {
              // If no query is provided, show the selected options from the list of results on top, the other selected options below them and the remaining results below those
              finalOptions.push(...otherSelectedOptions.slice(0, this.defaultPagination.pageSize - finalOptions.length));
              finalOptions.push(...otherOptions.slice(0, this.defaultPagination.pageSize - finalOptions.length));
            }
            return finalOptions;
          }),
        );
      })
    );
  }
}

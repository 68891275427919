import { Component } from '@angular/core';
import { DatasetSearchResultListElementComponent as BaseComponent } from '../../../../../../../../app/entity-groups/research-entities/item-list-elements/search-result-list-elements/dataset/dataset-search-result-list-element.component';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';

@listableObjectComponent('DatasetSearchResult', ViewMode.ListElement)
@Component({
  selector: 'ds-dataset-search-result-list-element-unsw',
  // templateUrl: '../../../../../../../../app/entity-groups/research-entities/item-list-elements/search-result-list-elements/dataset/dataset-search-result-list-element.component.html',
  templateUrl: './dataset-search-result-list-element.component.html',
  styleUrls: ['../../../../../shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
})
/**
 * The component for displaying a list element for an item search result of the type Dataset
 */
export class DatasetSearchResultListElementComponent extends BaseComponent {
}

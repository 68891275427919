<div class="row">
  <div class="col-4">
    <h3>{{ 'search.sidebar.settings.title' | translate}}</h3>
    <ds-page-size-selector></ds-page-size-selector>
  </div>

  <div class="col-8">
    <ds-search-form [query]="query$ | async"
                    [inPlaceSearch]="true"
                    [searchPlaceholder]="'atmire.submission.search-modal.external-source.search-placeholder' | translate">
    </ds-search-form>

    <ds-search-labels [inPlaceSearch]="true"></ds-search-labels>

    <h3>{{ ('atmire.submission.search-modal.external-source.search-results.' + externalSource) | translate }}</h3>
    <div *ngVar="(RD$ | async) as RD">
      <ds-pagination *ngIf="RD !== null"
        [paginationOptions]="defaultPagination"
        [collectionSize]="RD?.payload?.totalElements"
        [hideGear]="true"
        (pageChange)="onPageChange($event)">
        <ul *ngIf="RD?.hasSucceeded" class="list-unstyled">
          <li *ngFor="let element of (elements$ | async); let i = index; let last = last" class="mt-4 mb-4 d-flex">
            <ds-importable-list-item-control class="align-self-center"
                                             [object]="element"
                                             [importConfig]="importConfig"
                                             (importObject)="import($event)">
            </ds-importable-list-item-control>
            <ds-atmire-search-external-source-entry-list-element class="w-100" [element]="element" (edit)="editElement($event)"></ds-atmire-search-external-source-entry-list-element>
          </li>
        </ul>
        <ds-loading *ngIf="RD?.isLoading">{{ 'atmire.submission.search-modal.external-source.loading' | translate }}</ds-loading>
        <ds-alert *ngIf="RD?.payload?.page?.length === 0" [type]="AlertTypeEnum.Info" [content]="'atmire.submission.search-modal.external-source.empty' | translate"></ds-alert>
      </ds-pagination>
      <ds-alert *ngIf="RD === null" [type]="AlertTypeEnum.Info" [content]="'atmire.submission.search-modal.external-source.no-query' | translate"></ds-alert>
    </div>
  </div>
</div>

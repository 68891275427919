import { autoserialize } from 'cerialize';
import { isNotEmpty } from '../../shared/empty.util';

export class UsageStatisticsFilter {
  @autoserialize
  filter: string;

  @autoserialize
  category: string;

  @autoserialize
  filterType: string;

  @autoserialize
  values: string[];

  @autoserialize
  information: string;

  get firstValue() {
    if (isNotEmpty(this.values)) {
      return this.values[0];
    } else {
      return null;
    }
  }
}


<ng-container *ngIf="(maxNbLists$ | async) as maxNbLists">
  <ng-container *ngIf="!(hidden$ | async) && maxNbLists > 0">
    <div *ngIf="maxNbLists > 1 && (options$ | async) as options" ngbDropdown placement="bottom-right" class="d-inline-block">
      <button class="btn btn-light" [ngClass]="{'btn-sm': !useLargeButton}"
              id="myListDropdown" ngbDropdownToggle (click)="updateOptions()">
        <img [src]="src$ | async" [alt]="alt$ | async"/>
      </button>
      <div id="myListDropdownMenu" aria-labelledby="myListDropdown" ngbDropdownMenu>
        <ng-container *ngFor="let option of options">
          <div *ngIf="option.selected" class="dropdown-item" (click)="deselect(option.listId)">
            <i class="fas fa-minus"></i> {{ 'atmire.saved-item-list.control.dropdown.remove' | translate:{ name: option.listName } }}
          </div>
          <div *ngIf="!option.selected" class="dropdown-item" (click)="select(option.listId)">
            <i class="fas fa-plus"></i> {{ 'atmire.saved-item-list.control.dropdown.add' | translate:{ name: option.listName } }}
          </div>
        </ng-container>
        <div [ngbTooltip]="maxNbLists <= options.length ? ('atmire.saved-item-list.control.dropdown.new-list.disabled' | translate) : null">
          <div class="dropdown-item" [ngClass]="{ 'disabled': maxNbLists <= options.length }" (click)="addToNewList()">
            <i class="fas fa-plus-square"></i> {{ 'atmire.saved-item-list.control.dropdown.new-list' | translate }}
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="maxNbLists === 1" class="btn" [ngClass]="{'btn-sm': !useLargeButton, 'btn-dark': (selected$ | async), 'btn-light': !(selected$ | async)}"
            [ngbTooltip]="(tooltip$ | async) | translate" container="body" (click)="toggle()">
      <img [src]="src$ | async" [alt]="alt$ | async"/>
    </button>
  </ng-container>
</ng-container>

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ThemedComponent } from '../../../../theme-support/themed.component';
import {
  ExistingMetadataListElementComponent, ReorderableRelationship,
} from './existing-metadata-list-element.component';
import { Item } from '../../../../../core/shared/item.model';
import { RelationshipOptions } from '../../models/relationship-options.model';

/**
 * Themed wrapper for ExistingMetadataListElementComponent
 */
@Component({
  selector: 'ds-themed-existing-metadata-list-element',
  styleUrls: [],
  templateUrl: '../../../../theme-support/themed.component.html',
})
export class ThemedExistingMetadataListElementComponent extends ThemedComponent<ExistingMetadataListElementComponent> {
  protected inAndOutputNames: (keyof ExistingMetadataListElementComponent & keyof this)[] = ['listId', 'submissionItem', 'reoRel', 'metadataFields', 'relationshipOptions', 'submissionId', 'remove'];

  @Input() listId: string;

  @Input() submissionItem: Item;

  @Input() reoRel: ReorderableRelationship;

  @Input() metadataFields: string[];

  @Input() relationshipOptions: RelationshipOptions;

  @Input() submissionId: string;

  @Output() remove: EventEmitter<any> = new EventEmitter();

  protected getComponentName(): string {
    return 'ExistingMetadataListElementComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../../../themes/${themeName}/app/shared/form/builder/ds-dynamic-form-ui/existing-metadata-list-element/existing-metadata-list-element.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import('./existing-metadata-list-element.component');
  }
}

import { AUTOCOMPLETE_OFF, DynamicFormControlLayout, serializable } from '@ng-dynamic-forms/core';
import { DsDynamicInputModel, DsDynamicInputModelConfig } from '../ds-dynamic-input.model';

export const DYNAMIC_FORM_CONTROL_TYPE_ATMIRE_SEARCH = 'ATMIRE_SEARCH';

export interface DynamicAtmireSearchModelConfig extends DsDynamicInputModelConfig {
  maxOptions?: number;
  value?: any;
  externalSources: string[];
  closedAuthority: boolean;
}

export class DynamicAtmireSearchModel extends DsDynamicInputModel {

  @serializable() maxOptions: number;
  @serializable() readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_ATMIRE_SEARCH;
  @serializable() externalSources: string[];
  @serializable() closedAuthority = false;

  constructor(config: DynamicAtmireSearchModelConfig, layout?: DynamicFormControlLayout) {

    super(config, layout);

    this.autoComplete = AUTOCOMPLETE_OFF;
    this.maxOptions = config.maxOptions || 10;
    this.externalSources = config.externalSources;
    this.closedAuthority = config.closedAuthority;

    this.value = config.value;
  }
}

<ngx-charts-chart [view]="[width, height]" [showLegend]="false" [animations]="animations">
  <svg>
    <g [attr.transform]="transform" class="number-card chart" [class.clickable]="clickable">
      <g
        ds-ngx-custom-charts-card-series
        [colors]="colors"
        [cardColor]="cardColor"
        [bandColor]="bandColor"
        [textColor]="textColor"
        [emptyColor]="emptyColor"
        [data]="data"
        [dims]="dims"
        [innerPadding]="innerPadding"
        [valueFormatting]="valueFormatting"
        [labelFormatting]="labelFormatting"
        [animations]="animations"
        (select)="onClick($event)"
      />
    </g>
  </svg>
</ngx-charts-chart>

{{ mdValue.value }}
<ng-container *ngIf="mdValue.authority">
  <ng-container *ngFor="let metadataRepresentation of (matchingRepresentations | async)">
    <ng-container *ngVar="getOrcidId(metadataRepresentation) as orcidId">
      <a *ngIf="orcidId" href="https://orcid.org/{{orcidId}}"
         target="_blank">
        <img src="assets/unsw/images/orcid_icon.png" alt="orcid"/>
      </a>
    </ng-container>
  </ng-container>
</ng-container>

import { Component, Input } from '@angular/core';
import { DSpaceObject } from '../../../core/shared/dspace-object.model';

@Component({
  selector: 'ds-item-list-repeatable-md-badge',
  templateUrl: './item-list-repeatable-md-badge.component.html'
})
/**
 * Component rendering the metadata field values of a given field of an item as badges
 */
export class ItemListRepeatableMdBadgeComponent {
  /**
   * The component used to retrieve the metadata from
   */
  @Input() object: DSpaceObject;
  /**
   * The metadata field(s)
   */
  @Input() fields: string[];
}

import { AtmireSearchSelectableListElement } from './atmire-search-selectable-list-element';
import { ExternalSourceEntry } from '../../../../../../../core/shared/external-source-entry.model';
import { FormFieldMetadataValueObject } from '../../../../models/form-field-metadata-value.model';
import { hasValue } from '../../../../../../empty.util';
import { GenericConstructor } from '../../../../../../../core/shared/generic-constructor';
import { ListableObject } from '../../../../../../object-collection/shared/listable-object.model';

export class ExternalSourceEntryListElement extends AtmireSearchSelectableListElement<ExternalSourceEntry> {
  authority: string;

  createValueObject(): FormFieldMetadataValueObject {
    return new FormFieldMetadataValueObject(this.selectedValue, null, this.authority, this.selectedValue);
  }

  equalsValueObject(object: FormFieldMetadataValueObject): boolean {
    return hasValue(object) && object.authority === this.authority;
  }

  getRenderTypes(): (string | GenericConstructor<ListableObject>)[] {
    return [this.constructor as GenericConstructor<ListableObject>];
  }
}

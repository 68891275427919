<ng-container>
    <div class="row" *ngIf="orgUnitsSourceModel$ | async">
        <h2>{{'home.org-unit-list.head' | translate}}</h2>
        <ng-container>
            <ds-atmire-pagination
                    [sourceModel]="orgUnitsSourceModel$ | async"
                    [initialPaginationOptions]="config"
                    [initialSortOptions]="sortConfig"
                    [viewMode]="ViewModes.GridElement"
                    [hideGear]="true"
                    [hidePagerWhenSinglePage]="true"
                    [hidePaginationDetailWhenSinglePage]="true"
            >
            </ds-atmire-pagination>
        </ng-container>
    </div>
    <ds-loading *ngIf="!(orgUnitsSourceModel$ | async)" message="{{'home.org-unit-list.loading' | translate}}"></ds-loading>
</ng-container>

import { Component, Input, OnInit } from '@angular/core';
import { AtmireMetadataRepresentationService } from '../../atmire-metadata-representation.service';
import { Observable } from 'rxjs';
import { MetadataRepresentation } from '../../../../../../app/core/shared/metadata-representation/metadata-representation.model';
import { map } from 'rxjs/operators';
import { AtmireAuthorityDetailModel } from '../../../../../../app/core/atmire-authorities/atmire-authority-detail.model';
import { Item } from '../../../../../../app/core/shared/item.model';
import { Metadata } from '../../../../../../app/core/shared/metadata.utils';
import { MetadataValue } from '../../../../../../app/core/shared/metadata.models';
import { hasValue } from '../../../../../../app/shared/empty.util';

/**
 * Component to render a single authority controlled metadata value, with authority control details like orcid
 */
@Component({
  selector: 'ds-atmire-authority-single-metadata',
  templateUrl: './atmire-authority-single-metadata.component.html'
})
export class AtmireAuthoritySingleMetadataComponent implements OnInit {
  @Input() item: Item;
  @Input() metadataFieldsAuthority: string[];
  @Input() itemType: string;
  @Input() mdValue: MetadataValue;
  matchingRepresentations: Observable<any[]>;

  constructor(protected atmireMDRepresentationService: AtmireMetadataRepresentationService) {
  }

  ngOnInit() {
    if (hasValue(this.mdValue.authority)) {
      const metadata = this.item.findMetadataSortedByPlace(this.metadataFieldsAuthority);
      const mdRepresentationsAuthorityFields = this.atmireMDRepresentationService.resolveMetadataRepresentations(metadata, 'Person', this.item);
      this.matchingRepresentations = mdRepresentationsAuthorityFields.pipe(
        map((representations: MetadataRepresentation[]) => {
          return representations.filter((representation: any) => {
            if (representation instanceof AtmireAuthorityDetailModel
              && (representation as AtmireAuthorityDetailModel).value === this.mdValue.authority) {
              return (representation as AtmireAuthorityDetailModel);
            }
          });
        }),
      );
    }
  }

  getOrcidId(metadataRepresentation: AtmireAuthorityDetailModel): string {
    return Metadata.firstValue(metadataRepresentation.metadata, 'ac.person.orcid');
  }
}

import { StatletDsoDataTypeService } from './statlet-dso-data-type.service';
import { statletDataTypeService } from './statlet-data-type.decorator';
import { DSONameService } from '../../../../../core/breadcrumbs/dso-name.service';
import { CommunityDataService } from '../../../../../core/data/community-data.service';
import { Injectable } from '@angular/core';
import { StatletPointRoute } from '../../data/models/statlet.utils';
import { getCommunityPageRoute } from '../../../../../community-page/community-page-routing-paths';

@Injectable()
@statletDataTypeService('dso', 'COMMUNITY')
export class StatletDsoCommunityDataTypeService extends StatletDsoDataTypeService {
  constructor(public dsoNameService: DSONameService,
              public dataService: CommunityDataService) {
    super(dsoNameService);
  }

  getLinkByValue(value: string): StatletPointRoute {
    return { href: getCommunityPageRoute(value) };
  }
}

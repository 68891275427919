/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AtmirePageWithSidebarComponent } from './sidebar/atmire-page-with-sidebar.component';
import { AtmireSidebarService } from './sidebar/atmire-sidebar.service';
import { CollapsibleCardComponent } from './collapsible-card/collapsible-card.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DsoSelectorInputComponent } from './dso-selector/dso-selector-input/dso-selector-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiDsoSelectorInputComponent } from './dso-selector/multi-dso-selector-input/multi-dso-selector-input.component';
import { DsoSelectorService } from './dso-selector/services/dso-selector.service';

const DECLARATIONS = [
  AtmirePageWithSidebarComponent,
  CollapsibleCardComponent,
  DsoSelectorInputComponent,
  MultiDsoSelectorInputComponent,
];

const PROVIDERS = [
  AtmireSidebarService,
  DsoSelectorService,
];

const PIPES = [
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
    ...PIPES,
  ],
  providers: [
    ...PROVIDERS,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    NgSelectModule,
  ],
  exports: [
    ...DECLARATIONS,
    ...PIPES,
  ]
})
/**
 * A module for shared components/services between other atmire modules
 * Import this module in an atmire module to get access to its contents
 */
export class AtmireSharedModule {
}

import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { getFirstSucceededRemoteData } from '../../core/shared/operators';
import { Observable } from 'rxjs';
import { RemoteData } from '../../core/data/remote-data';
import { ActivatedRoute } from '@angular/router';
import { TakeDown } from '../../core/shared/takedown.model';

@Component({
  selector: 'ds-taken-down',
  templateUrl: './taken-down.component.html'
})
/**
 * Component responsible for rendering a taken down item
 */
export class TakenDownComponent implements OnInit {
  takeDownRD$: Observable<RemoteData<TakeDown>>;

  constructor(private route: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.takeDownRD$ = this.route.data.pipe(
      map((data) => data.takeDown),
      getFirstSucceededRemoteData(),
    ) as Observable<RemoteData<TakeDown>>;
  }

}

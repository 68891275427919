<ds-metadata-field-wrapper [label]="label | translate">
  <ng-container *ngFor="let mdValue of item?.allMetadata(fieldValue); let last=last; let i = index">
    <ng-container *ngIf="getCorrespondingURI(i) != null">
      <a [href]="getCorrespondingURI(i)" dsHrefExternalLink>
        {{ mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
      </a>
    </ng-container>
    <!-- If there are more values for fieldValue than fieldURI => render remaining fieldValue as non-uri -->
    <ng-container *ngIf="getCorrespondingURI(i) == null">
      <span> {{mdValue.value}} <span *ngIf="!last" [innerHTML]="separator"> </span> </span>
    </ng-container>
  </ng-container>
  <!-- If there are more values for fieldURI than fieldValue => render remaining fieldURI as uri -->
  <ng-container *ngIf="item?.allMetadata(fieldValue)?.length < item?.allMetadata(fieldURI)?.length">
    <span [innerHTML]="separator"></span>
    <ng-container *ngFor="let mdURIValue of item?.allMetadata(fieldURI); let last=last; let i = index">
      <a *ngIf="i >= item?.allMetadata(fieldValue)?.length" [href]="mdURIValue.value" dsHrefExternalLink>
        {{ mdURIValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
      </a>
    </ng-container>
  </ng-container>
</ds-metadata-field-wrapper>

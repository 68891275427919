import { AtmireAuthorityItemMetadataListElementComponent } from './atmire-authority/atmire-authority-item-metadata-list-element.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';


const ENTRY_COMPONENTS = [
// put only entry components that use custom decorator
  AtmireAuthorityItemMetadataListElementComponent,
];

const COMPONENTS = [
  ...ENTRY_COMPONENTS
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ...COMPONENTS,
  ]
})
export class AtmireAuthorityDetailModule {
  /**
   * NOTE: this method allows to resolve issue with components that using a custom decorator
   * which are not loaded during CSR otherwise
   */
  static withEntryComponents() {
    return {
      ngModule: AtmireAuthorityDetailModule,
      providers: ENTRY_COMPONENTS.map((component) => ({provide: component}))
    };
  }
}

import { StatletGraphType } from './statlet-graph-types.model';
import { InjectionToken } from '@angular/core';
import { GenericConstructor } from '../../../../../core/shared/generic-constructor';
import { Context } from '../../../../../core/shared/context.model';
import { hasNoValue } from '../../../../../shared/empty.util';

const singleStatletComponentMap = new Map();

export const DEFAULT_STATLET_CONTEXT = Context.Any;
export const DEFAULT_STATLET_THEME = '*';

export const SINGLE_STATLET_COMPONENT_FACTORY = new InjectionToken<(type: string, context?: Context, theme?: string) => GenericConstructor<any>>('getComponentForSingleStatletType', {
  providedIn: 'root',
  factory: () => getComponentForSingleStatletType
});

export function rendersSingleStatletForType(type: StatletGraphType, context: Context = DEFAULT_STATLET_CONTEXT, theme: string = DEFAULT_STATLET_THEME) {
  return function decorator(component: any) {
    if (!component) {
      return;
    }
    if (hasNoValue(singleStatletComponentMap.get(type.id))) {
      singleStatletComponentMap.set(type.id, new Map());
    }
    if (hasNoValue(singleStatletComponentMap.get(type.id).get(context))) {
      singleStatletComponentMap.get(type.id).set(context, new Map());
    }
    singleStatletComponentMap.get(type.id).get(context).set(theme, component);
  };
}

export function getComponentForSingleStatletType(type: string, context: Context = DEFAULT_STATLET_CONTEXT, theme: string = DEFAULT_STATLET_THEME) {
  if (singleStatletComponentMap.has(type)) {
    if (singleStatletComponentMap.get(type).has(context)) {
      if (singleStatletComponentMap.get(type).get(context).has(theme)) {
        return singleStatletComponentMap.get(type).get(context).get(theme);
      } else {
        return singleStatletComponentMap.get(type).get(context).get(DEFAULT_STATLET_THEME);
      }
    }
  }
  return null;
}

<div>
  <div *ngIf="item && !item.isDiscoverable" class="private-warning">
    <ds-alert [type]="AlertTypeEnum.Warning" [content]="'item.alerts.private' | translate"></ds-alert>
  </div>
  <div *ngIf="item && item.isWithdrawn" class="withdrawn-warning">
    <ds-alert [type]="AlertTypeEnum.Warning" [content]="'item.alerts.withdrawn' | translate"></ds-alert>
  </div>
  <div *ngIf="item && (itemIsTakenDown$ | async)" class="takend-down-warning">
    <ds-alert [type]="AlertTypeEnum.Warning"
              [content]="'item.alerts.taken-down' | translate: { link: itemTakenDownPath }"></ds-alert>
  </div>
</div>

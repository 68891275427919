<div *ngIf="!smallView" class="altmetric-badge d-flex flex-column">
  <h6 class="altmetric-title">
    {{ 'publications.citations-section.altmetric.title' | translate }}
  </h6>
  <div #altMetricEmbeddedDonut>
    <span *ngIf="doiIdentifierValue != null" data-badge-type="donut"
          [attr.data-doi]="doiIdentifierValue"
          class="altmetric-embed altmetric-embed-{{this.item.id}}" data-hide-no-mentions="true"
          data-badge-popover="bottom">
    </span>
    <span *ngIf="doiIdentifierValue == null" data-badge-type="donut"
          [attr.data-handle]="handleIdentifierValue"
          class="altmetric-embed altmetric-embed-{{this.item.id}}" data-hide-no-mentions="true"
          data-badge-popover="bottom">
    </span>
  </div>
</div>

<div *ngIf="smallView" class="citation-smallView">
  <div #altMetricEmbeddedDonut>
    <span *ngIf="doiIdentifierValue != null" [attr.data-doi]="doiIdentifierValue"
          class="altmetric-embed altmetric-embed-{{this.item.id}}" data-badge-popover="bottom"
          data-badge-type="4" data-condensed="true"
          data-hide-no-mentions="true">
    </span>
    <span *ngIf="doiIdentifierValue == null" [attr.data-handle]="handleIdentifierValue"
          class="altmetric-embed altmetric-embed-{{this.item.id}}" data-badge-popover="bottom"
          data-badge-type="4" data-condensed="true"
          data-hide-no-mentions="true">
    </span>
  </div>
</div>

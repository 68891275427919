<div class="dropdown" ngbDropdown *ngIf="isAuthorized$ | async">
  <button class="btn btn-outline-dark" [ngClass]="{'btn-sm' : compact}" [ngbTooltip]="'atmire.item-export-dropdown.tooltip' | translate" ngbDropdownToggle>
    <i class="fas fa-file-download"></i>
  </button>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
    <div *ngFor="let exportOption of exportOptions" (click)="export(exportOption)" class="dropdown-item">
      {{'atmire.item-export-dropdown.option.export-as' | translate:{ exportType: exportOption.label } }}
    </div>
  </div>
</div>

/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../app/shared/shared.module';
import { AtmireAppRoutingModule } from './atmire-app-routing.module';
import { AtmireSharedModule } from './shared/atmire-shared.module';

/**
 * Declaration needed to make sure all decorator functions are called in time
 */
export const MODELS = [
];

const DECLARATIONS = [
];

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS. This will
 * ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
];

const PROVIDERS = [
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  providers: [
    ...PROVIDERS,
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
  imports: [
    CommonModule,
    SharedModule,
    AtmireAppRoutingModule,
    AtmireSharedModule,
  ],
  exports: [
    ...DECLARATIONS,
  ]
})
export class AtmireAppModule {
}

<img class="homepage-graphic" src="assets/images/homepage-header-graphic-left-aligned.png">
<div class="homepage-content">
  <ds-themed-home-news></ds-themed-home-news>
  <div class="container">
    <ng-container *ngIf="(site$ | async) as site">
      <ds-view-tracker [object]="site"></ds-view-tracker>
    </ng-container>
    <div class="search-bar">
      <ds-themed-search-form [inPlaceSearch]="false" [searchPlaceholder]="'home.search-form.placeholder' | translate"></ds-themed-search-form>
    </div>
    <ds-top-level-community-list *ngIf="!(hasOrgUnitCommunityConfig$ | async)"></ds-top-level-community-list>
    <ds-organisation-unit-list *ngIf="(hasOrgUnitCommunityConfig$ | async)"></ds-organisation-unit-list>
<!--
    <div class="row mt-4" *ngIf="(site$ | async) as site">
      <ds-atmire-cua-statlets-section [dso]="site"></ds-atmire-cua-statlets-section>
    </div>
-->
  </div>
</div>

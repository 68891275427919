import { autoserialize } from 'cerialize';
import { AlternativeLabel } from '../../../../shared/models/alternative-label.model';

export class StatletHeader {
  @autoserialize
  headerCategory: string;

  @autoserialize
  headerType: string;

  @autoserialize
  headerId: string;

  @autoserialize
  headerName: string;

  @autoserialize
  alternativeLabels: {
    [size: string]: AlternativeLabel
  };
}

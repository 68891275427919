<ds-truncatable [id]="dso.id">
  <a *ngIf="linkType != linkTypes.None" target="_blank"
     rel="noopener noreferrer"
     [routerLink]="[itemPageRoute]" class="item-list-title"
     [innerHTML]="firstMetadataValue(['organization.legalName','dc.title'])"></a>
  <span *ngIf="linkType == linkTypes.None"
        class="lead item-list-title"
        [innerHTML]="firstMetadataValue(['organization.legalName','dc.title'])"></span>
  <ds-item-list-repeatable-md-badge *ngIf="showLabel" [object]="dso" [fields]="['dc.type']"></ds-item-list-repeatable-md-badge>
  <span class="text-muted">
    <ds-truncatable-part [id]="dso.id" [minLines]="3">
            <span *ngIf="dso.allMetadata(['dc.description']).length > 0"
                  class="item-list-abstract">
                <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                  [innerHTML]="firstMetadataValue('dc.description')"></span>
                </ds-truncatable-part>
            </span>
        </ds-truncatable-part>
  </span>
</ds-truncatable>
<hr/>

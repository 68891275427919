import { StatletDataTypeService } from './statlet-data-type.service';
import { DataService } from '../../../../../core/data/data.service';
import { Observable, of as observableOf } from 'rxjs';
import { getFirstCompletedRemoteData } from '../../../../../core/shared/operators';
import { map } from 'rxjs/operators';
import { DSONameService } from '../../../../../core/breadcrumbs/dso-name.service';
import { StatletPoint } from '../../data/models/statlet-point.model';
import { StatletPointRoute } from '../../data/models/statlet.utils';

export abstract class StatletDsoDataTypeService extends StatletDataTypeService {
  abstract dataService: DataService<any>;
  uuidRegex = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g;

  constructor(public dsoNameService: DSONameService) {
    super();
  }

  /**
   * Resolve the name of an object by UUID (has to match UUID regex to avoid tons of 404s) with a given data-service
   * @param value
   */
  transform(value: string): Observable<string> {
    if (value.match(this.uuidRegex)) {
      return this.dataService.findById(value).pipe(
        getFirstCompletedRemoteData(),
        map((rd) => {
          if (rd.hasSucceeded) {
            return this.dsoNameService.getName(rd.payload);
          } else {
            return value;
          }
        })
      );
    } else {
      return observableOf(value);
    }
  }

  getLinkByPoint(point: StatletPoint): StatletPointRoute {
    return this.getLinkByValue(point.id);
  }
}

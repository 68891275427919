import domtoimage from 'dom-to-image';
import { isNotEmpty } from '../../empty.util';

export enum ImageType {
  SVG = '.svg',
  JPEG = '.jpeg',
  PNG = '.png'
}

export function getExportFunction(imageType: ImageType): (dom: Element, options?: object) => Promise<string> {
  switch (imageType) {
    case ImageType.JPEG: {
      return domtoimage.toJpeg;
    }
    case ImageType.PNG: {
      return domtoimage.toPng;
    }
    case ImageType.SVG: {
      return domtoimage.toSvg;
    }
  }
}


export function toCamelCase(fileName: string) {
  if (isNotEmpty(fileName)) {
    return fileName
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  }
  return fileName;
}

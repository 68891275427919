<div ngbDropdown #dropdown="ngbDropdown" class="btn-group w-100 mb-2" display="static" [autoClose]="'outside'" (openChange)="$event && open()">
    <button ngbDropdownToggle type="button" class="btn btn-outline-primary w-100 d-flex justify-content-between align-items-center">
        <span class="overflow-hidden">
        <i class="far fa-calendar-alt pr-2"></i>
            {{ presetName ? ('form.date-range.preset.' + presetName | translate) : dateRangeString }}
        </span>
        <span class="caret"></span>
    </button>
    <div ngbDropdownMenu class="p-0 dropdown-menu-{{alignment}}">
        <div class="d-flex">
            <div class="scroll-wrapper">
                <div class="list-wrapper">
                    <ul class="list-unstyled mb-0 py-3">
                        <li role="menuitem" *ngFor="let preset of presets">
                            <button ngbDropdownItem [ngClass]="{'active': preset.name === selectedPresetName}"
                                    (click)="updateWithPreset(preset)">{{'form.date-range.preset.' + preset.name | translate}}</button>
                        </li>
                        <li role="menuitem">
                            <button *ngIf="!presetsOnly" ngbDropdownItem [ngClass]="{'active': !selectedPresetName}"
                                    (click)="setToCustom()">{{'form.date-range.preset.custom' | translate}}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-none d-lg-block p-3 bg-light">
                <bs-daterangepicker-inline *ngVar="disabled$ | async as disabled"
                                           [isDisabled]="disabled"
                                           [bsValue]="[selectedStartDate, selectedEndDate]"
                                           (bsValueChange)="updateRange($event)"
                                           [bsConfig]="{ containerClass: 'theme-red', dateInputFormat: dateFormat, minMode: minMode }"
                ></bs-daterangepicker-inline>
                <form class="d-flex justify-content-between mt-3" #dateInputs (keydown.enter)="$event.preventDefault()">
                    <div class="d-flex mr-2 calendar-width">
                        <input class="date-input form-control flex-grow-1 mr-2"
                               name="startDateInput"
                               #start="ngModel"
                               [disabled]="disabled$ | async"
                               [pattern]="dateRegex"
                               [ngModel]="selectedStartDateAsString"
                               (blur)="dateInputs.checkValidity() ? selectedStartDateAsString = start.value : start.reset(selectedStartDateAsString)">
                        <span class="dateHyphen"> - </span>
                        <input class="date-input form-control flex-grow-1 ml-2"
                               #end="ngModel"
                               name="endDateInput"
                               [disabled]="disabled$ | async"
                               [pattern]="dateRegex"
                               [ngModel]="selectedEndDateAsString"
                               (blur)="dateInputs.checkValidity() ? selectedEndDateAsString = end.value :  end.reset(selectedEndDateAsString)">
                    </div>
                    <button class="btn btn-primary" (click)="applyChanges(); dropdown.close();">{{'form.date-range.apply.button' | translate}}</button>
                </form>
            </div>
            <div class="d-flex flex-column justify-content-between d-lg-none p-3 bg-light">
                <div>
                    <div class="form-group">
                        <label for="mobileStartDateInput">{{'form.date-range.start-date.label' | translate}}</label>
                        <input class="form-control form-control-sm"
                               name="mobileStartDateInput"
                               id="mobileStartDateInput"
                               [disabled]="disabled$ | async"
                               type="date"
                               [(ngModel)]="selectedStartDateAsString">
                    </div>
                    <div class="form-group">
                        <label for="mobileEndDateInput">{{'form.date-range.end-date.label' | translate}}</label>
                        <input class="form-control form-control-sm"
                               name="mobileEndDateInput"
                               id="mobileEndDateInput"
                               [disabled]="disabled$ | async"
                               type="date"
                               [(ngModel)]="selectedEndDateAsString">
                    </div>
                </div>
                <button class="btn btn-primary" (click)="applyChanges(); dropdown.close();">{{'form.date-range.apply.button' | translate}}</button>
            </div>
        </div>
    </div>
</div>

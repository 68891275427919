import { FieldParser } from './field-parser';
import { FormFieldMetadataValueObject } from '../models/form-field-metadata-value.model';
import {
  DynamicAtmireSearchModel,
  DynamicAtmireSearchModelConfig
} from '../ds-dynamic-form-ui/models/atmire-search/dynamic-atmire-search.model';
import { isNotEmpty } from '../../../empty.util';

export class AtmireSearchFieldParser extends FieldParser {

  public modelFactory(fieldValue?: FormFieldMetadataValueObject | any, label?: boolean): any {
    if (this.configData.selectableMetadata[0].controlledVocabulary) {
      const searchModelConfig: DynamicAtmireSearchModelConfig = this.initModel(null, label);
      this.setConfigOptions(searchModelConfig, fieldValue);
      return new DynamicAtmireSearchModel(searchModelConfig);
    }
  }

  protected setConfigOptions(config: DynamicAtmireSearchModelConfig, fieldValue?: FormFieldMetadataValueObject) {
    this.setVocabularyOptions(config);
    this.setValues(config, fieldValue, true);
    this.setExternalSources(config);
    config.closedAuthority = this.configData.selectableMetadata[0].closed;
  }

  protected setExternalSources(config: DynamicAtmireSearchModelConfig) {
    const externalSources = [];
    if (isNotEmpty(this.configData.selectableMetadata)) {
      this.configData.selectableMetadata.forEach((selectableMetadata) => {
        if (isNotEmpty(selectableMetadata.externalSources)) {
          selectableMetadata.externalSources.forEach((externalSource) => {
            if (externalSources.indexOf(externalSource) < 0) {
              externalSources.push(externalSource);
            }
          });
        }
      });
    }
    config.externalSources = externalSources;
  }
}

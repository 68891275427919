<ds-metadata-field-wrapper [label]="label">
  <ng-container *ngFor="let objectPage of objects; let i = index">
    <ng-container *ngIf="(objectPage | async) as representations">
      <ng-container *ngFor="let rep of representations; last as last; index as i">
        <span *ngIf="i < maxAmount - 1" class="d-flex">
          <ds-metadata-representation-loader [mdRepresentation]="rep" class="d-inline-block">
          </ds-metadata-representation-loader>
          <span *ngIf="!last" [innerHTML]="i === maxAmount - 2 && representations.length > 2 ? separator + ' ...' : separator"></span>
        </span>
      </ng-container>
      <span *ngIf="representations.length >= maxAmount" class="d-flex">
        <ds-metadata-representation-loader [mdRepresentation]="representations[representations.length - 1]"
                                           class="d-inline-block">
        </ds-metadata-representation-loader>
      </span>
      <ds-loading *ngIf="(i + 1) === objects.length && (i > 0) && (!representations || representations?.length === 0)"
                  message="{{'loading.default' | translate}}"></ds-loading>
      <div class="d-inline-block w-100 mt-2" *ngIf="(i + 1) === objects.length && representations?.length > 0 && representations?.length < maxAmount">
        <div *ngIf="(objects.length * incrementBy) < total" class="float-left">
          <a [routerLink]="" (click)="increase()">{{'item.page.related-items.view-more' |
            translate:{ amount: (total - (objects.length * incrementBy) < incrementBy) ? total - (objects.length * incrementBy) : incrementBy } }}</a>
        </div>
        <div *ngIf="objects.length > 1" class="float-right">
          <a [routerLink]="" (click)="decrease()">{{'item.page.related-items.view-less' |
            translate:{ amount: representations?.length } }}</a>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ds-metadata-field-wrapper>

import { AtmireAuthorityDetailDataService } from '../../../core/data/atmire-authority-detail-data.service';
import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../../core/shared/item.model';
import { hasValue, isNotEmpty } from '../../../shared/empty.util';
import { Observable } from 'rxjs/internal/Observable';
import { AtmireAuthorityDetailModel } from '../../../core/atmire-authorities/atmire-authority-detail.model';
import { map } from 'rxjs/operators';
import { Metadata } from '../../../core/shared/metadata.utils';
import { MetadataValue } from '../../../core/shared/metadata.models';
import { getEntityPageRoute } from '../../item-page-routing-paths';
import { getFirstSucceededRemoteDataPayload } from '../../../core/shared/operators';
import { of as observableOf, zip as observableZip } from 'rxjs';

const ITEM_UUID_SEPARATOR = '###';

@Component({
  selector: 'ds-combined-authority-item-metadata-list',
  templateUrl: './combined-authority-item-metadata-list.component.html'
})
export class CombinedAuthorityItemMetadataListComponent implements OnInit {

  @Input() item: Item;
  @Input() itemAuthorityMetadataFields: string[];
  @Input() authorityMetadataFields: string[];
  @Input() itemMetadataFields: string[];
  @Input() label: string;

  /**
   * Define how individual item and authority metadata should be separated from each other
   * Combines the values found for each authority value's authorityMetadataFields, as well as the item's itemMetadataFields
   * Each of these combines values will be displayed on a new line (e.g. item metadata and each authority each contain their own separate line)
   * Each individual metadata value will be displayed on a new line if this value is omitted
   */
  @Input() individualSeparator: string;

  /**
   * Whether or not the metadata values should be checked if they contain an item ID.
   * If they do, transform the value into a link to said item.
   */
  @Input() resolveItemLinks = false;

  authorityDetails: Observable<AtmireAuthorityDetailModel[]>;
  metadataValues: Observable<string[]>;

  constructor(public authorityDetailService: AtmireAuthorityDetailDataService) {
  }

  ngOnInit(): void {
    this.authorityDetails = observableZip(
      ...this.item.allMetadata(this.itemAuthorityMetadataFields)
        .map((metadatum) => {
          if (isNotEmpty(metadatum.authority)) {
            return this.authorityDetailService.byAuthorityField(metadatum.authority, {elementsPerPage: 1}).pipe(
              getFirstSucceededRemoteDataPayload(),
              map((list) => {
                if (isNotEmpty(list.page)) {
                  return list.page[0];
                } else {
                  return null;
                }
              })
            );
          } else {
            return observableOf(null);
          }
        })
    );
    this.metadataValues = this.authorityDetails.pipe(
      map((authorityDetails) => {
        return [].concat(...[
          this.item.allMetadata(this.itemMetadataFields),
          ...authorityDetails
            .filter((authorityDetail) => hasValue(authorityDetail))
            .map((authorityDetail) =>
              Metadata.all(authorityDetail.metadata, this.authorityMetadataFields))
        ].map((group) => {
          const values = group.map((mdValue) => this.getMetadataValue(mdValue));
          if (hasValue(this.individualSeparator)) {
            return values.join(this.individualSeparator);
          } else {
            return values;
          }
        }));
      }),
      map((values: string[]) => values.filter((value, index) => values.indexOf(value) === index))
    );
  }

  private getMetadataValue(mdValue: MetadataValue): string {
    if (this.resolveItemLinks && mdValue.value.indexOf(ITEM_UUID_SEPARATOR) > 0) {
      const split = mdValue.value.split(ITEM_UUID_SEPARATOR);
      if (split.length > 1) {
        return `<a href="${getEntityPageRoute(null, split[1])}">${split[0]}</a>`;
      }
    }
    return mdValue.value;
  }

}

import { StatletDefaultDataTypeService } from './statlet-default-data-type.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { statletDataTypeService } from './statlet-data-type.decorator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
@statletDataTypeService('data', 'type')
export class StatletI18nDataTypeService extends StatletDefaultDataTypeService {
  constructor(protected translateService: TranslateService) {
    super();
  }

  transform(value: string): Observable<string> {
    return this.translateService.get(`statlets.data.type.value.${value}`, { default: value });
  }
}

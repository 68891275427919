<ds-truncatable-part [maxLines]="1">
  <div [ngClass]="'text-body'"
       [innerHTML]="(parentTitle$ && parentTitle$ | async) ? (parentTitle$ | async) : ('home.breadcrumbs' | translate)"></div>
</ds-truncatable-part>
<ds-truncatable-part *ngIf="title" [maxLines]="1">
  <div class="font-weight-bold"
       [ngClass]="'text-primary'"
       [innerHTML]="title"></div>
</ds-truncatable-part>
<ds-truncatable-part *ngIf="description" [maxLines]="1">
  <div class="text-secondary"
       [ngClass]="'text-secondary'"
       [innerHTML]="description"></div>
</ds-truncatable-part>

import { select, Store } from '@ngrx/store';
import { AtmireSavedItemListState } from './atmire-saved-item-list.reducer';
import { map, take, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  atmireSavedItemListSelector,
  atmireSavedItemListsSelector
} from './atmire-saved-item-list.selectors';
import { Injectable } from '@angular/core';
import { hasValue } from '../../shared/empty.util';
import { Context } from '../../core/shared/context.model';
import {
  AtmireSavedItemListClearAction,
  AtmireSavedItemListDeleteAction,
  AtmireSavedItemListSetAction
} from './atmire-saved-item-list.actions';
import { getAtmireSavedListName } from '../atmire-saved-item-list.util';
import { AtmireSavedItemListTypes } from '../atmire-saved-item-list-types';

/**
 * Service containing methods to send/retrieve data to/from the {@link AtmireSavedItemListState} in the NGRX Store
 */
@Injectable()
export class AtmireSavedItemListStoreService {
  constructor(private store: Store<AtmireSavedItemListState>) {
  }

  getStoredListByContext(context: Context): string {
    const listName = getAtmireSavedListName(context);
    let currentList: string = null;

    this.getStoredListIDObs(listName).pipe(
      take(1)
    ).subscribe((listId) => currentList = listId);

    return currentList;
  }

  /**
   * Retrieve the current list's ID from the Store
   */
  getStoredListID(name: string = AtmireSavedItemListTypes.Default): string {
    let currentList: string = null;

    this.getStoredListIDObs(name).pipe(
      take(1)
    ).subscribe((listId) => currentList = listId);

    return currentList;
  }

  /**
   * Retrieve the current list's ID from the Store in the form of an Observable
   */
  getStoredListIDObs(name: string = AtmireSavedItemListTypes.Default): Observable<string> {
    return this.store.pipe(select(atmireSavedItemListsSelector())).pipe(
      filter((lists) => hasValue(lists)),
      map((lists) => lists[name]),
    );
  }
  getStoredListIDObsByContext(context: Context): Observable<string> {
    const listName = getAtmireSavedListName(context);
    return this.store.pipe(select(atmireSavedItemListsSelector())).pipe(
      filter((lists) => hasValue(lists)),
      map((lists) => lists[listName]),
    );
  }

  addListToStore(list, user?) {
    this.store.dispatch(new AtmireSavedItemListSetAction(list.id, list.name, hasValue(user) ? user.id : undefined));
  }

  clear() {
    this.store.dispatch(new AtmireSavedItemListClearAction());
  }

  getStoredListState(): Observable<AtmireSavedItemListState> {
    return this.store.select(atmireSavedItemListSelector);
  }

  getStoredListIdsObs(): Observable<string[]> {
    return this.getStoredListState().pipe(
      map((state) => hasValue(state.currentLists) ? Object.values(state.currentLists) : [])
    );
  }

  getStoredListIds(): string[] {
    let currentLists = [];

    this.getStoredListIdsObs().pipe(
      take(1)
    ).subscribe((lists) => currentLists = lists);

    return currentLists;
  }

  deleteListFromStore(id: string) {
    this.store.dispatch(new AtmireSavedItemListDeleteAction(id));
  }
}

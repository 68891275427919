<ng-template #descTemplate>
  <span class="text-muted">
      <span class="item-list-job-title">
        <span [innerHTML]="metadataRepresentation.firstMetadataValue(['dc.description'])"></span>
      </span>
  </span>
</ng-template>
<ds-truncatable [id]="metadataRepresentation.id">
  <a [routerLink]="[itemPageRoute]"
     [innerHTML]="metadataRepresentation.getValue()"
     [ngbTooltip]="metadataRepresentation.allMetadata(['organization.legalName','dc.title']).length > 0 ? descTemplate : null"></a>
</ds-truncatable>

import { FormFieldMetadataValueObject } from '../../models/form-field-metadata-value.model';

export class AtmireSearchAddValueAction {
  value: FormFieldMetadataValueObject;
  index?: number;

  constructor(value: FormFieldMetadataValueObject, index?: number) {
    this.value = value;
    this.index = index;
  }
}

import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts an input into a decimal, displaying 3 digits after the decimal point by default
 */
@Pipe({ name: 'dsDecimal' })
export class DsDecimalPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, digitsInfo: string = '1.3-3', locale?: string): string | null {
    return super.transform(value, digitsInfo, locale);
  }
}

import { autoserialize, deserialize } from 'cerialize';
import { HALLink } from '../../core/shared/hal-link.model';

export class UsageStatisticsTableFilterConfig {
  @autoserialize
  filter: string;

  @autoserialize
  category: string;

  @autoserialize
  filterType: string;

  @autoserialize
  valueType: string;

  @autoserialize
  information: string;

  @deserialize
  _links: {
    self: HALLink;
  };
}

<div class="rotation-wrapper-outer">
  <div class="rotation-wrapper-inner">
    <ng-select [items]="options$ | async"
               [placeholder]="placeholder"
               [multiple]="true"
               [(ngModel)]="selectedLabels"
               [typeahead]="query$"
               (ngModelChange)="change()"
               (blur)="submit(selectedLabels)"
               [loading]="loading">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
        <div class="d-flex">
          <div class="flex-grow-1">
            <span>{{ item }}</span>
          </div>
          <div class="text-primary align-self-center" *ngIf="item$.selected">
            <i class="fas fa-check fa-fw"></i>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

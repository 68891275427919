<ds-metadata-field-wrapper [label]="label | translate">
  <span *ngFor="let mdValue of mdValues; let last=last;">
    <ds-truncatable [id]="mdValue.uuid">
      <ds-truncatable-part [id]="mdValue.uuid" [minLines]="3">
        <span [innerHTML]="mdValue.value"></span>
      </ds-truncatable-part>
    </ds-truncatable>
    <span *ngIf="!last" [innerHTML]="separator"></span>
  </span>
</ds-metadata-field-wrapper>

import { Component, Inject, Injector } from '@angular/core';
import { rendersSingleStatletForType } from '../../single-statlet.decorator';
import { StatletGraphTypes } from '../../statlet-graph-types.model';
import { Context } from '../../../../../../../core/shared/context.model';
import {
  MergedSingleStatletComponent
} from '../../merged-single-statlet/merged-single-statlet.component';
import { Observable } from 'rxjs';
import { AtmireCuaColorService } from '../../../../../../shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../../shared/alternative-label.service';
import { HostWindowService } from '../../../../../../../shared/host-window.service';
import {
  STATLET_DATA_TYPE_SERVICE_FACTORY
} from '../../../data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../../core/shared/generic-constructor';

/* Scale a value from one range to another
* Example of use:
*
* // Convert 33 from a 0-100 range to a 0-65535 range
* var n = scaleValue(33, [0,100], [0,65535]);
*
* // Ranges don't have to be positive
* var n = scaleValue(0, [-50,+50], [0,65535]);
*
* Ranges are defined as arrays of two values, inclusive
*
* The ~~ trick on return value does the equivalent of Math.floor, just faster.
*
*/
const scaleValue = (value: number, from: number[], to: number[]): number => {
  const scale = (to[1] - to[0]) / (from[1] - from[0]);
  const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
  return Math.floor(capped * scale + to[0]);
};

@Component({
  selector: 'ds-atmire-cua-single-statlet-bar-vertical-inline',
  templateUrl: './single-statlet-bar-vertical-inline.component.html',
  styleUrls: ['./single-statlet-bar-vertical-inline.component.scss']
})
@rendersSingleStatletForType(StatletGraphTypes.BAR_VERTICAL, Context.StatletInline)
@rendersSingleStatletForType(StatletGraphTypes.BAR_VERTICAL_STACKED, Context.StatletInline)
/**
 * Component rendering a single statlet of type "bar-vertical" in an inline context
 * The legend will not be displayed if the statlet contains only one point
 */
export class SingleStatletBarVerticalInlineComponent extends MergedSingleStatletComponent {
  normalizedData$: Observable<any>;

  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

  loadData() {
    super.loadData();
    // workaround for https://github.com/swimlane/ngx-charts/issues/1459. Disabled because it also
    // affects the values in the tooltips. Can be used if you disable tooltips
    //
    // this.normalizedData$ = this.data$.pipe(
    //   map((data: { value: number }[]) => {
    //     const max = Math.max.apply(Math, data.map((d: { value: number }) => d.value))
    //     return data.map((d: { value: number }) => {
    //       return Object.assign(d, {
    //         value: scaleValue(d.value, [0, max], [0, 30])
    //       })
    //     });
    //   })
    // )
    this.normalizedData$ = this.data$;
  }
}

<div class="outer-wrapper" *ngIf="!shouldShowFullscreenLoader; else fullScreenLoader">
    <ds-admin-sidebar></ds-admin-sidebar>
    <div class="inner-wrapper"  [@slideSidebarPadding]="{
     value: (!(sidebarVisible | async) ? 'hidden' : (slideSidebarOver | async) ? 'shown' : 'expanded'),
     params: {collapsedSidebarWidth: (collapsedSidebarWidth | async), totalSidebarWidth: (totalSidebarWidth | async)}
    }">
    <ds-themed-header-navbar-wrapper></ds-themed-header-navbar-wrapper>

        <ds-notifications-board
                [options]="notificationOptions">
        </ds-notifications-board>
        <main class="main-content">
<!--            <ds-themed-breadcrumbs></ds-themed-breadcrumbs>-->

      <div class="container d-flex justify-content-center align-items-center h-100" *ngIf="shouldShowRouteLoader">
                <ds-loading [showMessage]="false"></ds-loading>
            </div>
      <div [class.d-none]="shouldShowRouteLoader">
                <router-outlet></router-outlet>
            </div>
        </main>

        <ds-themed-footer></ds-themed-footer>
    </div>
</div>
<ng-template #fullScreenLoader>
  <div class="ds-full-screen-loader">
        <ds-loading [showMessage]="false"></ds-loading>
    </div>
</ng-template>

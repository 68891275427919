import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { hasValue } from '../../shared/empty.util';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { LocalStorageStoreAction } from './local-storage.actions';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { of as observableOf } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  localStorage: Storage;
  map: Map<string, BehaviorSubject<any>>;

  constructor(protected store: Store<AppState>,
              @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage = window.localStorage;
      this.map = new Map<string, BehaviorSubject<any>>();
      if (window.addEventListener) {
        window.addEventListener('storage', (data: StorageEvent) => {
          this.store.dispatch(new LocalStorageStoreAction(data));
          this.setValueInMap(data.key, JSON.parse(data.newValue));
        }, false);
      }
    }
  }

  watch(key: string): Observable<any> {
    if (this.isLocalStorageSupported) {
      if (!this.map.has(key)) {
        this.map.set(key, new BehaviorSubject<any>(this.get(key)));
      }
      return this.map.get(key);
    }
    return observableOf(null);
  }

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      return JSON.parse(this.localStorage.getItem(key));
    }
    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, JSON.stringify(value));
      this.setValueInMap(key, value);
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      this.setValueInMap(key, null);
      return true;
    }
    return false;
  }

  get isLocalStorageSupported(): boolean {
    return hasValue(this.localStorage);
  }

  private setValueInMap(key: string, value: any) {
    if (!this.map.has(key)) {
      this.map.set(key, new BehaviorSubject<any>(value));
    } else {
      this.map.get(key).next(value);
    }
  }
}

import { PaginationComponentOptions } from '../../shared/pagination/pagination-component-options.model';
import { SortOptions } from '../../core/cache/models/sort-options.model';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../../core/data/remote-data';
import { PaginatedList } from '../../core/data/paginated-list.model';
import { ListableObject } from '../../shared/object-collection/shared/listable-object.model';
import { PaginatedSearchOptions } from '../../shared/search/paginated-search-options.model';
import { SearchService } from '../../core/shared/search/search.service';
import { SearchOptions } from '../../shared/search/search-options.model';
import { AtmireSearchListableObjectSourceModel } from '../listable-object-sources';
import { AbstractListableObjectSource, AbstractListableObjectSourceModel } from './abstract-listable-object-source';
import { map } from 'rxjs/operators';

/**
 * Retrieves objects via {@link SearchService}.
 */
export class AtmireSearchListableObjectSource extends AbstractListableObjectSource {
  private readonly searchOptions: Partial<SearchOptions>;
  private readonly displayAsSearchResults: boolean;

  constructor(
    model: AtmireSearchListableObjectSourceModel,
    private searchService: SearchService,
  ) {
    super(model as AbstractListableObjectSourceModel);
    this.searchOptions = model.searchOptions;
    this.displayAsSearchResults = model.displayAsSearchResults;
  }

  public getList(
    paginationOptions: Partial<PaginationComponentOptions>, sortOptions: Partial<SortOptions>
  ): Observable<RemoteData<PaginatedList<ListableObject>>> {
    return this.searchService.search(
      Object.assign(new PaginatedSearchOptions({}), {
        ...this.searchOptions,
        sort: sortOptions,
        pagination: paginationOptions,
      }),
      undefined, // responseMsToLive
      this.useCachedVersionIfAvailable,
      this.reRequestOnStale,
      ...this.linksToFollow,
    ).pipe(
      map((rd) => {
        if (!this.displayAsSearchResults && rd.payload?.page !== undefined) {
          return Object.assign(rd, {
            payload: Object.assign(rd.payload, {
              page: rd.payload.page.map((obj) => obj.indexableObject)
            })
          });
        }
        return rd;
      }),
    );
  }

  public get params() {
    return {};
  }
}

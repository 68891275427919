<div class="container">
  <ds-my-dspace-new-submission *dsShowOnlyForRole="[roleTypeEnum.Submitter]"></ds-my-dspace-new-submission>
  <div class="search-page row">
      <ds-search-sidebar *ngIf="!(isXsOrSm$ | async)" class="col-3 sidebar-md-sticky"
                         id="search-sidebar"
                         [configurationList]="(configurationList$ | async)"
                         [resultCount]="(resultsRD$ | async)?.payload.totalElements"
                         [viewModeList]="viewModeList"
                         [searchOptions]="(searchOptions$ | async)"
                         [sortOptions]="(sortOptions$ | async)"
                         [refreshFilters]="refreshFilters.asObservable()"
                         [inPlaceSearch]="inPlaceSearch"></ds-search-sidebar>
      <div class="col-12 col-md-9">
          <ds-themed-search-form id="search-form"
                          [query]="(searchOptions$ | async)?.query"
                          [scope]="(searchOptions$ | async)?.scope"
                          [currentUrl]="getSearchLink()"
                          [scopes]="(scopeListRD$ | async)"
                          [inPlaceSearch]="inPlaceSearch"
                          [searchPlaceholder]="'mydspace.search-form.placeholder' | translate">
          </ds-themed-search-form>
          <ds-search-labels [inPlaceSearch]="inPlaceSearch"></ds-search-labels>
          <div class="row">
              <div id="search-body"
                   class="row-offcanvas row-offcanvas-left w-100"
                   [@pushInOut]="(isSidebarCollapsed() | async) ? 'collapsed' : 'expanded'">
                  <ds-search-sidebar *ngIf="(isXsOrSm$ | async)" class="col-12"
                                     id="search-sidebar-sm"
                                     [configurationList]="(configurationList$ | async)"
                                     [resultCount]="(resultsRD$ | async)?.payload.totalElements"
                                     (toggleSidebar)="closeSidebar()"
                                     [ngClass]="{'active': !(isSidebarCollapsed() | async)}"
                                     [searchOptions]="(searchOptions$ | async)"
                                     [sortOptions]="(sortOptions$ | async)"
                                     [refreshFilters]="refreshFilters.asObservable()"
                                     [inPlaceSearch]="inPlaceSearch">
                  </ds-search-sidebar>
                  <div id="search-content" class="col-12">
                      <div class="d-block d-md-none search-controls clearfix">
                          <ds-view-mode-switch [viewModeList]="viewModeList" [inPlaceSearch]="inPlaceSearch"></ds-view-mode-switch>
                          <button (click)="openSidebar()" aria-controls="#search-body"
                                  class="btn btn-outline-primary float-right open-sidebar"><i
                                  class="fas fa-sliders"></i> {{"search.sidebar.open"
                              | translate}}
                          </button>
                      </div>
                      <ds-my-dspace-results [searchResults]="resultsRD$ | async"
                                            [searchConfig]="searchOptions$ | async"
                                            [sortOptions]="sortOptions$ |async"
                                            [context]="context$ | async"
                                            (contentChange)="onResultsContentChange()"></ds-my-dspace-results>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { dataService } from '../../../core/cache/builders/build-decorators';
import { ATMIRE_VALUE_PAIR } from './atmire-value-pair.resource-type';
import { DataService } from '../../../core/data/data.service';
import { AtmireValuePair } from './atmire-value-pair.model';
import { RequestService } from '../../../core/data/request.service';
import { RemoteDataBuildService } from '../../../core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../core/core.reducers';
import { BrowseService } from '../../../core/browse/browse.service';
import { ObjectCacheService } from '../../../core/cache/object-cache.service';
import { HALEndpointService } from '../../../core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../../core/data/default-change-analyzer.service';
import { URLCombiner } from '../../../core/url-combiner/url-combiner';
import { isNotEmpty } from '../../../shared/empty.util';

export const BY_COUNTRY_ENDPOINT = '/api/config/atmirevaluepairs/search/byCountry';

@Injectable()
@dataService(ATMIRE_VALUE_PAIR)
export class AtmireValuePairDataService extends DataService<AtmireValuePair> {
  protected linkPath = 'atmirevaluepairs';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected bs: BrowseService,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<AtmireValuePair>,
  ) {
    super();
  }

  getEndpointFromPath(path: string): string {
    return new URLCombiner(this.halService.getRootHref(), path.substring(path.lastIndexOf('/api/') + 4)).toString();
  }

  getSearchEndpointByPathWithDisplay(path: string, display: string): string {
    return this.getEndpointWithDisplay(this.getEndpointFromPath(path), display);
  }

  getSearchEndpointByPathWithValue(path: string, value: string): string {
    return this.getEndpointWithValue(this.getEndpointFromPath(path), value);
  }

  getEndpointWithDisplay(endpoint: string, display: string): string {
    return `${endpoint}${isNotEmpty(display) ? `?display=${display}` : ''}`;
  }

  getEndpointWithValue(endpoint: string, value: string): string {
    return `${endpoint}${isNotEmpty(value) ? `?value=${value}` : ''}`;
  }
}

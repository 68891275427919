import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormFieldMetadataValueObject } from '../../../models/form-field-metadata-value.model';
import { AtmireSearchEditValueAction } from '../atmire-search-edit-value-action.model';
import { AlertType } from '../../../../../alert/aletr-type';
import { AtmireSearchSelectableListElement } from './models/atmire-search-selectable-list-element';
import { AtmireSearchAddValueAction } from '../atmire-search-add-value-action.model';
import { hasNoValue, hasValue, isEmpty } from '../../../../../empty.util';

@Component({
  selector: 'ds-atmire-search-tab-contents',
  template: ''
})
export class AtmireSearchTabComponent {
  @Input() query: string;
  @Input() selectedValues: FormFieldMetadataValueObject[];
  @Input() model: any;
  @Input() clickedValue: FormFieldMetadataValueObject;
  @Output() selectObject: EventEmitter<AtmireSearchAddValueAction> = new EventEmitter<AtmireSearchAddValueAction>();
  @Output() editObject: EventEmitter<AtmireSearchEditValueAction> = new EventEmitter<AtmireSearchEditValueAction>();
  @Output() deselectObject: EventEmitter<number> = new EventEmitter<number>();

  /**
   * The AlertType enumeration
   * @type {AlertType}
   */
  public AlertTypeEnum = AlertType;

  selectElement(element: AtmireSearchSelectableListElement<any>) {
    if (element.selected) {
      let i = null;
      this.selectedValues.forEach((value, index) => {
        if (hasNoValue(i) && hasValue(value) && hasValue(this.clickedValue) && isEmpty(this.clickedValue.authority) && isEmpty(value.authority) && this.clickedValue.value === value.value) {
          i = index;
          this.clickedValue = null;
        }
      });
      this.selectObject.emit(new AtmireSearchAddValueAction(element.createValueObject(), i));
    } else {
      this.selectedValues.forEach((value, index) => {
        if (element.equalsValueObject(value)) {
          this.deselectObject.emit(index);
        }
      });
    }
  }

  editElement(element: AtmireSearchSelectableListElement<any>) {
    this.selectedValues.forEach((value, index) => {
      if (element.equalsValueObject(value)) {
        this.editObject.emit(new AtmireSearchEditValueAction(element.createValueObject(), index));
      }
    });
  }
}

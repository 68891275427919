import { Injectable } from '@angular/core';
import { dataService } from '../../../core/cache/builders/build-decorators';
import { DataService } from '../../../core/data/data.service';
import { RequestService } from '../../../core/data/request.service';
import { RemoteDataBuildService } from '../../../core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../core/core.reducers';
import { ObjectCacheService } from '../../../core/cache/object-cache.service';
import { HALEndpointService } from '../../../core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DSOChangeAnalyzer } from '../../../core/data/dso-change-analyzer.service';
import { SEARCH_TERM } from '../search-reports-models/search-term.resource-type';
import { SearchTerm } from '../search-reports-models/search-term.model';

/**
 * The service handling all REST requests for SearchTerm
 */
@Injectable()
@dataService(SEARCH_TERM)
export class SearchTermsDataService extends DataService<SearchTerm> {
  protected linkPath = 'searchterms';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DSOChangeAnalyzer<SearchTerm>
  ) {
    super();
  }
}

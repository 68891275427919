<ng-container *ngVar="(objects$ | async) as objects">
  <div class="browse-by-header">
    <div class="container">
      <h3 [ngClass]="{'sr-only': parentname }" >{{title | translate}}</h3>
      <ng-container *ngComponentOutlet="getStartsWithComponent(); injector: objectInjector;"></ng-container>

    </div>
  </div>
  <div class="container">
    <div *ngIf="objects?.hasSucceeded && !objects?.isLoading && objects?.payload?.page.length > 0" @fadeIn>
      <div *ngIf="!enableArrows">
        <ds-viewable-collection
          [config]="paginationConfig"
          [sortConfig]="sortConfig"
          [objects]="objects">
        </ds-viewable-collection>
      </div>
      <div *ngIf="enableArrows">
        <div class="row">
          <div class="col-12 pagination-controls pt-3">
            <div *ngIf="!hideGear" ngbDropdown placement="bottom-right" class="d-inline-block float-right ml-2">
              <span class="control-label">
                {{ 'pagination.options.results-per-page' | translate }}
              </span>
              <button class="btn btn-outline-light" id="resultsPerPageControl" ngbDropdownToggle
                      [title]="'pagination.options.results-per-page' | translate"
                      [attr.aria-label]="'pagination.options.results-per-page' | translate"
              >
                {{ paginationConfig?.pageSize }}
                <img src="assets/images/chevron-down.png" alt="">
              </button>
              <div id="resultsPerPageDropdown" aria-labelledby="resultsPerPageControl" ngbDropdownMenu>
                <button class="dropdown-item page-size-change btn-light" *ngFor="let item of paginationConfig?.pageSizeOptions" (click)="doPageSizeChange(item)">
                  <i [ngClass]="{'invisible': item != paginationConfig?.pageSize}" class="fas fa-check" aria-hidden="true"></i>
                  {{ item }}
                </button>
              </div>
            </div>

            <div *ngIf="!hideGear" ngbDropdown placement="bottom-right" class="d-inline-block float-right ml-2">
              <span class="control-label">
                {{ 'pagination.options.sort' | translate }}
              </span>
              <button class="btn btn-outline-light" id="sortControl" ngbDropdownToggle
                      [title]="'pagination.options.sort' | translate"
                      [attr.aria-label]="'pagination.options.results-per-page' | translate"
              >
                {{ 'sorting.' + sortConfig?.direction | translate }}
                <img src="assets/images/chevron-down.png" alt="">
              </button>
              <div id="sortDropdown" aria-labelledby="sortControl" ngbDropdownMenu>
                <button class="dropdown-item sort-direction-change btn-light" *ngFor="let direction of (sortDirections | dsKeys)" (click)="doSortDirectionChange(direction.value)">
                  <i [ngClass]="{'invisible': direction.value !== sortConfig?.direction}" class="fas fa-check" aria-hidden="true"></i>
                  {{ 'sorting.' + direction.key | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <ul class="list-unstyled">
          <li *ngFor="let object of objects?.payload?.page" class="mt-4 mb-4">
            <ds-listable-object-component-loader [object]="object"></ds-listable-object-component-loader>
          </li>
        </ul>
        <div class="browse-by-controls">
          <button id="nav-prev" type="button" class="btn btn-dark float-left" (click)="goPrev()" [disabled]="objects?.payload?.currentPage <= 1">
            <img src="assets/images/chevron-left.png" alt="" class="float-left">
            <span class="float-right">
              {{'browse.previous.button' |translate}}
            </span>
          </button>
          <button id="nav-next" type="button" class="btn btn-dark float-right" (click)="goNext()" [disabled]="objects?.payload?.currentPage >= objects?.payload?.totalPages">
            <span class="float-left">
              {{'browse.next.button' | translate}}
            </span>
            <img src="assets/images/chevron-right.png" alt="" class="float-right">
          </button>
        </div>
      </div>
    </div>
    <ds-loading *ngIf="!objects || objects?.isLoading" message="{{'loading.browse-by' | translate}}"></ds-loading>
    <ds-error *ngIf="objects?.hasFailed" message="{{'error.browse-by' | translate}}"></ds-error>
    <div *ngIf="!objects?.isLoading && objects?.payload?.page.length === 0" class="alert alert-info w-100" role="alert">
      {{'browse.empty' | translate}}
    </div>
  </div>
</ng-container>

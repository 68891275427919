/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { AtmireMenuResolver } from './atmire-menu.resolver';
import { MenuService } from './shared/menu/menu.service';
import { BrowseService } from './core/browse/browse.service';
import { AuthorizationDataService } from './core/data/feature-authorization/authorization-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { ScriptDataService } from './core/data/processes/script-data.service';
import { StatisticsMenuService } from './shared/menu/statistics-menu/statistics-menu.service';
import { ConfigurationDataService } from './core/data/configuration-data.service';
import { getFirstCompletedRemoteData, getFirstSucceededRemoteDataPayload } from './core/shared/operators';
import { ConfigurationProperty } from './core/shared/configuration-property.model';
import { MenuID, MenuItemType } from './shared/menu/initial-menus-state';
import { TextMenuItemModel } from './shared/menu/menu-item/models/text.model';
import { LinkMenuItemModel } from './shared/menu/menu-item/models/link.model';
import { ExternalLinkMenuItemModel } from './shared/menu/menu-item/models/external-link.model';
import { RemoteData } from './core/data/remote-data';
import { PaginatedList } from './core/data/paginated-list.model';
import { BrowseDefinition } from './core/shared/browse-definition.model';

const DATASET_COLLECTION_UUID_PROPERTY_NAME = 'submission-quicklink.collection-uuid.datasets';
const THESIS_COLLECTION_UUID_PROPERTY_NAME = 'submission-quicklink.collection-uuid.thesis';

/**
 * Menu entries specific to app-client should be defined here.
 * For this resolver to be used, it must be provided as {@link MenuResolver} in {@link AppModule}.
 */
@Injectable()
export class ClientMenuResolver extends AtmireMenuResolver {
  constructor(
    protected menuService: MenuService,
    protected browseService: BrowseService,
    protected authorizationService: AuthorizationDataService,
    protected modalService: NgbModal,
    protected scriptDataService: ScriptDataService,
    protected statisticsMenuService: StatisticsMenuService,
    protected configService: ConfigurationDataService,
  ) {
    super(menuService, browseService, authorizationService, modalService, scriptDataService, statisticsMenuService);
  }

  createPublicMenu$(): Observable<boolean> {
    const datasetCollectionUUID$ = this.configService.findByPropertyName(DATASET_COLLECTION_UUID_PROPERTY_NAME);
    const thesisCollectionUUID$ = this.configService.findByPropertyName(THESIS_COLLECTION_UUID_PROPERTY_NAME);

    observableCombineLatest(
      datasetCollectionUUID$.pipe(getFirstSucceededRemoteDataPayload()),
      thesisCollectionUUID$.pipe(getFirstSucceededRemoteDataPayload())
    ).subscribe(([datasetCollectionUUID, thesisCollectionUUID]: [ConfigurationProperty, ConfigurationProperty]) => {
      const depositSection =  {
        id: 'deposit',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.deposit',
          activeRegex: [/^\/submit/g, /^\/workspaceitems\/[a-zA-Z0-9\-]+\/edit/g]
        } as TextMenuItemModel,
        index: 2
      };

      const thesisDepositOption = {
        id: 'deposit_thesis',
        parentID: 'deposit',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: 'menu.section.deposit.thesis',
          link: '/submit',
          queryParams: {collection: thesisCollectionUUID.values[0]}
        } as LinkMenuItemModel,
      };
      this.menuService.addSection(MenuID.PUBLIC, Object.assign(thesisDepositOption, {
        shouldPersistOnRouteChange: true
      }));
      const datasetDepositOption = {
        id: 'deposit_dataset',
        parentID: 'deposit',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: 'menu.section.deposit.dataset',
          link: '/submit',
          queryParams: {collection: datasetCollectionUUID.values[0]}
        } as LinkMenuItemModel,
      };
      this.menuService.addSection(MenuID.PUBLIC, Object.assign(datasetDepositOption, {
        shouldPersistOnRouteChange: true
      }));
      this.menuService.addSection(MenuID.PUBLIC, Object.assign(depositSection, {
        shouldPersistOnRouteChange: true
      }));
    });

    const menuList: any[] = [
      /* Home */
      {
        id: `home`,
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.home`,
          link: `/home`,
        } as LinkMenuItemModel,
        index: 0
      },
      //// w2p-85125 DISABLE SELECTIVE EXPORT ////
      // {
      //   id: 'my_lists',
      //   active: false,
      //   visible: true,
      //   model: {
      //     type: MenuItemType.LINK,
      //     text: 'menu.section.my_lists',
      //     link: `/my-lists`
      //   } as LinkMenuItemModel,
      //   index: 1
      // },
      {
          id: 'help',
          active: false,
          visible: true,
          model: {
              type: MenuItemType.TEXT,
              text: 'menu.section.help',
          } as TextMenuItemModel,
          index: 3,
      },
      {
        id: 'help_deposit',
        parentID: 'help',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.EXTERNAL,
          text: 'menu.section.help_deposit',
          href: 'https://www.library.unsw.edu.au/research/publishing-and-sharing-your-research/depositing-your-thesis',
        } as ExternalLinkMenuItemModel,
      },
      {
        id: 'help_data_publishing',
        parentID: 'help',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.EXTERNAL,
          text: 'menu.section.help_data_publishing',
          href: 'https://subjectguides.library.unsw.edu.au/publishing/data',
        } as ExternalLinkMenuItemModel,
      },
      {
        id: 'help_OA',
        parentID: 'help',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.EXTERNAL,
          text: 'menu.section.help_OA',
          href: 'https://www.library.unsw.edu.au/research/publishing-and-sharing-your-research/open-access',
        } as ExternalLinkMenuItemModel,
      },
    ];
    // Read the different Browse-By types from config and add them to the browse menu
    this.browseService.getBrowseDefinitions()
        .pipe(getFirstCompletedRemoteData<PaginatedList<BrowseDefinition>>())
        .subscribe((browseDefListRD: RemoteData<PaginatedList<BrowseDefinition>>) => {
          if (browseDefListRD.hasSucceeded) {
            browseDefListRD.payload.page.forEach((browseDef: BrowseDefinition) => {
              menuList.push({
                id: `browse_global_by_${browseDef.id}`,
                parentID: 'browse_global',
                active: false,
                visible: true,
                model: {
                  type: MenuItemType.LINK,
                  text: `menu.section.browse_global_by_${browseDef.id}`,
                  link: `/browse/${browseDef.id}`
                } as LinkMenuItemModel
              });
            });
            menuList.push(
              /* Browse */
              {
                id: 'browse_global',
                active: false,
                visible: true,
                index: 1,
                model: {
                  type: MenuItemType.TEXT,
                  text: 'menu.section.browse_global',
                  activeRegex: [/^\/browse\//g]
                } as TextMenuItemModel,
              }
            );
          }
          menuList.forEach((menuSection) => this.menuService.addSection(MenuID.PUBLIC, Object.assign(menuSection, {
            shouldPersistOnRouteChange: true
          })));
        });

    this.statisticsMenuService.addMenuOptionsForType('Site', true);

    return this.waitForMenu$(MenuID.PUBLIC);
  }

  createMainMenuSections(): void {
    super.createMainMenuSections();
  }

  createExportMenuSections(): void {
    super.createExportMenuSections();
  }

  createImportMenuSections(): void {
    super.createImportMenuSections();
  }

  createSiteAdministratorMenuSections(): void {
    super.createSiteAdministratorMenuSections();
  }

  createAccessControlMenuSections(): void {
    super.createAccessControlMenuSections();
  }
}

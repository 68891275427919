import { typedObject } from '../../../../core/cache/builders/build-decorators';
import { HALResource } from '../../../../core/shared/hal-resource.model';
import { PREDEFINED_REPORT } from './predefined-report.resource-type';
import { autoserialize, deserialize } from 'cerialize';
import { HALLink } from '../../../../core/shared/hal-link.model';

@typedObject
export class PredefinedReport extends HALResource {
  static type = PREDEFINED_REPORT;
  type = PREDEFINED_REPORT;

  @autoserialize
  id: number;

  @autoserialize
  name: string;

  @autoserialize
  ePersonID: string;

  @autoserialize
  reportType: string;

  @autoserialize
  graph: string;

  @deserialize
  _links: {
    usagestatisticstable: HALLink;
    contentstatisticstable: HALLink;
    self: HALLink;
  };

}

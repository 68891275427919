<div *ngVar="(bitstreams$ | async)?.payload as bitstreams">
    <div *ngIf="hasValuesInBundle(bitstreams)">
        <ds-metadata-field-wrapper [label]="label | translate">
            <ds-pagination *ngIf="bitstreams?.page?.length > 0"
                           [hideGear]="true"
                           [hidePagerWhenSinglePage]="true"
                           [paginationOptions]="originalOptions"
                           [pageInfoState]="bitstreams"
                           [collectionSize]="bitstreams?.totalElements"
                           [retainScrollPosition]="true">


                <div class="file-section row" *ngFor="let file of bitstreams?.page;">
                    <div class="col-3">
                        <ds-thumbnail [thumbnail]="(file.thumbnail | async)?.payload"></ds-thumbnail>
                    </div>
                    <div class="col-7">
                        <dl class="row">
                            <dt class="col-md-4">{{"item.page.filesection.name" | translate}}</dt>
                            <dd class="col-md-8">{{file.name}}</dd>

                            <dt class="col-md-4">{{"item.page.filesection.size" | translate}}</dt>
                            <dd class="col-md-8">{{(file.sizeBytes) | dsFileSize }}</dd>


                            <dt class="col-md-4">{{"item.page.filesection.format" | translate}}</dt>
                            <dd class="col-md-8">{{ (file?.format | async)?.payload?.mimetype }}</dd>


                            <dt class="col-md-4">{{"item.page.filesection.description" | translate}}</dt>
                            <dd class="col-md-8">{{file.firstMetadataValue("dc.description")}}</dd>
                        </dl>
                    </div>
                    <div class="col-2">
                        <ds-file-download-link [bitstream]="file">
                            {{"item.page.filesection.download" | translate}}
                        </ds-file-download-link>
                    </div>
                </div>
            </ds-pagination>
        </ds-metadata-field-wrapper>
    </div>
</div>

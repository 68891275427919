import { ListableObject } from '../../../../../../object-collection/shared/listable-object.model';
import { GenericConstructor } from '../../../../../../../core/shared/generic-constructor';
import { FormFieldMetadataValueObject } from '../../../../models/form-field-metadata-value.model';

export abstract class AtmireSearchSelectableListElement<T> extends ListableObject {
  object: T;
  values: string[];
  selectedValue: string;
  selected: boolean;

  constructor(object: T, values: string[], selected: boolean = false) {
    super();
    this.object = object;
    this.values = values;
    this.selectedValue = values[0];
    this.selected = selected;
  }

  abstract createValueObject(): FormFieldMetadataValueObject;

  abstract equalsValueObject(object: FormFieldMetadataValueObject): boolean;

  getRenderTypes(): (string | GenericConstructor<ListableObject>)[] {
    return [this.constructor as GenericConstructor<ListableObject>];
  }
}

import { Component, Inject, Injector } from '@angular/core';
import { rendersSingleStatletForType } from '../single-statlet.decorator';
import { StatletGraphTypes } from '../statlet-graph-types.model';
import { DynamicSingleStatletComponent } from '../dynamic-single-statlet.component';
import { Context } from '../../../../../../core/shared/context.model';
import { AtmireCuaColorService } from '../../../../../shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../shared/alternative-label.service';
import { HostWindowService } from '../../../../../../shared/host-window.service';
import { STATLET_DATA_TYPE_SERVICE_FACTORY } from '../../data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../core/shared/generic-constructor';

@Component({
  selector: 'ds-atmire-cua-single-statlet-bar-horizontal',
  templateUrl: './single-statlet-bar-horizontal.component.html',
  styleUrls: ['./single-statlet-bar-horizontal.component.scss']
})
@rendersSingleStatletForType(StatletGraphTypes.BAR_HORIZONTAL)
@rendersSingleStatletForType(StatletGraphTypes.BAR_HORIZONTAL, Context.StatletCard)
/**
 * Component rendering a single statlet of type "bar-horizontal"
 * The legend will not be displayed if the statlet contains only one point
 */
export class SingleStatletBarHorizontalComponent extends DynamicSingleStatletComponent {
  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

}

import { Component } from '@angular/core';
import { metadataRepresentationComponent } from '../../../shared/metadata-representation/metadata-representation.decorator';
import { MetadataRepresentationType } from '../../shared/metadata-representation/metadata-representation.model';
import { ItemMetadataRepresentationListElementComponent } from '../../../shared/object-list/metadata-representation-list-element/item/item-metadata-representation-list-element.component';
import { Metadata } from '../../shared/metadata.utils';

@metadataRepresentationComponent('person', MetadataRepresentationType.AuthorityControlled)
@Component({
  selector: 'ds-atmire-authority-item-metadata-list-element',
  templateUrl: './atmire-authority-item-metadata-list-element.component.html'
})
/**
 * The component for displaying an authority detail for the person type as a metadata field
 */
export class AtmireAuthorityItemMetadataListElementComponent extends ItemMetadataRepresentationListElementComponent {

  orcidID: string;
  uri: string;

  ngOnInit(): void {
    this.orcidID = Metadata.firstValue(this.metadataRepresentation.metadata, 'person.identifier.orcid');
    this.uri =  Metadata.firstValue(this.metadataRepresentation.metadata, 'dc.identifier.uri');
  }
}

import { autoserialize, autoserializeAs, autoserializeIndexable, deserialize, inheritSerialization } from 'cerialize';
import { UsageStatisticsHeader } from './usage-statistics-header.model';
import { typedObject } from '../../core/cache/builders/build-decorators';
import { HALResource } from '../../core/shared/hal-resource.model';
import { USAGE_STATISTICS_TABLE } from './usage-report.resource-type';
import { UsageStatisticsRow } from './usage-statistics-row.model';
import { HALLink } from '../../core/shared/hal-link.model';
import { UsageStatisticsGroup } from './usage-statistics-group.model';
import { UsageStatisticsFilter } from './usage-statistics-filter.model';
import { UsageStatisticsTablePagination } from './usage-statistics-table-pagination.model';
import { hasValue, isNotEmpty } from '../../shared/empty.util';

@typedObject
@inheritSerialization(HALResource)
export class UsageStatisticsTable extends HALResource {

  static type = USAGE_STATISTICS_TABLE;

  type = USAGE_STATISTICS_TABLE;

  @autoserialize
  searchType: string;

  @autoserialize
  rowType: string;

  @autoserializeAs(UsageStatisticsFilter)
  appliedFilters: UsageStatisticsFilter[];

  @autoserializeAs(UsageStatisticsGroup)
  group: UsageStatisticsGroup;

  @autoserializeAs(UsageStatisticsHeader)
  rowHeaders: UsageStatisticsHeader[];

  @autoserializeAs(UsageStatisticsHeader)
  columnHeaders: UsageStatisticsHeader[];

  @autoserializeIndexable(UsageStatisticsRow)
  statisticsRows: { [rowId: string]: UsageStatisticsRow };

  @autoserialize
  searchParameters: { [parameter: string]: string };

  @deserialize
  _links: {
    self: HALLink;
    first?: HALLink;
    prev?: HALLink;
    next?: HALLink;
  };

  @autoserializeAs(UsageStatisticsTablePagination)
  page: UsageStatisticsTablePagination;

  get first(): string {
    if (hasValue(this._links.first) && hasValue(this._links.first.href)) {
      return this._links.first.href;
    }
  }

  get prev(): string {
    if (hasValue(this._links.prev) && hasValue(this._links.prev.href)) {
      return this._links.prev.href;
    }
  }

  get hasPrev(): boolean {
    return isNotEmpty(this.prev);
  }

  get next(): string {
    if (hasValue(this._links.next) && hasValue(this._links.next.href)) {
      return this._links.next.href;
    }
  }

  get hasNext(): boolean {
    return isNotEmpty(this.next);
  }
}

import { MergedSingleStatletComponent } from '../../merged-single-statlet/merged-single-statlet.component';
import { Component, Inject, Injector } from '@angular/core';
import { rendersSingleStatletForType } from '../../single-statlet.decorator';
import { StatletGraphTypes } from '../../statlet-graph-types.model';
import { Context } from '../../../../../../../core/shared/context.model';
import { hasValue, isNotEmpty } from '../../../../../../../shared/empty.util';
import { map, switchMap } from 'rxjs/operators';
import { Observable, zip as observableZip } from 'rxjs';
import { AtmireCuaColorService } from '../../../../../../shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../../shared/alternative-label.service';
import { HostWindowService } from '../../../../../../../shared/host-window.service';
import { STATLET_DATA_TYPE_SERVICE_FACTORY } from '../../../data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../../core/shared/generic-constructor';

@Component({
  selector: 'ds-atmire-cua-single-statlet-gauge-inline',
  templateUrl: './single-statlet-gauge-inline.component.html',
  styleUrls: ['./single-statlet-gauge-inline.component.scss']
})
@rendersSingleStatletForType(StatletGraphTypes.GAUGE, Context.StatletInline)
/**
 * Component rendering a single statlet of type "gauge" within an "inline" context
 */
export class SingleStatletGaugeInlineComponent extends MergedSingleStatletComponent {
  /**
   * The max value of the gauge
   * This is equal to the "total" value found in the statlet's point (or defaults to 100 if none is found)
   */
  max = 100;

  /**
   * The label to display below the gauge
   */
  label$: Observable<string>;

  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

  /**
   * Load the data for the gauge to read
   * It attempts to find a unique key in every statlet point that doesn't equal "total" or "other"
   * If found, that key is used for translating the header and only its value is used to display
   * Otherwise, display the gauge regularly (by combining all values within a point)
   */
  loadData() {
    this.data$ = this.windowService.widthCategory.pipe(
      switchMap((width) => observableZip(...this.statlet.points.map((point) => {
        const valueId = Object.keys(point.values).find((key) => key !== 'total' && key !== 'other');
        let value = 0;
        if (hasValue(valueId)) {
          value = point.values[valueId];
        } else {
          Object.values(point.values).forEach((v) => value += v);
        }
        const header = this.getHeaderById(hasValue(valueId) ? this.statlet.columnHeaders : this.statlet.rowHeaders, hasValue(valueId) ? valueId : point.id);
        const dataService = this.getStatletDataTypeServiceForHeader(header);
        return this.getHeaderName(header, dataService, point.label, width).pipe(
          map((name) => Object.assign({ name, value }))
        );
      })))
    );

    if (isNotEmpty(this.statlet.points)) {
      const header = this.getHeaderById(this.statlet.rowHeaders, this.statlet.points[0].id);
      const dataService = this.getStatletDataTypeServiceForHeader(header);
      this.label$ = this.windowService.widthCategory.pipe(
        switchMap((width) => this.getHeaderName(header, dataService, this.statlet.points[0].label, width)),
      );

      if (hasValue(this.statlet.points[0].values.total)) {
        this.max = this.statlet.points[0].values.total;
      }
    }
  }
}

import { type } from '../../shared/ngrx/type';
import { Action } from '@ngrx/store';

export const LocalStorageActionTypes = {
  STORAGE: type('dspace/local-storage/STORAGE'),
};

/* tslint:disable:max-classes-per-file */
export class LocalStorageAction implements Action {
  type: string;

  // tslint:disable-next-line:no-shadowed-variable
  constructor(type: string) {
    this.type = type;
  }
}

/**
 * This action represents a store action happening externally (e.g. within another browser tab) within the local storage
 * - The constructor of {@link LocalStorageService} listens to a storage event and dispatches this action
 * - {@link AtmireSavedItemListEffects} listens for this action and updates the current tab's store with the updated values
 * This keeps multiple tabs their local storage in sync
 */
export class LocalStorageStoreAction extends LocalStorageAction {
  event: StorageEvent;

  constructor(event: StorageEvent) {
    super(LocalStorageActionTypes.STORAGE);
    this.event = event;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { slide } from '../../../shared/animations/slide';
import { hasValueOperator, isNotEmpty } from '../../../shared/empty.util';
import { ItemDataService } from '../../../core/data/item-data.service';
import { RemoteDataBuildService } from '../../../core/cache/builders/remote-data-build.service';
import { Item } from '../../../core/shared/item.model';
import {
  getAllSucceededRemoteDataPayload,
  getFirstCompletedRemoteData,
  getFirstSucceededRemoteDataPayload
} from '../../../core/shared/operators';
import { ViewMode } from '../../../core/shared/view-mode.model';
import { AlertType } from '../../../shared/alert/aletr-type';
import {
  AtmireSavedItemListDataService
} from '../../data-services/atmire-saved-item-list-data.service';
import { RemoteData } from '../../../core/data/remote-data';
import { AtmireSavedItemList } from '../../models/atmire-saved-item-list.model';

@Component({
  selector: 'ds-atmire-saved-item-list-link',
  templateUrl: './atmire-saved-item-list-link.component.html',
  styleUrls: ['./atmire-saved-item-list-link.component.scss'],
  animations: [slide]
})
/**
 * Component displaying a dropdown button with a badge displaying the amount of items the user has selected
 * The dropdown displays a small list of selected items and a link to the user's list
 */
export class AtmireSavedItemListLinkComponent implements OnInit {
  /**
   * The amount of items to display in the dropdown
   * Defaults to 3
   */
  @Input() showAmount = 3;

  /**
   * The item list retrieved from the REST API
   */
  myList$: Observable<RemoteData<AtmireSavedItemList>>;

  /**
   * The amount of items the user currently has selected in his list
   */
  count$: Observable<number>;

  /**
   * The list of items to display within the dropdown
   */
  items$: Observable<RemoteData<Item[]>>;

  /**
   * Whether or not the list should be updated with fresh values
   * When set to false, the list will be cleared until set to true again
   */
  updateList$ = new BehaviorSubject(false);

  /**
   * The view mode of the this component
   */
  viewMode = ViewMode.ListElement;

  /**
   * The AlertType enumeration
   * @type {AlertType}
   */
  AlertTypeEnum = AlertType;

  constructor(protected savedItemListService: AtmireSavedItemListDataService,
              protected itemService: ItemDataService,
              protected rdbService: RemoteDataBuildService) { }

  ngOnInit() {
    this.myList$ = this.savedItemListService.findMyList();
    this.count$ = this.myList$.pipe(
      hasValueOperator(),
      getAllSucceededRemoteDataPayload(),
      switchMap((list) => this.savedItemListService.countItems(list))
    );
    this.items$ = this.updateList$.pipe(
      mergeMap((updateList) => {
        if (updateList) {
          return this.myList$.pipe(
            hasValueOperator(),
            getAllSucceededRemoteDataPayload(),
            switchMap((list) => this.savedItemListService.findItems(list, { elementsPerPage: this.showAmount })),
            getFirstSucceededRemoteDataPayload(),
            map((list) =>
              list.page.map((item) => item.id).filter((id) => isNotEmpty(id))
                .map((id) => this.itemService.findById(id).pipe(getFirstCompletedRemoteData()))
            ),
            switchMap((list) => this.rdbService.aggregate(list))
          );
        } else {
          return [null];
        }
      })
    );
  }

}

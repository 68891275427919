import { Injectable } from '@angular/core';
import { WorkspaceitemSectionUploadFileObject } from './models/workspaceitem-section-upload-file.model';
import { Metadata } from '../shared/metadata.utils';
import { Observable } from 'rxjs';
import { ResponseState } from '../data/request.reducer';
import { isNotEmptyOperator } from '../../shared/empty.util';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { PatchRequest, RestRequest } from '../data/request.models';
import { getResponseFromEntry, sendRequest } from '../shared/operators';
import { RequestService } from '../data/request.service';

/**
 * Service to:
 * - share the uppy dashboard between the UppyTusUploadComponent and the
 * SubmissionSectionUploadFileComponent so that on file delete the correspondingly named file
 * from the uppy dashboard
 * - for the tus/uppy upload during submission of workspaceitems or edit of workflowitems
 */
@Injectable()
export class TusUppyUploadService {

  constructor(
    protected requestService: RequestService) {
  }

  wsiIDToUppyInstance: Map<string, any> = new Map<string, any>();

  addUppyInstance(submissionId: string, uppyInstance: any) {
    this.wsiIDToUppyInstance.set(submissionId, uppyInstance);
  }

  removeFileFromDashboard(submissionId: string, fileId: string, fileData: WorkspaceitemSectionUploadFileObject) {
    const uppy = this.wsiIDToUppyInstance.get(submissionId);
    uppy?.getFiles()
        .filter((file) => {
          return file.name === Metadata.first(fileData.metadata, 'dc.title').value;
        })
        .map((file) => {
          uppy.removeFile(file.id);
        });
  }

  /**
   * Upload a file via url to a workspaceitem or workflowitem
   */
  public uploadFileWithURL(endpointUrl$: Observable<string>, workspaceItemId: string, urlNamePairs: any, tusuploadSectionId: string): Observable<ResponseState> {
    const patchOperation = [
      { op: 'add', path: '/sections/' + tusuploadSectionId + '/files', value: urlNamePairs },
    ];

    return endpointUrl$.pipe(
      isNotEmptyOperator(),
      distinctUntilChanged(),
      map((endpointURL: string) => {
        return new PatchRequest(this.requestService.generateRequestId(), endpointURL, patchOperation);
      }),
      sendRequest(this.requestService),
      switchMap((request: RestRequest) => this.requestService.getByUUID(request.uuid)),
      getResponseFromEntry()
    );
  }

}

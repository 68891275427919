<div class="d-flex flex-column flex-md-row">
  <div class="flex-grow-1">
    <h3 *ngIf="!disableHeader">{{ (configuration ? configuration + '.search.results.head' : 'search.results.head') | translate }}</h3>
  </div>

    <div class="d-flex flex-column align-items-end flex-lg-row">
        <div *ngIf="showExport" class="d-inline-block float-right ml-2">
            <ds-atmire-saved-item-list-selective-export-dropdown [totalResults]="searchResults?.payload?.totalElements">
                                                       [searchConfig]="searchConfig">
          </ds-atmire-saved-item-list-selective-export-dropdown>
        </div>
        <div ngbDropdown placement="bottom-right" class="d-inline-block float-right ml-2">
              <span class="control-label">
                {{ 'pagination.options.sort' | translate }}
              </span>
            <button class="btn btn-outline-light" id="sortControl" ngbDropdownToggle
                    [title]="'pagination.options.sort' | translate"
                    [attr.aria-label]="'pagination.options.sort' | translate"
            >
                {{ 'sorting.' + searchConfig.sort?.field + '.' + searchConfig.sort?.direction | translate }}
                <img src="assets/images/chevron-down.png" alt="">
            </button>
            <div id="sortDropdown" aria-labelledby="sortControl" ngbDropdownMenu>
                <button class="dropdown-item sort-direction-change btn-light" *ngFor="let sortOption of sortOptions"
                        (click)="doSortDirectionChange(sortOption.field, sortOption.direction.toString())">
                    <i [ngClass]="{'invisible': sortOption.field !== searchConfig.sort?.field || sortOption.direction !== (searchConfig.sort?.direction)}"
                       class="fas fa-check" aria-hidden="true"></i>
                    {{ 'sorting.' + sortOption.field + '.' + sortOption.direction | translate }}
                </button>
            </div>
        </div>
        <div ngbDropdown placement="bottom-right" class="d-inline-block float-right ml-2">
              <span class="control-label">
                {{ 'pagination.options.results-per-page' | translate }}
              </span>
            <button class="btn btn-outline-light" id="resultsPerPageControl" ngbDropdownToggle
                    [title]="'pagination.options.results-per-page' | translate"
                    [attr.aria-label]="'pagination.options.results-per-page' | translate"
            >
                {{ searchConfig.pagination?.pageSize }}
                <img src="assets/images/chevron-down.png" alt="">
            </button>
            <div id="resultsPerPageDropdown" aria-labelledby="resultsPerPageControl" ngbDropdownMenu>
                <button class="dropdown-item page-size-change btn-light"
                        *ngFor="let item of searchConfig.pagination?.pageSizeOptions" (click)="doPageSizeChange(item)">
                    <i [ngClass]="{'invisible': item != searchConfig.pagination?.pageSize}" class="fas fa-check"
                       aria-hidden="true"></i>
                    {{ item }}
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="searchResults?.hasSucceeded && !searchResults?.isLoading && searchResults?.payload?.page.length > 0" @fadeIn>
    <ds-viewable-collection
            [config]="searchConfig.pagination"
            [sortConfig]="searchConfig.sort"
            [objects]="searchResults"
            [hideGear]="true"
            [selectable]="selectable"
            [selectionConfig]="selectionConfig"
            [linkType]="linkType"
            [context]="context"
            [hidePaginationDetail]="hidePaginationDetail"
            (deselectObject)="deselectObject.emit($event)"
            (selectObject)="selectObject.emit($event)"
            >
    </ds-viewable-collection>
</div>
<ds-loading
        *ngIf="hasNoValue(searchResults) || hasNoValue(searchResults.payload) || searchResults.isLoading"
        message="{{'loading.search-results' | translate}}"></ds-loading>
<ds-error
        *ngIf="searchResults?.hasFailed && (!searchResults?.errorMessage || searchResults?.statusCode != 400)"
        message="{{'error.search-results' | translate}}"></ds-error>
<div *ngIf="searchResults?.payload?.page.length == 0 || searchResults?.statusCode == 400">
    {{ 'search.results.no-results' | translate }}
    <a [routerLink]="['/search']"
       [queryParams]="{ query: surroundStringWithQuotes(searchConfig?.query) }"
       queryParamsHandling="merge">
        {{"search.results.no-results-link" | translate}}
    </a>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form.value)" class="row" action="/search">
  <div class="col-12">
    <div class="form-group input-group">
        <input type="text" [(ngModel)]="query" name="query" class="form-control" attr.aria-label="{{ searchPlaceholder }}"
               [placeholder]="searchPlaceholder"
               placeholder="{{'search.form.search_placeholder' | translate}}">
        <span class="input-group-append">
            <button type="submit" class="search-button btn btn-{{brandColor}}"><i class="fas fa-search"></i> {{ ('search.form.search' | translate) }}</button>
        </span>
    </div>
  </div>
</form>

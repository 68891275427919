<div class="d-flex align-items-center">
  <div class="flex-grow-1 overflow-hidden">
    <ds-truncatable [id]="dso.id" *ngIf="hasObject()">
        <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"
           [routerLink]="[itemPageRoute]" class="item-list-title"
           [innerHTML]="dsoTitle"></a>
        <span *ngIf="linkType == linkTypes.None" class="item-list-title"
              [innerHTML]="dsoTitle"></span>
    </ds-truncatable>
  </div>
  <div class="ml-2">
    <ds-atmire-saved-item-list-control [item]="dso"></ds-atmire-saved-item-list-control>
  </div>
</div>

<a *ngIf="isVisible | async" class="d-flex flex-row"
   [tabIndex]="-1"
   [routerLink]="[searchLink]"
   [queryParams]="addQueryParams" queryParamsHandling="merge">
  <span class="filter-value px-1 text-monospace">
    {{ 'search.filters.' + filterConfig.name + '.' + filterValue.value | translate: {default: filterValue.value} }}
    <span class="text-muted">({{filterValue.count}})</span>
  </span>
  <div class="unsw-checkbox ml-auto"></div>
</a>

<div class="horizontal-line-chart">
  <ngx-charts-line-chart *ngIf="data$ | async"
                         [results]="data$ | async"
                         [view]="view"
                         [xAxis]="true"
                         [yAxis]="true"
                         [xAxisLabel]="'statlets.type.' + statlet.shortName + '.x-axis' | translate"
                         [yAxisLabel]="'statlets.type.' + statlet.shortName + '.y-axis' | translate"
                         [showXAxisLabel]="true"
                         [showYAxisLabel]="true"
                         [scheme]="colors$ | async"
  >
    <ng-template #seriesTooltipTemplate let-model="model">
      <div class="tooltip-item" *ngFor="let m of model">
        <span class="tooltip-item-color" [style]="'background-color: ' + m.color"></span>
        <span> </span>
        <span [innerHTML]="m.series"></span>
        <span>{{ ': ' + m.value }}</span>
      </div>
    </ng-template>

    <ng-template #tooltipTemplate let-model="model">
      <div class="tooltip-content">
        <span class="tooltip-label"><span [innerHTML]="model.series"></span> • <span>{{ model.name }}</span></span>
        <span class="tooltip-value">{{ model.value }}</span>
      </div>
    </ng-template>
  </ngx-charts-line-chart>
</div>

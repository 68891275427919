import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { focusShadow } from '../../../../../../../app/shared/animations/focus';
import { ItemSearchResultGridElementComponent } from '../../../../../../../app/shared/object-grid/search-result-grid-element/item-search-result/item/item-search-result-grid-element.component';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { Observable } from 'rxjs';
import { Bitstream } from '../../../../../../../app/core/shared/bitstream.model';
import { getFirstSucceededRemoteDataPayload } from '../../../../../../../app/core/shared/operators';

@listableObjectComponent('OrgUnitSearchResult', ViewMode.GridElement, Context.Any, 'unsw')
@Component({
  selector: 'ds-org-unit-search-result-grid-element-unsw',
  styleUrls: ['./org-unit-search-result-grid-element.component.scss'],
  templateUrl: './org-unit-search-result-grid-element.component.html',
  animations: [focusShadow]
})
/**
 * The component for displaying a grid element for an item search result of the type Organisation Unit
 */
export class OrgUnitSearchResultGridElementComponent extends ItemSearchResultGridElementComponent {

  getThumbnail(): Observable<Bitstream> {
    return this.bitstreamDataService
        .findByHref(this.dso._links.thumbnail.href)
        .pipe(getFirstSucceededRemoteDataPayload());
  }
}

<div class="row">
    <ds-search-sidebar class="col-4" id="search-sidebar"
                       [resultCount]="(resultsRD$ | async)?.payload?.totalElements"
                       [inPlaceSearch]="true" [showViewModes]="false"></ds-search-sidebar>
    <div class="col-8">
        <ds-themed-search-form [query]="query"
                        [inPlaceSearch]="true"
                        [searchPlaceholder]="'submission.sections.describe.relationship-lookup.search-tab.search-form.placeholder' | translate">
        </ds-themed-search-form>

        <ds-search-results [searchResults]="(resultsRD$ | async)"
                           [sortConfig]="this.lookupRelationService.searchConfig?.sort"
                           [sortOptions]="sortOptions$ |async"
                           [searchConfig]="this.lookupRelationService.searchConfig"
                           [selectable]="true"
                           [selectionConfig]="{ repeatable: repeatable, listId: listId }"
                           [linkType]="linkTypes.ExternalLink"
                           [context]="context"
                           [showExport]="false"
                           (deselectObject)="deselectObject.emit($event)"
                           (selectObject)="selectObject.emit($event)">
        </ds-search-results>
    </div>
</div>

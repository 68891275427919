import { Component, Input } from '@angular/core';
import { AbstractAtmireSearchListElementComponent } from '../../list-element/abstract-atmire-search-list-element.component';
import { VocabularyEntry } from '../../../../../../../../core/submission/vocabularies/models/vocabulary-entry.model';
import { MetadataMap, MetadataValue } from '../../../../../../../../core/shared/metadata.models';
import { hasNoValue, hasValue, isEmpty, isNotEmpty } from '../../../../../../../empty.util';
import { Metadata } from '../../../../../../../../core/shared/metadata.utils';
import { slide } from '../../../../../../../animations/slide';
import { LinkedAuthorityValues } from './linked-authority-values.model';
import { HrefOnlyDataService } from '../../../../../../../../core/data/href-only-data.service';
import { combineLatest } from 'rxjs';
import { getFirstSucceededRemoteDataPayload } from '../../../../../../../../core/shared/operators';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'ds-atmire-search-vocabulary-entry-list-element',
  styleUrls: ['./atmire-search-vocabulary-entry-list-element.component.scss'],
  templateUrl: './atmire-search-vocabulary-entry-list-element.component.html',
  animations: [slide],
})
export class AtmireSearchVocabularyEntryListElementComponent extends AbstractAtmireSearchListElementComponent<VocabularyEntry> {
  @Input() metadata: MetadataMap;
  @Input() title: string;

  /**
   * The metadata value for the object's first name
   */
  firstName: MetadataValue;

  /**
   * The metadata value for the object's last name
   */
  lastName: MetadataValue;

  /**
   * The metadata value for the object's orcid
   */
  orcid: MetadataValue;

  /**
   * The metadata value for the object's uri
   */
  uri: MetadataValue;

  /**
   * Extra UNSW metadata
   */
  zID: MetadataValue;
  personTitle: MetadataValue;
  initials: MetadataValue;
  email: MetadataValue;
  position: MetadataValue;
  school: MetadataValue;
  faculty: MetadataValue;

  grantNo: MetadataValue;
  refNo: MetadataValue;
  projectTitle: MetadataValue;
  projectDesc: MetadataValue;
  projectStartDate: MetadataValue;
  projectEndDate: MetadataValue;
  fundingAgency: MetadataValue;
  fundingScheme: MetadataValue;
  classification: MetadataValue[] = [];
  grantZID: MetadataValue[] = [];

  /**
   * A list of HAL links to follow for retrieving and displaying linked authorities
   * The order of this list matters, as they will be displayed in the order defined
   */
  linkedAuthorityFields = ['dc_contributor_other', 'unsw_contributor_leadChiefInvestigator'];

  /**
   * An observable list of resolved linked authority values to display
   */
  linkedAuthorityValues$: Observable<LinkedAuthorityValues[]>;

  /**
   * Whether or not the extra metadata section and its toggle should be visible
   * Setting this to false will hide the extra metadata toggle and section
   */
  extraMetadataSectionEnabled = true;

  /**
   * Whether or not the metadata section is expanded
   * Only relevant if extraMetadataSectionEnabled is true
   */
  expanded = false;

  /**
   * Whether or not the object has extra metadata to show
   * If false, the collapse button will be hidden
   * Only relevant if extraMetadataSectionEnabled is true
   */
  hasExtraMetadata = false;

  constructor(protected hrefDataService: HrefOnlyDataService) {
    super();
  }

  ngOnInit(): void {
    if (hasValue(this.element)) {
      if (hasNoValue(this.metadata)) {
        this.metadata = this.element.object.metadata;
      }
      if (isEmpty(this.title)) {
        this.title = this.element.selectedValue;
      }
    }

    this.initMetadata();
    this.initLinkedAuthorities();

    this.hasExtraMetadata =
      hasValue(this.firstName) || hasValue(this.lastName) || hasValue(this.orcid) || hasValue(this.uri) ||
      hasValue(this.zID) || hasValue(this.personTitle) || hasValue(this.initials) || hasValue(this.email) ||
      hasValue(this.position) || hasValue(this.faculty) || hasValue(this.school) || hasValue(this.grantNo) ||
      hasValue(this.refNo) || hasValue(this.projectTitle) || hasValue(this.projectDesc) || hasValue(this.projectStartDate) ||
      hasValue(this.projectEndDate) || hasValue(this.fundingAgency) || hasValue(this.fundingScheme) ||
      isNotEmpty(this.classification) || isNotEmpty(this.grantZID);
  }

  /**
   * Initialise the metadata values to display
   */
  initMetadata() {
    if (hasValue(this.metadata)) {
      this.firstName = Metadata.first(this.metadata, 'ac.person.firstName');
      this.lastName = Metadata.first(this.metadata, 'ac.person.lastName');
      this.orcid = Metadata.first(this.metadata, 'ac.person.orcid');

      this.zID = Metadata.first(this.metadata, 'ac.person.id');
      this.personTitle = Metadata.first(this.metadata, 'ac.person.title');
      this.initials = Metadata.first(this.metadata, 'ac.person.initials');
      this.email = Metadata.first(this.metadata, 'ac.person.email');
      this.position = Metadata.first(this.metadata, 'ac.person.position');
      this.faculty = Metadata.first(this.metadata, 'unsw.description.faculty');
      this.school = Metadata.first(this.metadata, 'unsw.description.school');

      this.grantNo = Metadata.first(this.metadata, 'unsw.relation.unswGrantNo');
      this.refNo = Metadata.first(this.metadata, 'unsw.relation.FunderRefNo');
      this.projectTitle = Metadata.first(this.metadata, 'unsw.relation.projectTitle');
      this.projectDesc = Metadata.first(this.metadata, 'unsw.relation.projectDesc');
      this.projectStartDate = Metadata.first(this.metadata, 'unsw.relation.projectStartDate');
      this.projectEndDate = Metadata.first(this.metadata, 'unsw.relation.projectEndDate');
      this.fundingAgency = Metadata.first(this.metadata, 'unsw.relation.fundingAgency');
      this.fundingScheme = Metadata.first(this.metadata, 'unsw.relation.fundingScheme');
      this.classification = Metadata.all(this.metadata, ['unsw.subject.SEOcode', 'unsw.subject.fieldofresearchcode']);
      this.grantZID = Metadata.all(this.metadata, 'grant.employer');
    }
  }

  /**
   * Initialise the linked authorities to display
   * The HAL links used to retrieve these are defined in linkedAuthorityFields
   */
  initLinkedAuthorities() {
    if (hasValue(this.element) && hasValue(this.element.object) && isNotEmpty(this.element.object._links.linkedAuthorities)) {
      const links = this.linkedAuthorityFields.map((field) => this.element.object._links.linkedAuthorities.find((link) => link.name === field)).filter((link) => hasValue(link));
      if (isNotEmpty(links)) {
        this.linkedAuthorityValues$ = combineLatest(...links.map((link) => {
          return this.hrefDataService.findAllByHref<VocabularyEntry>(link.href).pipe(
            getFirstSucceededRemoteDataPayload(),
            map((linkedAuthorities) => {
              return new LinkedAuthorityValues(link.name, linkedAuthorities.page.map((entry) => entry.display));
            })
          );
        }));
      }
    }
  }
}

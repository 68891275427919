<div class="jumbotron jumbotron-fluid pb-0">
  <div class="container">
    <div class="row">
      <h1 class="unsw-title">{{ 'unsw.homepage.title' | translate }}</h1>
    </div>
    <div class="row">
      <p class="unsw-intro">{{ 'unsw.homepage.intro' | translate }}</p>
    </div>
  </div>
</div>

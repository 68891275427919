import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nBreadcrumbResolver } from '../core/breadcrumbs/i18n-breadcrumb.resolver';
import { I18nBreadcrumbsService } from '../core/breadcrumbs/i18n-breadcrumbs.service';
import {
  SEARCH_REPORTS_MODULE_PATH,
  STATLETS_MODULE_PATH,
  STORAGE_REPORTS_MODULE_PATH,
  USAGE_REPORTS_MODULE_PATH
} from './atmire-cua-routing-paths';
import { SiteAdministratorGuard } from '../core/data/feature-authorization/feature-authorization-guard/site-administrator.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: USAGE_REPORTS_MODULE_PATH,
        loadChildren: () => import('./usage-reports/usage-reports.module')
          .then((m) => m.UsageReportsModule),
      },      {
        path: STORAGE_REPORTS_MODULE_PATH,
        loadChildren: () => import('./storage-reports/storage-reports.module')
          .then((m) => m.StorageReportsModule),
        canActivate: [SiteAdministratorGuard],
      },
      {
        path: SEARCH_REPORTS_MODULE_PATH,
        loadChildren: () => import('./search-reports/search-reports.module')
          .then((m) => m.SearchReportsModule),
        resolve: {
          breadcrumb: I18nBreadcrumbResolver
        },
        data: { breadcrumbKey: 'search-reports.page' },
        canActivate: [SiteAdministratorGuard],
      },
      {
        path: STATLETS_MODULE_PATH,
        loadChildren: () => import('./statlets/statlets.module')
          .then((m) => m.StatletsModule),
      },
    ])
  ],
  providers: [
    I18nBreadcrumbResolver,
    I18nBreadcrumbsService
  ]
})
export class AtmireCuaRoutingModule {
}

import { AlternativeLabel } from './models/alternative-label.model';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlternativeLabelService {
  constructor(protected translateService: TranslateService) {
  }

  transform(alternativeLabel: AlternativeLabel): Observable<string> {
    if (alternativeLabel.type === 'i18n') {
      return this.translateService.get(alternativeLabel.value);
    }
    if (alternativeLabel.type === 'fa-icon') {
      let modifier = '';
      if (alternativeLabel.value.startsWith('fas-')) {
        modifier = 's';
      }
      const splitValue = alternativeLabel.value.split('-');
      const value = splitValue[splitValue.length - 1];
      return observableOf(`<i class="fa${modifier} fa-${value}"></i>`);
    }
    return observableOf(alternativeLabel.value);
  }
}

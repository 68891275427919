<ds-themed-home-news></ds-themed-home-news>
<div class="container">
  <ng-container *ngIf="(site$ | async) as site">
    <ds-view-tracker [object]="site"></ds-view-tracker>
  </ng-container>
  <ds-themed-search-form [inPlaceSearch]="false" [searchPlaceholder]="'home.search-form.placeholder' | translate"></ds-themed-search-form>
  <ds-top-level-community-list *ngIf="!(hasOrgUnitCommunityConfig$ | async)"></ds-top-level-community-list>
  <ds-organisation-unit-list *ngIf="(hasOrgUnitCommunityConfig$ | async)"></ds-organisation-unit-list>
  <ng-container *ngIf="(site$ | async) as site">
    <ds-atmire-cua-statlets-section [dso]="site"></ds-atmire-cua-statlets-section>
  </ng-container>
</div>

<ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>

<div class="d-flex align-items-center">
  <div class="flex-grow-1 overflow-hidden">
    <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
        <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"
           [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out"
           [innerHTML]="dsoTitle"></a>
        <span *ngIf="linkType == linkTypes.None" class="lead item-list-title dont-break-out"
              [innerHTML]="dsoTitle"></span>
        <span class="text-muted">
          <ds-truncatable-part [id]="dso.id" [minLines]="1">
            <ng-container *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">
              (<span *ngIf="dso.firstMetadataValue('dc.publisher')" class="item-list-publisher" [innerHTML]="firstMetadataValue('dc.publisher') + ', '"></span>
              <span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)
            </ng-container>
            <span *ngIf="dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0" class="item-list-authors">
              <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
                  <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
                  <span *ngIf="!last">; </span>
              </span>
            </span>
          </ds-truncatable-part>
        </span>
        <ds-item-list-repeatable-md-badge [object]="dso" [fields]="['dc.type']"></ds-item-list-repeatable-md-badge>
    <div *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
            <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                    [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
            </ds-truncatable-part>
        </div>
    </ds-truncatable>
  </div>
  <div class="ml-2">
    <ds-atmire-saved-item-list-control *ngIf="savedItemListControlEnabled" [item]="dso"></ds-atmire-saved-item-list-control>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { AtmireSelectiveExportOption } from '../../models/atmire-selective-export-option.model';
import { Observable } from 'rxjs';
import { ScriptDataService } from '../../../core/data/processes/script-data.service';
import { AuthService } from '../../../core/auth/auth.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  getAtmireSelectiveExportOptions,
  handleItemExportResponse,
} from '../../atmire-selective-export.util';
import { getFirstCompletedRemoteData } from '../../../core/shared/operators';
import { RemoteData } from '../../../core/data/remote-data';
import { Process } from '../../../process-page/processes/process.model';
import { AuthorizationDataService } from '../../../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../../../core/data/feature-authorization/feature-id';

@Component({
  selector: 'ds-abstract-atmire-export-dropdown',
  template: '',
})
/**
 * Abstract component for displaying a dropdown menu, listing export options
 */
export class AbstractAtmireExportDropdownComponent implements OnInit {
  /**
   * Whether or not the display should be compact. This means:
   * No label, dropdown arrow and small button size
   */
  @Input() compact = false;

  /**
   * A list of export options
   */
  exportOptions: AtmireSelectiveExportOption[];

  /**
   * Is the current user authorized?
   */
  isAuthorized$: Observable<boolean>;

  constructor(protected scriptDataService: ScriptDataService,
              protected authService: AuthService,
              protected authorizationService: AuthorizationDataService,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService,
              protected router: Router) { }

  ngOnInit() {
    this.exportOptions = getAtmireSelectiveExportOptions().filter((option) => !option.disabled);
    this.isAuthorized$ = this.authorizationService.isAuthorized(FeatureID.AdministratorOf);
  }

  /**
   * Export items using the current discovery arguments and selected export type
   * @param exportOption  The export type to export items to
   */
  export(exportOption: AtmireSelectiveExportOption) {
    this.invokeScript(exportOption).pipe(getFirstCompletedRemoteData()).subscribe((rd) => {
      handleItemExportResponse(this.notificationsService, this.translate, this.router, rd);
    });
  }

  /**
   * Abstract method, should be overwritten in the sub class
   * @param exportOption
   */
  invokeScript(exportOption: AtmireSelectiveExportOption): Observable<RemoteData<Process>> {
    return null;
  }

}

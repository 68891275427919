import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { find, map, switchMap, take } from 'rxjs/operators';
import { CommunityDataService } from '../../core/data/community-data.service';
import { RemoteData } from '../../core/data/remote-data';
import { Community } from '../../core/shared/community.model';
import { fadeInOut } from '../../shared/animations/fade';
import { PaginationComponentOptions } from '../../shared/pagination/pagination-component-options.model';
import { Item } from '../../core/shared/item.model';
import { Collection } from '../../core/shared/collection.model';
import { getFirstSucceededRemoteDataPayload } from '../../core/shared/operators';
import { DSpaceObjectType } from '../../core/shared/dspace-object-type.model';
import { ConfigurationDataService } from '../../core/data/configuration-data.service';
import { CollectionDataService } from '../../core/data/collection-data.service';
import { ConfigurationProperty } from '../../core/shared/configuration-property.model';
import { followLink } from '../../shared/utils/follow-link-config.model';
import { createPendingRemoteDataObject } from '../../shared/remote-data.utils';
import { PaginatedList } from '../../core/data/paginated-list.model';
import { SortDirection, SortOptions } from '../../core/cache/models/sort-options.model';
import { getItemPageRoute } from '../../item-page/item-page-routing-paths';
import { AtmireSearchListableObjectSourceModel } from '../../atmire-object-collection/listable-object-sources';
import { ViewMode } from '../../core/shared/view-mode.model';

/**
 * This component renders the list of OrgUnits (ie all items in community from config orgunit.community.uuid)
 */
@Component({
  selector: 'ds-organisation-unit-list',
  templateUrl: './organisation-unit-list.component.html',
  styleUrls: ['./organisation-unit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut]
})
export class OrganisationUnitListComponent implements OnInit {
  /**
   * A list of remote data objects of all items in orgUnit community
   */
  orgunitsRD$: BehaviorSubject<RemoteData<PaginatedList<Item>>> = new BehaviorSubject<RemoteData<PaginatedList<Item>>>({} as any);
  orgUnitCollections$: Observable<PaginatedList<Collection>>;

  /**
   * The pagination configuration
   */
  config: PaginationComponentOptions;
  sortConfig: SortOptions;
  ViewModes =  ViewMode;
  /**
   * The pagination id
   */
  pageId = 'orgunit-list-pagination';

  MAX_ORG_UNITS_PER_PAGE = 20;
  orgUnitsSourceModel$: Observable<AtmireSearchListableObjectSourceModel>;

  constructor(private communityDataService: CommunityDataService,
              private configurationDataService: ConfigurationDataService,
              private collectionDataService: CollectionDataService) {
    this.config = new PaginationComponentOptions();
    this.config.id = this.pageId;
    this.config.pageSize = this.MAX_ORG_UNITS_PER_PAGE;
    this.config.currentPage = 1;
    this.sortConfig = new SortOptions('dc.date.accessioned', SortDirection.ASC);
    this.orgunitsRD$.next(createPendingRemoteDataObject());
  }

  ngOnInit() {
    const orgUnitCommunityConfig: Observable<RemoteData<ConfigurationProperty>> =
      this.configurationDataService.findByPropertyName('orgunit.community.uuid');
    this.orgUnitCollections$ = orgUnitCommunityConfig.pipe(
      find((rd: RemoteData<ConfigurationProperty>) => rd.hasSucceeded),
      map((rd: RemoteData<ConfigurationProperty>) => rd.payload),
      switchMap((config: ConfigurationProperty) => {
        if (config.values.length > 0) {
          const orgUnitCommunityUUID: string = config.values[0];
          return this.communityDataService.findById(orgUnitCommunityUUID, true, true, followLink('collections'));
        }
      }),
      getFirstSucceededRemoteDataPayload(),
      switchMap((orgUnitCommunity: Community) => {
        return this.collectionDataService.findAllByHref(orgUnitCommunity._links.collections.href);
      }),
      getFirstSucceededRemoteDataPayload()
    );
    this.updatePage();
  }

  /**
   * Called when one of the pagination settings is changed
   * @param event The new pagination data
   */
  onPaginationChange(event) {
    this.config.currentPage = event;
    this.config.pageSize = this.config.pageSize;
    this.updatePage();
  }

  /**
   * Update the list of organisational units
   */
  updatePage() {
    this.orgUnitsSourceModel$ = this.orgUnitCollections$.pipe(
      take(1),
      map((collections: PaginatedList<Collection>) => {
        return new AtmireSearchListableObjectSourceModel(
            {
              dsoTypes: [DSpaceObjectType.ITEM],
              scope: collections.page[0].id
            },
            true  // Show regular DSOs instead of search results. This can be useful if there are styling differences between
            // <ds-*-search-result-list-element> and regular <ds-*-list-element> components.
        );

        })
      );
  }

  getOrgUnitRoute(orgUnit: Item): string {
    return getItemPageRoute(orgUnit);
  }
}

import { Component, Input } from '@angular/core';
import { Item } from '../../../core/shared/item.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RemoteData } from '../../../core/data/remote-data';
import { PaginatedList } from '../../../core/data/paginated-list.model';
import { FindListOptions } from '../../../core/data/request.models';
import { ViewMode } from '../../../core/shared/view-mode.model';
import { RelationshipService } from '../../../core/data/relationship.service';
import { AbstractIncrementalListComponent } from '../abstract-incremental-list/abstract-incremental-list.component';
import { map } from 'rxjs/operators';
import { getFirstSucceededRemoteData } from '../../../core/shared/operators';
import { DSONameService } from '../../../core/breadcrumbs/dso-name.service';
import { createSuccessfulRemoteDataObject$ } from '../../../shared/remote-data.utils';

/**
 * Interface for combined listable objects and plaintext entries in a single pagination
 */
interface RelatedItemPage {
  /**
   * The actual related Items on this page
   */
  items: Item[];
  /**
   * The plaintext values on this page
   */
  plain: string[];
  /**
   * The size of the next page. "Show more" should be disabled if this is equal to 0.
   */
  nextPageSize: number;
}

@Component({
  selector: 'ds-related-items',
  styleUrls: ['./related-items.component.scss'],
  templateUrl: './related-items.component.html'
})
/**
 * This component is used for displaying relations between items
 * It expects a parent item and relationship type, as well as a label to display on top
 */
export class RelatedItemsComponent extends AbstractIncrementalListComponent<Observable<RelatedItemPage>> {
  /**
   * The parent of the list of related items to display
   */
  @Input() parentItem: Item;

  /**
   * The label of the relationship type to display
   * Used in sending a search request to the REST API
   */
  @Input() relationType: string;

  /**
   * The amount to increment the list by when clicking "view more"
   * Defaults to 5
   * The default can optionally be overridden by providing the limit as input to the component
   */
  @Input() incrementBy = 5;

  /**
   * Default options to start a search request with
   * Optional input
   */
  @Input() options = new FindListOptions();

  /**
   * An i18n label to use as a title for the list (usually describes the relation)
   */
  @Input() label: string;

  /**
   * If not null, any additional values of this field that are not shown as listable object related Items
   * will be shown as plain text below them.
   */
  @Input() plainField: string = null;

  /**
   * An optional list of classes to apply to plain text values
   */
  @Input() plainClass = '';

  private totalRelationships: number = null;
  private totalPlainValues: number = null;
  private allRelationshipNames: Set<string> = new Set<string>();

  /**
   * Whether to show the loading animation
   */
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * The view-mode we're currently on
   * @type {ViewMode}
   */
  viewMode = ViewMode.ListElement;

  constructor(
    public relationshipService: RelationshipService,
    public dsoNameService: DSONameService,
  ) {
    super();
  }

  /**
   * Get a specific page
   * Can extend beyond the pagination of getRelatedItemsByLabel if {@link plainField} includes additional MDVs
   * @param page  The page to fetch
   */
  getPage(page: number): Observable<RelatedItemPage> {
    // Don't show loading animation on first render:
    this.isLoading$.next(page !== 1);

    // getRelatedItemsByLabel will fail if we request a page > totalPages, so we fake an empty RD$ in that case
    let relationshipsRD$: Observable<RemoteData<PaginatedList<Item>> | null>;
    if (this.totalRelationships === null || page <= Math.ceil(this.totalRelationships / this.incrementBy)) {
      relationshipsRD$ = this.relationshipService.getRelatedItemsByLabel(
        this.parentItem, this.relationType, Object.assign(this.options, {
          elementsPerPage: this.incrementBy,
          currentPage: page,
        })
      );
    } else {
      relationshipsRD$ = createSuccessfulRemoteDataObject$({ page: [] } as PaginatedList<Item>);
    }

    return relationshipsRD$.pipe(
      getFirstSucceededRemoteData(),
      map(itemsRD => {
        this.isLoading$.next(false);

        // Keep track of the total number of related Items
        if (itemsRD.payload.totalElements !== undefined) {
          this.totalRelationships = itemsRD.payload.totalElements;
        }

        const items = itemsRD.payload.page;

        // Keep track of the names of related Items
        for (const item of items) {
          this.allRelationshipNames.add(this.dsoNameService.getName(item));
        }

        // If we have enough related Items for this page, don't bother with plaintext values
        const lastFullyRelatedItemsPage = Math.floor(this.totalRelationships / this.incrementBy);
        if (page < lastFullyRelatedItemsPage) {
          return {
            items, plain: [], nextPageSize: this.incrementBy  // here?
          } as RelatedItemPage;
        }

        // If the _next_ page may contain plaintext values, include them when counting the size of the next page
        const allPlainValues = this.getPlainTextValues();
        if (page === lastFullyRelatedItemsPage) {
          // Assuming that plain values _must_ include all actual relationships
          return {
            items, plain: [], nextPageSize: Math.min(
              this.totalRelationships % this.incrementBy + (this.totalPlainValues - this.totalRelationships), this.incrementBy
            )
          } as RelatedItemPage;
        } else {
          // Include plaintext values in the current page
          let plain: string[];

          // For all plain text values leading up to the current page, splice a fragment from the front of the list
          // Take into account that the first page may contain some non-plaintext values as well
          plain = allPlainValues.splice(0, this.incrementBy - (this.totalRelationships % this.incrementBy));
          for (let pageWithPlain = lastFullyRelatedItemsPage + 2; pageWithPlain <= page; pageWithPlain++) {
            // Subsequent pages contain only plaintext values
            plain = allPlainValues.splice(0, this.incrementBy);
          }
          return {
            items,
            plain,                                                           // the last fragment is the current page
            nextPageSize: Math.min(allPlainValues.length, this.incrementBy), // number of remaining plaintext values
          } as RelatedItemPage;
        }
      })
    );
  }

  private getPlainTextValues(): string[] {
    if (this.plainField === null) {
      this.totalPlainValues = 0;
      return [];
    }

    const allPlainValues = this.parentItem.allMetadataValues(this.plainField);

    if (this.totalPlainValues === null) {
      this.totalPlainValues = allPlainValues.length;
    }

    // Filter out plain text values that are already shown as actual related Items
    return allPlainValues.filter(mdv => !this.allRelationshipNames.has(mdv));
  }
}

import { Component } from '@angular/core';
import { rendersSingleStatletWrapper } from '../single-statlet-wrapper.decorator';
import { SingleStatletWrapperComponent } from '../single-statlet-wrapper.component';
import { Context } from '../../../../../core/shared/context.model';

@Component({
  selector: 'ds-atmire-cua-single-statlet-inline',
  templateUrl: './single-statlet-inline.component.html',
  styleUrls: ['./single-statlet-inline.component.scss']
})
@rendersSingleStatletWrapper(Context.StatletInline)
export class SingleStatletInlineComponent extends SingleStatletWrapperComponent {
}

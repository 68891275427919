 <ng-container *ngVar="(bitstreams$ | async) as bitstreams">
  <ds-metadata-field-wrapper *ngIf="bitstreams?.length > 0" [label]="label | translate">
    <table id="file-section" class="table table-hover">
      <tbody>
        <tr *ngFor="let file of bitstreams; let last=last;">
          <td class="text-monospace col-2">
            <img src="assets/images/files-download.svg" alt="download">
            <ds-file-download-link [bitstream]="file">
              <span>{{file?.name}}</span>
            </ds-file-download-link>
          </td>
          <td class="col-2">{{ (file?.sizeBytes) | dsFileSize }}</td>
          <td class="col-4">{{(file.format | async)?.payload?.description}}</td>
          <td class="col-4">{{ getDescription(file) }}</td>
        </tr>
      </tbody>
    </table>
    <ds-loading *ngIf="isLoading" message="{{'loading.default' | translate}}" [showMessage]="false"></ds-loading>
    <div *ngIf="!isLastPage" class="mt-1" id="view-more">
      <a class="bitstream-view-more btn btn-outline-secondary btn-sm" [routerLink]=""
         (click)="getNextPage()">{{'item.page.bitstreams.view-more' | translate}}</a>
    </div>
    <div *ngIf="isLastPage && currentPage != 1" class="mt-1" id="collapse">
      <a class="bitstream-collapse btn btn-outline-secondary btn-sm" [routerLink]=""
         (click)="currentPage = undefined; getNextPage();">{{'item.page.bitstreams.collapse' | translate}}</a>
    </div>
  </ds-metadata-field-wrapper>
</ng-container>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, take } from 'rxjs/operators';
import { getFirstSucceededRemoteData, getRemoteDataPayload } from '../../../core/shared/operators';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../core/data/remote-data';
import { Item } from '../../../core/shared/item.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ItemDataService } from '../../../core/data/item-data.service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { MetadataValue } from '../../../core/shared/metadata.models';
import { hasValue, isNotEmpty } from '../../../shared/empty.util';
import { getItemEditPath } from '../../item-page-routing-paths';
import { Metadata } from '../../../core/shared/metadata.utils';

@Component({
  selector: 'ds-item-take-down',
  templateUrl: './item-take-down.component.html'
})
/**
 * Component responsible for taking down an item
 */
export class ItemTakeDownComponent implements OnInit, OnDestroy {
  itemRD$: Observable<RemoteData<Item>>;
  reason: string;

  private currentItem: Item;
  private takeDownField = 'local.takedown.reason';

  /**
   * Subscription to unsubscribe from on destroy
   */
  sub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private notificationsService: NotificationsService,
              private itemDataService: ItemDataService,
              private translateService: TranslateService) {

  }

  ngOnInit(): void {
    this.itemRD$ = this.route.data.pipe(map((data) => data.dso), getFirstSucceededRemoteData()) as Observable<RemoteData<Item>>;
    this.itemRD$.subscribe((rd) => {
        this.currentItem = rd.payload;
        const metadataValue = this.currentItem.firstMetadata(this.takeDownField);
        if (hasValue(metadataValue)) {
          this.reason = metadataValue.value;
        }
      }
    );
  }

  /**
   * Submits the item with updated take down reason to the server
   */
  submit() {
    const updateItem = cloneDeep(this.currentItem);
    const newMetadata = updateItem.metadata;
    Metadata.setFirstValue(newMetadata, this.takeDownField, this.reason);
    updateItem.metadata = newMetadata;
    this.itemDataService.update(updateItem).pipe(take(1)).subscribe();
    this.checkItemAndNotify();
  }

  /**
   * Check if item has now changed takedown reason
   */
  private checkItemAndNotify() {
    this.itemDataService.clearCacheForItem(this.currentItem._links.self.href);
    this.sub = this.itemDataService.findById(this.currentItem.id, false, true).pipe(getFirstSucceededRemoteData(), getRemoteDataPayload()).subscribe((item: Item) => {
      const takedownMD = item.firstMetadata(this.takeDownField);
      if (takedownMD !== undefined) {
        if (takedownMD.value === this.reason) {
          this.notificationsService.success(this.translateService.get('item.edit.take-down.submit.success.header'));
          this.router.navigate([getItemEditPath(this.currentItem.id)]);
        } else {
          this.notificationsService.error(this.translateService.get('item.edit.take-down.submit.error.header'));
        }
      } else {
        this.notificationsService.error(this.translateService.get('item.edit.take-down.submit.error.header'));
      }
    });
  }

  /**
   * Make sure the subscription is unsubscribed from when this component is destroyed
   */
  ngOnDestroy(): void {
    if (hasValue(this.sub)) {
      this.sub.unsubscribe();
    }
  }
}

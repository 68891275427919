import { autoserialize } from 'cerialize';
import { hasValue } from '../../shared/empty.util';

export class UsageStatisticsGroup {
  @autoserialize
  groupType: string;

  @autoserialize
  other: boolean;

  @autoserialize
  total: boolean;

  @autoserialize
  valueType: string;

  @autoserialize
  start: string;

  @autoserialize
  end: string;

  @autoserialize
  type: string;

  @autoserialize
  category: string;

  @autoserialize
  limit: number;

  @autoserialize
  uuid: string;

  toParams(): { [key: string]: string } {
    const params = Object.assign({});
    if (hasValue(this.start)) {
      params['group.start'] = this.start;
    }
    if (hasValue(this.end)) {
      params['group.end'] = this.end;
    }
    if (hasValue(this.limit)) {
      params['group.limit'] = this.limit;
    }
    return params;
  }
}

<ul class="navbar-nav">
  <li class="nav-item">
    <div class="dropdown" ngbDropdown (openChange)="updateList$.next($event)" display="dynamic" placement="bottom-right">
      <a ngbDropdownToggle
         class="d-flex flex-row my-list-link nav-link dropdown-toggle"
         href="javascript:void(null);"
         id="dropdowMyList"
      >
        <span>
          <img src="assets/images/my-list.svg" alt="My list" class="mt-n1">
          {{ 'atmire.saved-item-list.link.dropdown-toggle' | translate }}
        </span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdowMyList">
        <h5 class="dropdown-item-text">{{ 'atmire.saved-item-list.link.header' | translate }}</h5>
        <div class="dropdown-item-text" *ngVar="(items$ | async) as items">
          <div *ngFor="let item of items?.payload; let i = index">
            <ds-listable-object-component-loader [object]="item" [viewMode]="viewMode" [index]="i"></ds-listable-object-component-loader>
          </div>
          <ds-alert *ngIf="items?.payload?.length === 0" [type]="AlertTypeEnum.Info">{{ 'atmire.saved-item-list.link.items.empty' | translate }}</ds-alert>
          <ds-loading *ngIf="!items || items?.isLoading" [message]="'atmire.saved-item-list.link.items.loading' | translate"></ds-loading>
        </div>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" [routerLink]="'my-lists'">{{ ((count$ | async) > showAmount) ? ('atmire.saved-item-list.link.see-more' | translate:{ amount: (count$ | async) - showAmount }) : ('atmire.saved-item-list.link.go-to' | translate) }}</a>
      </div>
    </div>
  </li>
</ul>

<div class="facet-filter d-block mb-3 px-3 py-2">
    <div (click)="toggle()" class="filter-name">
        <h6 class="d-inline-block mb-0">
            {{'search.filters.filter.' + filter.name + '.head'| translate}}
        </h6>
        <span class="filter-toggle float-right"
              [title]="((collapsed$ | async) ? 'search.filters.filter.expand' : 'search.filters.filter.collapse') | translate"
              [attr.aria-label]="((collapsed$ | async) ? 'search.filters.filter.expand' : 'search.filters.filter.collapse') | translate">
      <img [src]="(collapsed$ | async) ? 'assets/images/plus.png' : 'assets/images/minus.png'">
    </span>
    </div>
    <div [@slide]="(collapsed$ | async) ? 'collapsed' : 'expanded'"
         (@slide.start)="startSlide($event)" (@slide.done)="finishSlide($event)"
         class="search-filter-wrapper" [ngClass]="{'closed' : closed}">
        <ds-search-facet-filter-wrapper
                [filterConfig]="filter"
                [inPlaceSearch]="inPlaceSearch">
        </ds-search-facet-filter-wrapper>
    </div>
</div>

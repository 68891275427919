import { Observable } from 'rxjs';
import { SiteDataService } from '../../../../../core/data/site-data.service';
import { StatletConfig } from './statlet-config.model';
import { map, switchMap } from 'rxjs/operators';
import { Statlet } from './statlet.model';
import { hasValue, isNotEmpty } from '../../../../../shared/empty.util';
import { ActivatedRoute, Params } from '@angular/router';
import { StatletFilter } from './statlet-filter.model';

export class StatletPointRoute {
  href: string;
  queryParams?: Params;
}

/**
 * Build {@link StatletConfig} for an object from a given {@link StatletReportType}
 * @param siteService
 */
export const buildStatletConfigFromShortNameAndObjectId = (siteService: SiteDataService) =>
  (source: Observable<[string, string]>): Observable<StatletConfig> =>
    source.pipe(
      switchMap(([shortName, objectId]) => {
        if (isNotEmpty(objectId)) {
          return [[shortName, objectId]];
        } else {
          return siteService.find().pipe(
            map((site) => [shortName, site.id])
          );
        }
      }),
      map(([shortName, objectId]) => Object.assign(new StatletConfig(), {
        dso: objectId,
        shortName,
      }))
    );

/**
 * Add parameters from the current route to a {@link StatletConfig} object
 * @param route
 */
export const addRouteFiltersToStatletConfig = (route: ActivatedRoute) =>
  (source: Observable<StatletConfig>): Observable<StatletConfig> =>
    source.pipe(
      switchMap((config) => route.queryParams.pipe(
        map((params) => {
          const filters = Object.assign({}, config.filters);
          let reportSize = null;
          let sort = null;
          Object.entries(params).forEach(([key, value]) => {
            if (key.startsWith('f.')) {
              if (isNotEmpty(filters[key])) {
                filters[key].push(value);
              } else {
                filters[key] = [value];
              }
            }
            if (key === 'size') {
              reportSize = value;
            }
            if (key === 'sort') {
              sort = value;
            }
          });
          return Object.assign(new StatletConfig(), config, { filters, reportSize, sort });
        })
      ))
    );

/**
 * Determine if a {@link Statlet} contains at least one data point with a value higher than 0
 * @param statlet
 */
export function hasStatletData(statlet: Statlet): boolean {
  return isNotEmpty(statlet.points) && hasValue(
    statlet.points.find((point) => isNotEmpty(point.values) && hasValue(
      Object.values(point.values).find((value) => value > 0))
    )
  );
}

/**
 * Group filters together on their category
 * @param filters
 */
export function groupStatletFilters(filters: StatletFilter[]): Map<string, StatletFilter[]> {
  const groupedFilters = new Map<string, StatletFilter[]>();
  filters.forEach((filter) => {
    if (groupedFilters.has(filter.category)) {
      groupedFilters.get(filter.category).push(filter);
    } else {
      groupedFilters.set(filter.category, [filter]);
    }
  });
  return groupedFilters;
}

/**
 * Split a filter by returning a new filter for each of its values
 * @param filter
 */
export function splitStatletFilterByValues(filter: StatletFilter): StatletFilter[] {
  if (filter.hasValue) {
    return filter.values.map((value) => Object.assign(new StatletFilter(), filter, {
      values: [value],
    }));
  }
  return [];
}

/**
 * Remove a value from a multi-valued filter string
 * @param valueString
 * @param valueToRemove
 */
export function removeStatletFilterValueFromString(valueString: string, valueToRemove: string): string {
  const values = valueString.split(StatletFilter.VALUE_SEPARATOR);
  const newValues = [];
  values.forEach((value) => {
    if (value !== valueToRemove) {
      newValues.push(value);
    }
  });
  return newValues.join(StatletFilter.VALUE_SEPARATOR);
}

<div class="item-page-graphic" [style.background-image]="'url(/assets/images/item-view-page-header-graphic-left-aligned.png)'">
  <div class="item-page-header d-flex flex-column justify-content-end">
    <div class="container">
      <div class="d-flex flex-row">
        <h2 class="item-page-title-field mr-auto">
          <ds-metadata-values class="d-inline-block" [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
        </h2>
        <div class="pl-2 d-flex flex-row">
          <ds-atmire-saved-item-list-control [item]="object"></ds-atmire-saved-item-list-control>
          <ds-dso-page-edit-button class="pl-1" [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'dataset.page.edit'"></ds-dso-page-edit-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="item-page-content-a">
  <div class="container">
    <div class="row">
      <div class="item-page-left col-xs-12 col-md">
        <a *ngIf="hasFiles() | async"
           (click)="onClick('file-section')"
           class="btn btn-lg btn-dark download-button mb-3"
        >
          <img src="assets/images/files-download.svg" alt="">
          {{"item.page.link.download" | translate}}
        </a>
        <ds-item-page-rights-field [item]="object"
                                   [fields]="['dcterms.accessRights', 'unsw.description.embargoNote', 'dcterms.rightsHolder', 'dcterms.rights']"
                                   [label]="'item.page.accessTerms'">
        </ds-item-page-rights-field>
        <ds-item-page-uri-separate-value-field [item]="object"
                                               [fieldURI]="'dc.rights.uri'"
                                               [fieldValue]="'dc.rights'"
                                               [label]="">
        </ds-item-page-uri-separate-value-field>
        <div class="mb-2" [class.d-none]="!showAltmetricDonut">
          <ds-altmetric-donut (altMetricDonutRenderedEmitter)="showAltmetricDonut = $event[0]"
                              [doiIdentifierMdFields]="['unsw.identifier.doi', 'unsw.identifier.doiPublisher']"
                              [smallView]="false"
                              [item]="object">
          </ds-altmetric-donut>
        </div>
        <div class="mb-2">
          <ds-atmire-cua-statlets-show-hide-button (show)="statletsSectionRef.nativeElement.scrollIntoView({ behavior: 'smooth' })">
          </ds-atmire-cua-statlets-show-hide-button>
        </div>
      </div>

      <div class="col-xs-12 col-md">
        <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
        <ds-item-page-uri-field [item]="object"
                                [fields]="['dc.identifier.uri']"
                                [label]="'item.page.permalink'">
        </ds-item-page-uri-field>
        <ds-item-page-uri-field [item]="object" [prefixValue]="'http://dx.doi.org/'"
                                [addPrefixIfDoesNotContains]="['doi.org']"
                                [fields]="['unsw.identifier.doi']"
                                [label]="'item.page.doi'">
        </ds-item-page-uri-field>
        <ds-item-page-uri-field [item]="object" [prefixValue]="'http://dx.doi.org/'"
                                [addPrefixIfDoesNotContains]="['doi.org']"
                                [fields]="['unsw.identifier.doiPublisher']"
                                [label]="'item.page.externalDataRepository'">
        </ds-item-page-uri-field>
        <ds-item-page-uri-field [item]="object"
                                [fields]="['unsw.description.storageURL']"
                                [label]="'item.page.storageUrl'">
        </ds-item-page-uri-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['unsw.description.contact']"
                                    [label]="'item.page.contact'">
        </ds-generic-item-page-field>
      </div>
    </div>
  </div>
</div>
<div class="item-page-content-b">
  <div class="container">
    <div class="row">
      <div class="item-page-left col-xs-12 col-md">
        <ds-metadata-representation-list
                [parentItem]="object"
                [itemType]="'person'"
                [metadataFields]="['unsw.contributor.researchDataCreator']"
                [label]="'item.page.researchDataCreator' | translate">
        </ds-metadata-representation-list>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['unsw.contributor.corporate']"
                                    [label]="'item.page.corporateContributor'">
        </ds-generic-item-page-field>
      </div>
      <div class="col-xs-12 col-md">
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['dc.date.issued']"
                                    [label]="'item.page.publicationDate'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['dc.type']"
                                    [label]="'item.page.type'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['dc.subject.other']"
                                    [label]="'item.page.keyword'">
        </ds-generic-item-page-field>

        <!-- Related Faculties -->
        <ds-related-items
          [parentItem]="object"
          [relationType]="'isAffiliatedOrgUnitOfDataset'"
          [label]="'relation.isAffiliatedOrgUnitOfDataset' | translate">
        </ds-related-items>

      </div>
  </div>
    <div class="row">
      <div class="col-12">
        <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
        <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.license-files" bundle="LICENSE" [item]="object"></ds-item-page-file-section>
        <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.administration-files" bundle="ADMINISTRATION" [item]="object"></ds-item-page-file-section>
      </div>
      <div class="col-12">
        <!-- Related Datasets -->
        <ds-unsw-multi-relationship-related-items-temp
          class="full-width-line"
          [parentItem]="object"
          [relationTypes]="['isDatasetRelatedToDataset','isDatasetRelatingToDataset']"
          [plainField]="'unsw.isDatasetRelatedToDataset'"
          [plainClass]=""
          [label]="'relation.isDatasetRelatedToDataset' | translate">
        </ds-unsw-multi-relationship-related-items-temp>

        <!-- Related Publications -->
        <ds-related-items
          class="full-width-line"
          [parentItem]="object"
          [relationType]="'isPublicationRelatedToDataset'"
          [plainField]="'unsw.isPublicationRelatedToDataset'"
          [plainClass]=""
          [label]="'relation.isPublicationRelatedToDataset' | translate">
        </ds-related-items>

        <!-- Related grants -->
        <ds-combined-metadata-authority-list
          class="full-width-line"
          [item]="object"
          [fields]="['unsw.relation.projectTitle', 'unsw.relation.fundingAgency', 'unsw.relation.fundingScheme', 'unsw.relation.FunderRefNo']"
          [separator]="' - '"
          [label]="'item.page.grants'">
        </ds-combined-metadata-authority-list>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center flex-nowrap flex-grow-0 w-100 mt-3 mb-5">
      <a class="btn btn-lg btn-dark mx-3" role="button" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
      <a [routerLink]="[itemPageRoute + '/statistics']" class="btn btn-lg btn-outline-light mx-3 statistics-button-item-page"
         role="button">
        {{"item.page.link.statistics" | translate}}
      </a>
    </div>
  </div>
    <div class="row mt-4" #statletsSection>
      <div class="col-12">
        <ds-atmire-cua-statlets-section [dso]="object" [displayShowHideButton]="false"></ds-atmire-cua-statlets-section>
      </div>
    </div>
</div>

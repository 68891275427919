import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FormFieldMetadataValueObject } from '../../../models/form-field-metadata-value.model';
import { Subscription } from 'rxjs';
import { AtmireSearchEditValueAction } from '../../atmire-search-modal/atmire-search-edit-value-action.model';
import { hasValue } from '../../../../../empty.util';
import { DsDynamicAtmireSearchComponent } from './dynamic-atmire-search.component';
import { AtmireSearchAddValueAction } from '../../atmire-search-modal/atmire-search-add-value-action.model';

export class AtmireSearchListener {
  select$: BehaviorSubject<AtmireSearchAddValueAction> = new BehaviorSubject<AtmireSearchAddValueAction>(null);
  edit$: BehaviorSubject<AtmireSearchEditValueAction> = new BehaviorSubject<AtmireSearchEditValueAction>(null);
  deselect$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  modalInstance: any;
  components: DsDynamicAtmireSearchComponent[] = [];
  subs: Subscription[] = [];

  reset() {
    this.subs
      .filter((sub) => hasValue(sub))
      .forEach((sub) => sub.unsubscribe());
    this.subs = [];
    this.select$.next(null);
    this.edit$.next(null);
    this.deselect$.next(null);
    this.modalInstance = null;

    // Create new subscriber if there's a component left
    const comp = this.components.find((c) => hasValue(c));
    if (hasValue(comp)) {
      comp.subscribeToListener();
    }
  }

  removeComponent(index: number) {
    this.components[index] = null;
  }
}

<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'orgunit.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title','organization.legalName'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'orgunit.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-thumbnail [thumbnail]="object?.thumbnail | async"
                    [defaultImage]="'assets/images/orgunit-placeholder.svg'"
                    [alt]="'thumbnail.orgunit.alt'"
                    [placeholder]="'thumbnail.orgunit.placeholder'"
      >
      </ds-thumbnail>
    </ds-metadata-field-wrapper>
    <div>
      <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.license-files" bundle="LICENSE" [item]="object"></ds-item-page-file-section>
      <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.administration-files" bundle="ADMINISTRATION" [item]="object"></ds-item-page-file-section>

      <a class="btn btn-outline-primary" [routerLink]="['/items/' + object.id + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.orgnunit.namevariant']"
                                [label]="'orgunit.page.namevariant'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.description']"
                                [label]="'orgunit.page.description'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.description.uri']"
                            [label]="'orgunit.page.website'">
    </ds-item-page-uri-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.type']"
                                [label]="'orgunit.page.type'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.date.issued']"
                                [label]="'orgunit.page.datefoundation'">
    </ds-generic-item-page-field>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitReplacedByOrgUnit'"
      [label]="'relationships.isOrgUnitReplacedByOrgUnit' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitReplacingOrgUnit'"
      [label]="'relationships.isOrgUnitReplacingOrgUnit' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isMergedOrgUnitOfOrgUnits'"
      [label]="'relationships.isMergedOrgUnitOfOrgUnits' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitMergedIntoOrgUnit'"
      [label]="'relationships.isOrgUnitMergedIntoOrgUnit' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitSplitOfOrgUnit'"
      [label]="'relationships.isOrgUnitSplitOfOrgUnit' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOriginalOrgUnitOfSplitOrgUnit'"
      [label]="'relationships.isOriginalOrgUnitOfSplitOrgUnit' | translate">
    </ds-related-items>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'orgunit.page.description'">
    </ds-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
<!-- Example of how to show same relationship but via virtual metadata (can include plain-text values): -->
<!--    <ds-metadata-representation-list-->
<!--      [parentItem]="object"-->
<!--      [itemType]="'OrgUnit'"-->
<!--      [metadataField]="'unsw.orgunit.splits'"-->
<!--      [label]="'relationships.isOriginalOrgUnitOfSplitOrgUnit' | translate">-->
<!--    </ds-metadata-representation-list>-->
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search [item]="object"
                                       [relationTypes]="[{
                                          label: 'search.isAffiliatedOrgUnitOfPublication',
                                          filter: 'isAffiliatedOrgUnitOfPublication',
                                          configuration: 'publication'
                                        },
                                        {
                                          label: 'search.isAffiliatedOrgUnitOfDataset',
                                          filter: 'isAffiliatedOrgUnitOfDataset',
                                          configuration: 'dataset'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>

import { Directive, HostListener } from '@angular/core';

/**
 * Directive for an href that should open in a new tab, and is not necessarily part of site (not prepended with context url)
 */
@Directive({
  selector: '[dsHrefExternalLink]'
})
export class HrefExternalLinkDirective {
  @HostListener('click', ['$event'])
  onClick(event) {
    event.preventDefault();
    let link: string = (event.currentTarget as any).getAttribute('href');
    if (link && link.indexOf('http') === -1) {
      link = `//${link}`;
    }
    window.open(link, '_blank');
  }
}

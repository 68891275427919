import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef, NgZone
} from '@angular/core';
import { CardModel, ViewDimensions } from '@swimlane/ngx-charts';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'g[ds-ngx-custom-charts-card-series]',
  templateUrl: './ngx-custom-card-series-inline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomCardSeriesComponent implements OnChanges {
  @Input() data: any[];
  @Input() slots: any[];
  @Input() dims: ViewDimensions;
  @Input() colors;
  @Input() innerPadding = 0;

  @Input() cardColor;
  @Input() bandColor;
  @Input() emptyColor = 'rgba(0, 0, 0, 0)';
  @Input() textColor;
  @Input() valueFormatting: any;
  @Input() labelFormatting: any;
  @Input() animations = true;

  @Output() select = new EventEmitter();

  @ViewChild('textEl', { static: false }) textEl: ElementRef;

  cards: CardModel[];
  emptySlots: any[];
  medianSize: number;

  cardX = 0;
  cardY = 0;

  constructor(private cd: ChangeDetectorRef,
              private zone: NgZone) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update(): void {
    if (this.data.length > 2) {
      const valueFormatting = this.valueFormatting || (card => card.value.toLocaleString());

      const sortedLengths = this.data
        .map(d => {
          const hasValue = d && d.data && typeof d.data.value !== 'undefined' && d.data.value !== null;
          return hasValue
            ? valueFormatting({
              data: d.data,
              label: d ? d.data.name : '',
              value: d && d.data ? d.data.value : ''
            }).length
            : 0;
        })
        .sort((a, b) => b - a);
      const idx = Math.ceil(this.data.length / 2);
      this.medianSize = sortedLengths[idx];
    }

    const cards = this.getCards();
    this.cards = cards.filter(d => d.data.value !== null);
    this.emptySlots = cards.filter(d => d.data.value === null);

    setTimeout(() => {
      this.scaleText();
    }, 8);
  }

  getCards(): any[] {
    const yPadding =
      typeof this.innerPadding === 'number' ? this.innerPadding : this.innerPadding[0] + this.innerPadding[2];
    const xPadding =
      typeof this.innerPadding === 'number' ? this.innerPadding : this.innerPadding[1] + this.innerPadding[3];

    return this.data.map((d, index) => {
      let label = d.data.name;
      if (label && label.constructor.name === 'Date') {
        label = label.toLocaleDateString();
      } else {
        label = label ? label.toLocaleString() : label;
      }

      const value = d.data.value;
      const valueColor = label ? this.colors.getColor(label) : this.emptyColor;
      const color = this.cardColor || valueColor || '#000';
      return {
        x: d.x,
        y: d.y,
        width: d.width - xPadding,
        height: d.height - yPadding,
        color: this.emptyColor,
        bandColor:this.emptyColor,
        textColor: this.textColor || color,
        label,
        data: d.data,
        tooltipText: `${label}: ${value}`
      };
    });
  }

  scaleText(): void {
    this.zone.run(() => {
      const { width, height } = this.textEl.nativeElement.getBoundingClientRect();
      if (width === 0 || height === 0) {
        return;
      }

      this.cardX = (this.dims.width / 2) - (width / 2);
      this.cardY = (this.dims.height / 2) - (height / 2);
      this.cd.markForCheck();
    });
  }

  trackBy(index, card): string {
    return card.label;
  }

  onClick(data): void {
    this.select.emit(data);
  }
}

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NgbDropdown, NgbDropdownItem, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFormLayoutService, DynamicFormValidationService } from '@ng-dynamic-forms/core';
import { VocabularyService } from '../../../../../../core/submission/vocabularies/vocabulary.service';
import { hasNoValue, hasValue, hasValueOperator, isEmpty, isNotEmpty } from '../../../../../empty.util';
import { PageInfo } from '../../../../../../core/shared/page-info.model';
import { FormFieldMetadataValueObject } from '../../../models/form-field-metadata-value.model';
import { VocabularyEntry } from '../../../../../../core/submission/vocabularies/models/vocabulary-entry.model';
import { DynamicAtmireSearchNameModel } from './dynamic-atmire-search-name.model';
import {
  PaginatedList,
} from '../../../../../../core/data/paginated-list.model';
import { DsDynamicVocabularyComponent } from '../dynamic-vocabulary.component';
import { DynamicAtmireSearchModalComponent } from '../../atmire-search-modal/dynamic-atmire-search-modal.component';
import { FormBuilderService } from '../../../form-builder.service';
import { AtmireSearchListener } from './atmire-search-listener.model';
import { AtmireSearchEditValueAction } from '../../atmire-search-modal/atmire-search-edit-value-action.model';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../../../../../../core/data/remote-data';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { PaginatedSearchOptions } from '../../../../../search/paginated-search-options.model';
import { PaginationComponentOptions } from '../../../../../pagination/pagination-component-options.model';
import { getFirstSucceededRemoteDataPayload } from '../../../../../../core/shared/operators';
import { ExternalSourceService } from '../../../../../../core/data/external-source.service';
import { DOWN_ARROW, ENTER, SPACE } from '@angular/cdk/keycodes';
import { AtmireSearchAddValueAction } from '../../atmire-search-modal/atmire-search-add-value-action.model';

const SEARCH_LISTENERS: Map<string, AtmireSearchListener> = new Map();

/**
 * Component representing an atmire-search or atmire-search-name input field
 */
@Component({
  selector: 'ds-dynamic-atmire-search',
  styleUrls: ['./dynamic-atmire-search.component.scss'],
  templateUrl: './dynamic-atmire-search.component.html'
})
export class DsDynamicAtmireSearchComponent extends DsDynamicVocabularyComponent implements OnDestroy, OnInit {

  @Input() group: FormGroup;
  @Input() model: any;

  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(NgbDropdownItem) dropdownItems: QueryList<ElementRef>;

  public firstInputValue = '';
  public secondInputValue = '';
  public loading = false;
  public pageInfo: PageInfo;
  public optionsList: any;

  searchListener: AtmireSearchListener;
  searchListenerSubscriber = false;
  searchListenerComponentIndex: number;

  query$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  localEntries$: Observable<RemoteData<PaginatedList<VocabularyEntry>>>;
  externalSourceCount$: {
    [externalSource: string]: Observable<number>
  } = {};

  localEntriesAmount = 3;
  preventSearchForNext: string;

  protected subs: Subscription[] = [];

  constructor(protected vocabularyService: VocabularyService,
              protected externalSourceService: ExternalSourceService,
              private cdr: ChangeDetectorRef,
              protected layoutService: DynamicFormLayoutService,
              protected validationService: DynamicFormValidationService,
              protected modalService: NgbModal,
              protected formBuilderService: FormBuilderService,
  ) {
    super(vocabularyService, layoutService, validationService);
  }

  /**
   * Converts an item from the result list to a `string` to display in the `<input>` field.
   */
  inputFormatter = (x: { display: string }, y: number) => {
    return y === 1 ? this.firstInputValue : this.secondInputValue;
  }

  /**
   * Initialize the component, setting up the init form value
   */
  ngOnInit() {
    if (isNotEmpty(this.model.value)) {
      this.change.emit(this.model.value);
      this.setCurrentValue(this.model.value, true);
    }

    this.subs.push(
      this.model.valueChanges.subscribe((value) => {
        if (isEmpty(value)) {
          this.resetFields();
        } else {
          this.setCurrentValue(this.model.value);
        }
      }),
    );

    if (!SEARCH_LISTENERS.has(this.model.id)) {
      SEARCH_LISTENERS.set(this.model.id, new AtmireSearchListener());
    }
    this.searchListener = SEARCH_LISTENERS.get(this.model.id);
    this.subscribeToListener();
    this.searchListener.components.push(this);
    this.searchListenerComponentIndex = this.searchListener.components.length - 1;

    this.localEntries$ = this.query$.pipe(
      switchMap((query) => {
        if (isNotEmpty(query)) {
          return this.vocabularyService.getVocabularyEntriesByValue(query, false, this.model.vocabularyOptions, Object.assign({
            elementsPerPage: this.localEntriesAmount,
            currentPage: 0,
          }));
        } else {
          return [null];
        }
      })
    );

    this.model.externalSources.forEach((externalSource) => {
      this.externalSourceCount$[externalSource] = this.query$.pipe(
        switchMap((query) => this.externalSourceService.getExternalSourceEntries(externalSource, new PaginatedSearchOptions({
          query,
          pagination: Object.assign(new PaginationComponentOptions(), {
            pageSize: 1,
          }),
        })).pipe(
          getFirstSucceededRemoteDataPayload(),
          map((entries) => entries.totalElements)
        )),
        startWith(0),
      );
    });
  }

  subscribeToListener() {
    if (isEmpty(this.searchListener.subs)) {
      this.searchListener.subs.push(
        this.searchListener.select$.pipe(hasValueOperator()).subscribe((action: AtmireSearchAddValueAction) => {
          if (this.model.repeatable && !this.isArraySingularNonAuthority()) {
            this.addValueToArray(action.value, action.index);
          } else {
            this.updateModel(action.value);
          }
        }),
        this.searchListener.edit$.pipe(hasValueOperator()).subscribe((action: AtmireSearchEditValueAction) => {
          if (this.model.repeatable) {
            this.editValue(action);
          } else {
            this.updateModel(action.value);
          }
        }),
        this.searchListener.deselect$.pipe(hasValueOperator()).subscribe((index) => {
          if (this.model.repeatable && !this.isArraySingular()) {
            this.removeValueAtIndex(index);
          } else {
            this.updateModel(new FormFieldMetadataValueObject());
          }
        }),
      );
      this.searchListenerSubscriber = true;
    }
  }

  /**
   * A method to check if the current array of values only contains one value without an authority
   * This is used to determine whether or not we should overwrite this value or add to the list
   * @private
   */
  private isArraySingularNonAuthority(): boolean {
    const formArrayControl = this.group.parent as FormArray;
    return this.isArraySingular() && (hasNoValue(formArrayControl.value[0][this.model.id]) || isEmpty(formArrayControl.value[0][this.model.id].authority));
  }

  private isArraySingular(): boolean {
    const formArrayControl = this.group.parent as FormArray;
    return formArrayControl.value.length === 1;
  }

  private addValueToArray(value: FormFieldMetadataValueObject, index?: number) {
    if (hasValue(this.model.parent) && hasValue(this.model.parent.parent)) {
      const arrayContext = this.model.parent.parent;
      const formArrayControl = this.group.parent as FormArray;
      if (isNotEmpty(formArrayControl.value)) {
        formArrayControl.value.forEach((v, i) => {
          if (hasNoValue(index) && (hasNoValue(v[this.model.id]) || hasNoValue(v[this.model.id].value))) {
            index = i;
          }
        });
      }
      if (hasNoValue(index)) {
        index = arrayContext.groups.length;
        this.formBuilderService.insertFormArrayGroup(index, formArrayControl, arrayContext);
      }
      const copyValues = [...formArrayControl.value];
      copyValues[index][this.model.id] = value;
      this.fixArrayControlValues(copyValues);
      formArrayControl.setValue(copyValues);
      this.updateModel(this.model.value);
      if (hasValue(this.searchListener.modalInstance)) {
        this.searchListener.modalInstance.updateSelectedValueSelect(value, index);
      }
    }
  }

  private fixArrayControlValues(values): void {
    values.forEach((value) => {
      if (hasNoValue(value[this.model.id])) {
        value[this.model.id] = new FormFieldMetadataValueObject();
      }
    });
  }

  private removeValueAtIndex(index: number) {
    if (hasValue(this.model.parent) && hasValue(this.model.parent.parent)) {
      const arrayContext = this.model.parent.parent;
      const formArrayControl = this.group.parent as FormArray;
      let oneValue = false;
      if (arrayContext.groups.length <= 1) {
        oneValue = true;
      }
      this.formBuilderService.removeFormArrayGroup(index, formArrayControl, arrayContext);
      this.updateModel(oneValue ? new FormFieldMetadataValueObject() : this.model.value);
    }

    // We possibly removed the component actively subscribed to our listener
    this.subscribeToListener();
  }

  private editValue(action: AtmireSearchEditValueAction) {
    this.removeValueAtIndex(action.index);
    this.addValueToArray(action.value, action.index);
  }

  addLocalEntry(localEntry: VocabularyEntry, dropdown: NgbDropdown) {
    this.preventSearchForNext = localEntry.display;
    this.updateModel(new FormFieldMetadataValueObject(localEntry.display, null, localEntry.authority, localEntry.display));
    dropdown.close();
  }

  /**
   * Check if model value has an authority
   */
  public hasAuthorityValue() {
    return hasValue(this.model.value)
      && typeof this.model.value === 'object'
      && this.model.value.hasAuthority();
  }

  /**
   * Check if current value has an authority
   */
  public hasEmptyValue() {
    return isNotEmpty(this.getCurrentValue());
  }

  /**
   * Clear inputs whether there is no results and authority is closed
   */
  public clearFields() {
    if (this.model.vocabularyOptions.closed) {
      this.resetFields();
    }
  }

  /**
   * Check if model is instanceof DynamicLookupNameModel
   */
  public isLookupName() {
    return (this.model instanceof DynamicAtmireSearchNameModel);
  }

  /**
   * Update model value with the typed text if vocabulary is not closed
   * @param event the typed text
   */
  public onChange(event) {
    event.preventDefault();
    if (!this.model.vocabularyOptions.closed) {
      if (isNotEmpty(this.getCurrentValue())) {
        const currentValue = new FormFieldMetadataValueObject(this.getCurrentValue());
        if (hasValue(this.model.value) && isNotEmpty(this.model.value.authority)) {
          currentValue.authority = this.model.value.authority;
          currentValue.confidence = this.model.value.confidence;
        }
        this.updateModel(currentValue);
      } else {
        this.remove();
      }
    }
  }

  /**
   * Update model value with selected entry
   * @param event the selected entry
   */
  public onSelect(event) {
    this.updateModel(event);
  }

  /**
   * Reset the current value when dropdown toggle
   */
  public openChange(isOpened: boolean) {
    if (!isOpened) {
      if (this.model.vocabularyOptions.closed && !this.hasAuthorityValue()) {
        this.setCurrentValue('');
      }
    }
  }

  /**
   * Reset the model value
   */
  public remove() {
    this.group.markAsPristine();
    this.dispatchUpdate(null);
  }

  /**
   * Saves all changes
   */
  public saveChanges() {
    if (isNotEmpty(this.getCurrentValue())) {
      const newValue = Object.assign(new VocabularyEntry(), this.model.value, {
        display: this.getCurrentValue(),
        value: this.getCurrentValue()
      });
      this.updateModel(newValue);
    } else {
      this.remove();
    }
  }

  /**
   * Converts a stream of text values from the `<input>` element to the stream of the array of items
   * to display in the result list.
   */
  public search(dropdown: NgbDropdown) {
    const query = this.getCurrentQuery();
    if (isNotEmpty(query) && this.preventSearchForNext !== query) {
      this.query$.next(query);
      dropdown.open();
    } else {
      this.query$.next('');
      dropdown.close();
    }
  }

  lookup(tab?: string) {
    const modalRef = this.modalService.open(DynamicAtmireSearchModalComponent, {
      size: 'lg',
    });

    this.searchListener.modalInstance = modalRef.componentInstance;
    this.searchListener.modalInstance.query = this.getCurrentQuery();
    this.searchListener.modalInstance.model = this.model;
    this.searchListener.modalInstance.clickedValue = this.model.value;
    if (hasValue(tab)) {
      this.searchListener.modalInstance.initialTab = tab;
    }
    this.searchListener.modalInstance.searchListener = this.searchListener;
  }

  keydownInput(keyEvent) {
    if (keyEvent.keyCode === DOWN_ARROW && hasValue(this.dropdownItems.first)) {
      (this.dropdownItems.first as any).elementRef.nativeElement.focus();
    }
  }

  keydownDropdownItem(keyEvent) {
    if (keyEvent.keyCode === SPACE || keyEvent.keyCode === ENTER) {
      keyEvent.preventDefault();
      keyEvent.target.click();
    }
  }

  ngOnDestroy() {
    this.subs
      .filter((sub) => hasValue(sub))
      .forEach((sub) => sub.unsubscribe());

    this.searchListener.removeComponent(this.searchListenerComponentIndex);
    if (this.searchListenerSubscriber) {
      this.searchListener.reset();
    }
  }

  /**
   * Sets the current value with the given value.
   * @param value The value to set.
   * @param init Representing if is init value or not.
   */
  public setCurrentValue(value: any, init = false) {
    if (hasValue(value)) {
      if (value instanceof FormFieldMetadataValueObject || value instanceof VocabularyEntry) {
        this.setDisplayInputValue(value.display);
      }
    }
  }

  protected setDisplayInputValue(displayValue: string) {
    if (hasValue(displayValue)) {
      if (this.isLookupName()) {
        const values = displayValue.split((this.model as DynamicAtmireSearchNameModel).separator);

        this.firstInputValue = (values[0] || '').trim();
        this.secondInputValue = (values[1] || '').trim();
      } else {
        this.firstInputValue = displayValue || '';
      }
      this.cdr.detectChanges();
    }
  }

  /**
   * Gets the current text present in the input field(s)
   */
  protected getCurrentValue(): string {
    let result;
    if (!this.isLookupName()) {
      result = this.firstInputValue;
    } else {
      result = (isNotEmpty(this.firstInputValue) ? this.firstInputValue : '') +
        (isNotEmpty(this.secondInputValue) ? ((this.model as DynamicAtmireSearchNameModel).separator + ' ' + this.secondInputValue) : '');
    }
    return result;
  }

  protected getCurrentQuery(): string {
    let query = '';
    if (isNotEmpty(this.firstInputValue)) {
      query = this.firstInputValue;
    }
    if (isNotEmpty(query) && isNotEmpty(this.secondInputValue)) {
      query += (this.model as DynamicAtmireSearchNameModel).separator + ' ';
    }
    if (isNotEmpty(this.secondInputValue)) {
      query += this.secondInputValue;
    }
    return query;
  }

  /**
   * Clear text present in the input field(s)
   */
  protected resetFields() {
    this.firstInputValue = '';
    if (this.isLookupName()) {
      this.secondInputValue = '';
    }
  }

  protected updateModel(value) {
    this.group.markAsDirty();
    this.dispatchUpdate(value);
    this.setCurrentValue(value);
    this.optionsList = null;
    this.pageInfo = null;
    if (hasValue(this.searchListener.modalInstance)) {
      this.searchListener.modalInstance.updateModel(this.model);
    }
  }

}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from './auth.service';
import { HardRedirectService } from '../services/hard-redirect.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ShibbolethGuard implements CanActivate {

  sub: Subscription;

  constructor(private authService: AuthService,
              @Inject(PLATFORM_ID) private platformId: any,
              private hardRedirectService: HardRedirectService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getShibbolethUrl().pipe(
      tap((url: string) => {
        // only redirect to shibboleth on the client, because the server doesn't have access to the
        // shib cookie and can therefore never know if you're authenticated or not
        if (isPlatformBrowser(this.platformId)) {
          this.hardRedirectService.redirect(url);
        }
      }),
      map(() => true)
    );
  }
}

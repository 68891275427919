import { Statlet } from '../../data/models/statlet.model';
import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { hasValue, isNotEmpty } from '../../../../../shared/empty.util';
import { Observable, of as observableOf, zip as observableZip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StatletDataTypeService } from '../data-type-services/statlet-data-type.service';
import {
  STATLET_DATA_TYPE_SERVICE_FACTORY
} from '../data-type-services/statlet-data-type.decorator';
import { AtmireCuaColorService } from '../../../../shared/atmire-cua-color.service';
import { GenericConstructor } from '../../../../../core/shared/generic-constructor';
import { StatletHeader } from '../../data/models/statlet-header.model';
import { HostWindowService, WidthCategory } from '../../../../../shared/host-window.service';
import { AlternativeLabelService } from '../../../../shared/alternative-label.service';

@Component({
  selector: 'ds-atmire-cua-abstract-single-statlet',
  template: ''
})
/**
 * The abstract component for every type of statistic to display for a single statlet
 */
export class DynamicSingleStatletComponent implements OnInit {
  @Input() statlet: Statlet;
  @Input() renderLinks = true;
  @Input() view: number[];
  @Input() size: string;
  @Input() palette: string[];

  data$: Observable<any>;
  colors$: Observable<any>;

  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
  }

  ngOnInit(): void {
    this.loadData();
    this.loadColors();
  }

  /**
   * Define how the data is loaded
   * This class defines a standard way of displaying data, allowing for multiple segments per point
   * If the implementation requires a different format for the data, override this method in the parent component
   */
  loadData(): void {
    if (this.isInverseData()) {
      this.loadInverseData();
    } else {
      this.loadRegularData();
    }
  }

  /**
   * Load data regularly
   * Each point resembles a row
   * Each value of the point resembles a column
   */
  loadRegularData() {
    this.data$ = this.windowService.widthCategory.pipe(
      switchMap((width) => observableZip(...this.statlet.points.map((point) => {
        return observableZip(...hasValue(point.values) ? Object.entries(point.values).map(([id, value]) => {
          const header = this.getHeaderById(this.statlet.columnHeaders, id);
          const dataService = this.getStatletDataTypeServiceForHeader(header);
          return this.getHeaderName(header, dataService, id, width).pipe(
            map((name) => Object.assign({name, value}))
          );
        }) : []).pipe(
          switchMap((series) => {
            const header = this.getHeaderById(this.statlet.rowHeaders, point.id);
            const dataService = this.getStatletDataTypeServiceForHeader(header);
            const data = {series} as any;
            if (this.renderLinks && hasValue(dataService)) {
              data.link = dataService.getLinkByPoint(point);
            }
            return this.getHeaderName(header, dataService, point.label, width).pipe(
              map((name) => Object.assign({}, data, {name}))
            );
          })
        );
      })))
    );
  }

  /**
   * Load data inverse (opposite of regularly)
   * Each point resembles a column
   * Each value of the point resembles a row
   */
  loadInverseData() {
    const segments = [];
    this.statlet.points.forEach((point) => {
      (hasValue(point.values) ? Object.keys(point.values) : []).forEach((label) => {
        if (segments.indexOf(label) < 0) {
          segments.push(label);
        }
      });
    });

    this.data$ = this.windowService.widthCategory.pipe(
        switchMap((width) => observableZip(...segments.map((label) => {
        return observableZip(...this.statlet.points.map((point) => {
          const header = this.getHeaderById(this.statlet.rowHeaders, point.id);
          const dataService = this.getStatletDataTypeServiceForHeader(header);
          return this.getHeaderName(header, dataService, point.label, width).pipe(
            map((name) => Object.assign({ name, value: point.values[label] }))
          );
        })).pipe(
          switchMap((series) => {
            const header = this.getHeaderById(this.statlet.columnHeaders, label);
            const dataService = this.getStatletDataTypeServiceForHeader(header);
            const data = { series } as any;
            if (this.renderLinks && hasValue(dataService)) {
              data.link = dataService.getLinkByValue(label);
            }
            return this.getHeaderName(header, dataService, label, width).pipe(
              map((name) => Object.assign({}, data, { name }))
            );
          })
        );
      })))
    );
  }

  /**
   * Get the name for a header using its {@link StatletDataTypeService}
   * @param header      Header to fetch data from
   * @param dataService {@link StatletDataTypeService} to use for transforming its name
   * @param def         Default value to return if no header name is found
   * @param width       The current window's width
   */
  getHeaderName(header: StatletHeader, dataService: StatletDataTypeService, def: string, width?: WidthCategory): Observable<string> {
    if (hasValue(width) && isNotEmpty(header.alternativeLabels) && hasValue(header.alternativeLabels[width])) {
      return this.alternativeLabelService.transform(header.alternativeLabels[width]);
    }

    if (hasValue(dataService)) {
      let value;
      if (hasValue(header.headerName)) {
        value = header.headerName;
      } else {
        value = header.headerId;
      }

      return dataService.transform(value);
    }
    return observableOf(def);
  }

  /**
   * Get a header out of an array by its ID
   * @param headers
   * @param id
   */
  getHeaderById(headers: StatletHeader[], id: string): StatletHeader {
    if (isNotEmpty(headers)) {
      const header = headers.find((h) => h.headerId === id);
      if (hasValue(header)) {
        return header;
      }
    }
    console.warn(`No header was found for ID \"${id}\", headers searched: `, headers);
    return null;
  }

  /**
   * Get the {@link StatletDataTypeService} for a {@link StatletHeader}
   * @param header
   */
  getStatletDataTypeServiceForHeader(header: StatletHeader): StatletDataTypeService {
    if (hasValue(header)) {
      const serviceProvider = this.getStatletDataTypeServiceFor(header.headerCategory, header.headerType, this.statlet.shortName);
      if (hasValue(serviceProvider)) {
        return Injector.create({
          providers: [],
          parent: this.parentInjector
        }).get(serviceProvider) as StatletDataTypeService;
      }
    }
    return null;
  }

  /**
   * Should the data be reversed?
   * Check the Statlet's graphtype property
   */
  isInverseData(): boolean {
    return this.statlet.graphtype.inverseData;
  }

  /**
   * Load the color set for the Statlet's data
   */
  loadColors() {
    this.colors$ = this.data$.pipe(
      map((data) => {
        let length = 1;
        if (data.length > 0) {
          length = this.isInverseData() ? data.length : data[0].series.length;
        }
        return Object.assign({ domain: this.colorService.getColorSet(length, this.statlet.style.palette ? this.statlet.style.palette : this.palette) });
      })
    );
  }
}

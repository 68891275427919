import { autoserialize, autoserializeAs, deserialize } from 'cerialize';
import { ListableObject } from '../../shared/object-collection/shared/listable-object.model';
import {
  MetadataRepresentation,
  MetadataRepresentationType
} from '../shared/metadata-representation/metadata-representation.model';
import { typedObject } from '../cache/builders/build-decorators';
import { HALLink } from '../shared/hal-link.model';
import { excludeFromEquals } from '../utilities/equals.decorators';
import { OtherInformation } from '../../shared/form/builder/models/form-field-metadata-value.model';
import { isNotEmpty } from '../../shared/empty.util';
import { GenericConstructor } from '../shared/generic-constructor';
import { ATMIRE_AUTHORITY_DETAIL } from './atmire-authority-detail.resource-type';
import { MetadataMap, MetadataMapSerializer } from '../shared/metadata.models';


/**
 * Model class for a Atmire Authority Detail
 */
@typedObject
export class AtmireAuthorityDetailModel extends ListableObject implements MetadataRepresentation {

  static type = ATMIRE_AUTHORITY_DETAIL;

  @autoserializeAs(String, 'pairtype')
  itemType: string;

  /**
   * The identifier of this authority detail
   */
  @autoserialize
  id: string;

  /**
   * The display value of this authority detail
   */
  @autoserialize
  display: string;

  /**
   * The value of this authority detail
   */
  @autoserialize
  value: string;

  /**
   * The language of this authority detail
   */
  @autoserialize
  language: string;


  /**
   * An object containing additional information related to this authority detail
   */
  @autoserialize
  otherInformation: OtherInformation;

  /**
   * All metadata of this authority detail
   */
  @excludeFromEquals
  @autoserializeAs(MetadataMapSerializer)
  metadata: MetadataMap;

  /**
   * A string representing the kind of authority deta
   */
  @excludeFromEquals
  @autoserialize
  public type: any;

  /**
   * The {@link HALLink}s for this ExternalSourceEntry
   */
  @deserialize
  _links: {
    self: HALLink;
  };

  /**
   * Gets the represenation type of this authority detail
   */
  get representationType(): MetadataRepresentationType {
    return MetadataRepresentationType.AuthorityControlled;
  }

  /**
   * Gets the value of this authority detail
   */
  getValue(): string {
    return this.display;
  }

  /**
   * This method checks if authority detail has a value
   *
   * @return boolean
   */
  hasValue(): boolean {
    return isNotEmpty(this.value);
  }

  /**
   * This method checks if authority detail has related information object
   *
   * @return boolean
   */
  hasOtherInformation(): boolean {
    return isNotEmpty(this.otherInformation);
  }

  /**
   * Method that returns as which type of object this object should be rendered
   */
  getRenderTypes(): (string | GenericConstructor<ListableObject>)[] {
    return [this.constructor as GenericConstructor<ListableObject>];
  }

}

import { VocabularyEntry } from '../../../../../../../core/submission/vocabularies/models/vocabulary-entry.model';
import { ListableObject } from '../../../../../../object-collection/shared/listable-object.model';
import { GenericConstructor } from '../../../../../../../core/shared/generic-constructor';
import { FormFieldMetadataValueObject } from '../../../../models/form-field-metadata-value.model';
import { hasValue } from '../../../../../../empty.util';
import { AtmireSearchSelectableListElement } from './atmire-search-selectable-list-element';

export class VocabularyEntryListElement extends AtmireSearchSelectableListElement<VocabularyEntry> {
  createValueObject(): FormFieldMetadataValueObject {
    return new FormFieldMetadataValueObject(this.selectedValue, null, this.object.authority, this.selectedValue);
  }

  equalsValueObject(object: FormFieldMetadataValueObject): boolean {
    return hasValue(object) && object.authority === this.object.authority;
  }

  getRenderTypes(): (string | GenericConstructor<ListableObject>)[] {
    return [this.constructor as GenericConstructor<ListableObject>];
  }
}

/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { MenuResolver } from './menu.resolver';
import { MenuService } from './shared/menu/menu.service';
import { BrowseService } from './core/browse/browse.service';
import { AuthorizationDataService } from './core/data/feature-authorization/authorization-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ScriptDataService } from './core/data/processes/script-data.service';
import { Injectable } from '@angular/core';
import { MenuID, MenuItemType } from './shared/menu/initial-menus-state';
import { TextMenuItemModel } from './shared/menu/menu-item/models/text.model';
import { LinkMenuItemModel } from './shared/menu/menu-item/models/link.model';
import { StatisticsMenuService } from './shared/menu/statistics-menu/statistics-menu.service';

/**
 * Menu entries specific to app-atmire are defined here.
 */
@Injectable()
export class AtmireMenuResolver extends MenuResolver {
  // w2p-88791 Temp disable 'CUA'
  renderStatsMenu = true;

  constructor(
    protected menuService: MenuService,
    protected browseService: BrowseService,
    protected authorizationService: AuthorizationDataService,
    protected modalService: NgbModal,
    protected scriptDataService: ScriptDataService,
    protected statisticsMenuService: StatisticsMenuService,
  ) {
    super(menuService, browseService, authorizationService, modalService, scriptDataService);
  }

  createPublicMenu$(): Observable<boolean> {
    const done$ = super.createPublicMenu$();

    const menuList: any[] = [
      {
        id: 'my_lists',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: 'menu.section.my_lists',
          link: `/my-lists`
        } as LinkMenuItemModel,
        index: 1
      },
    ];

    menuList.forEach((menuSection) => this.menuService.addSection(MenuID.PUBLIC, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));

    this.statisticsMenuService.addMenuOptionsForType('Site', true);

    return done$;
  }

  createMainMenuSections(): void {
    super.createMainMenuSections();

    const menuList = [
      /* CUA Statistics */
      {
        id: 'atmire-cua',
        active: false,
        visible: this.renderStatsMenu,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.atmire-cua'
        } as TextMenuItemModel,
        icon: 'chart-bar',
        index: 12
      },
      {
        id: 'atmire-cua_storage',
        parentID: 'atmire-cua',
        active: false,
        visible: this.renderStatsMenu,
        model: {
          type: MenuItemType.LINK,
          text: 'menu.section.atmire-cua_storage',
          link: 'atmire-cua/storage'
        } as LinkMenuItemModel
      },
      {
        id: 'atmire-cua_search',
        parentID: 'atmire-cua',
        active: false,
        visible: this.renderStatsMenu,
        model: {
          type: MenuItemType.LINK,
          text: 'menu.section.atmire-cua_search',
          link: 'atmire-cua/search'
        } as LinkMenuItemModel
      },
      {
        id: 'atmire-cua_usage',
        parentID: 'atmire-cua',
        active: false,
        visible: this.renderStatsMenu,
        model: {
          type: MenuItemType.LINK,
          text: 'menu.section.atmire-cua_usage',
          link: 'atmire-cua/usage'
        } as LinkMenuItemModel
      },
    ];
    menuList.forEach((menuSection) => this.menuService.addSection(MenuID.ADMIN, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));
  }

  createExportMenuSections(): void {
    super.createExportMenuSections();
  }

  createImportMenuSections(): void {
    super.createImportMenuSections();
  }

  createSiteAdministratorMenuSections(): void {
    super.createSiteAdministratorMenuSections();
  }

  createAccessControlMenuSections(): void {
    super.createAccessControlMenuSections();
  }
}

import { Injectable } from '@angular/core';
import { MetadataValue } from '../../../../app/core/shared/metadata.models';
import { combineLatest as observableCombineLatest, Observable, of as observableOf, zip as observableZip } from 'rxjs';
import { MetadataRepresentation } from '../../../../app/core/shared/metadata-representation/metadata-representation.model';
import { followLink } from '../../../../app/shared/utils/follow-link-config.model';
import { getFirstCompletedRemoteData, getFirstSucceededRemoteData } from '../../../../app/core/shared/operators';
import { filter, map, switchMap } from 'rxjs/operators';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { Relationship } from '../../../../app/core/shared/item-relationships/relationship.model';
import { MetadatumRepresentation } from '../../../../app/core/shared/metadata-representation/metadatum/metadatum-representation.model';
import { Item } from '../../../../app/core/shared/item.model';
import { ItemMetadataRepresentation } from '../../../../app/core/shared/metadata-representation/item/item-metadata-representation.model';
import { hasValue, isEmpty, isNotEmpty } from '../../../../app/shared/empty.util';
import { PaginatedList } from '../../../../app/core/data/paginated-list.model';
import { AtmireAuthorityDetailModel } from '../../../../app/core/atmire-authorities/atmire-authority-detail.model';
import { RelationshipService } from '../../../../app/core/data/relationship.service';
import { AtmireAuthorityDetailDataService } from '../../../../app/core/data/atmire-authority-detail-data.service';

@Injectable()
export class AtmireMetadataRepresentationService {

  constructor(public relationshipService: RelationshipService,
              public authorityDetailService: AtmireAuthorityDetailDataService) {

  }

  /**
   * Resolve a list of metadata values to a list of metadata representations
   * Note: functionality logic copy of {@link MetadataRepresentationListComponent#resolveMetadataRepresentations}
   * @param metadata    The list of all metadata values
   * @param itemType    The type of item to create a representation of
   * @param parentItem  The parent of the list of related metadata representations being resolved
   */
  resolveMetadataRepresentations(metadata: MetadataValue[], itemType: string, parentItem: Item): Observable<MetadataRepresentation[]> {
    if (isEmpty(metadata))  {
      return observableOf([]);
    }
    return observableZip(
      ...metadata
        .map((metadatum: any) => Object.assign(new MetadataValue(), metadatum))
        .map((metadatum: MetadataValue) => {
          if (metadatum.isVirtual) {
            return this.relationshipService.findById(metadatum.virtualValue, true, false, followLink('leftItem'), followLink('rightItem')).pipe(
              getFirstSucceededRemoteData(),
              switchMap((relRD: RemoteData<Relationship>) =>
                observableCombineLatest(relRD.payload.leftItem, relRD.payload.rightItem).pipe(
                  filter(([leftItem, rightItem]) => leftItem.hasCompleted && rightItem.hasCompleted),
                  map(([leftItem, rightItem]) => {
                    if (!leftItem.hasSucceeded || !rightItem.hasSucceeded) {
                      return observableOf(Object.assign(new MetadatumRepresentation(itemType), metadatum));
                    } else if (rightItem.hasSucceeded && leftItem.payload.id === parentItem.id) {
                      return rightItem.payload;
                    } else if (rightItem.payload.id === parentItem.id) {
                      return leftItem.payload;
                    }
                  }),
                  map((item: Item) => Object.assign(new ItemMetadataRepresentation(metadatum), item))
                )
              ));
          } else if (hasValue(metadatum.authority)) {
            return this.authorityDetailService.byAuthorityField(metadatum.authority, { elementsPerPage: 1 }).pipe(
              getFirstCompletedRemoteData(),
              map((rd: RemoteData<PaginatedList<AtmireAuthorityDetailModel>>) => {
                if (rd.isSuccess && hasValue(rd.payload) && isNotEmpty(rd.payload.page)) {
                  return rd.payload.page[0];
                } else {
                  return Object.assign(new MetadatumRepresentation(itemType), metadatum);
                }
              })
            );
          } else {
            return observableOf(Object.assign(new MetadatumRepresentation(itemType), metadatum));
          }
        })
    );
  }

}

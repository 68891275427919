import { HALResource } from '../../../core/shared/hal-resource.model';
import { typedObject } from '../../../core/cache/builders/build-decorators';
import { autoserialize, inheritSerialization } from 'cerialize';
import { ATMIRE_VALUE_PAIR } from './atmire-value-pair.resource-type';

@typedObject
@inheritSerialization(HALResource)
export class AtmireValuePair extends HALResource {
  static type = ATMIRE_VALUE_PAIR;

  type = ATMIRE_VALUE_PAIR;

  @autoserialize
  id: string;

  @autoserialize
  display: string;

  @autoserialize
  value: string;

  @autoserialize
  language: string;

  @autoserialize
  pairtype: string;
}

<ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>

<div class="d-flex align-items-center">
  <div class="flex-grow-1 overflow-hidden">
    <ds-truncatable [id]="dso.id">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
     rel="noopener noreferrer"
     [routerLink]="[itemPageRoute]" class="lead"
           [innerHTML]="firstMetadataValue(['organization.legalName','dc.title'])"></a>
        <span *ngIf="linkType == linkTypes.None"
              class="lead"
              [innerHTML]="firstMetadataValue(['organization.legalName','dc.title'])"></span>
  <ds-item-list-repeatable-md-badge [object]="dso" [fields]="['dc.type']"></ds-item-list-repeatable-md-badge>
        <span class="text-muted">
        <ds-truncatable-part [id]="dso.id" [minLines]="3">
                <span *ngIf="dso.allMetadata(['dc.description']).length > 0"
                      class="item-list-org-unit-description">
                    <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                            [innerHTML]="firstMetadataValue('dc.description')"></span>
                    </ds-truncatable-part>
                </span>
            </ds-truncatable-part>
      </span>
    </ds-truncatable>
  </div>
  <div class="ml-2">
    <ds-atmire-saved-item-list-control *ngIf="savedItemListControlEnabled" [item]="dso" [context]="context"></ds-atmire-saved-item-list-control>
  </div>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest as observableCombineLatest, Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AtmireSavedItemListDataService } from '../../../atmire-saved-item-list/data-services/atmire-saved-item-list-data.service';
import { RemoteData } from '../../../core/data/remote-data';
import { AtmireSavedItemList } from '../../../atmire-saved-item-list/models/atmire-saved-item-list.model';
import { hasNoValue, hasValue, isNotEmpty } from '../../empty.util';
import { getFirstCompletedRemoteData, getFirstSucceededRemoteDataPayload } from '../../../core/shared/operators';
import { Item } from '../../../core/shared/item.model';
import { NotificationsService } from '../../notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Context } from '../../../core/shared/context.model';
import { getAtmireSavedListName } from '../../../atmire-saved-item-list/atmire-saved-item-list.util';
import { AtmireMyListItemControlOption } from './options/atmire-saved-item-list-control-option.model';
import { AtmireSavedItemListStoreService } from '../../../atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AtmireSavedItemListCreateModalComponent } from '../atmire-saved-item-list-create-modal/atmire-saved-item-list-create-modal.component';
import { AuthService } from '../../../core/auth/auth.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ds-atmire-saved-item-list-control',
  templateUrl: './atmire-saved-item-list-control.component.html',
  styleUrls: ['./atmire-saved-item-list-control.component.scss']
})
/**
 * Component rendering a selectable control for a listable item
 * Selecting or deselecting an item will add/remove it from the user's list
 */
export class AtmireSavedItemListControlComponent implements OnInit, OnDestroy {
  /**
   * Item to display a selectable control for
   */
  @Input() item: Item;

  /**
   * Whether or not to render the button as a large button
   * Defaults to false (small button)
   */
  @Input() useLargeButton = false;

  /**
   * The context we matched on to get this component
   */
  @Input() context: Context;

  /**
   * The list of options to choose from to add/remove the item to/from the user's lists
   */
  options$: BehaviorSubject<AtmireMyListItemControlOption[]> = new BehaviorSubject<AtmireMyListItemControlOption[]>(null);

  /**
   * Whether or not the item is selected in at least one of the user's lists
   * Determined by the item's savedLists property
   */
  selected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Whether or not the control should be hidden or not
   * If the user is anonymous and property "atmire.savedItemLists.anonymous" is set to false, the control should be hidden
   */
  hidden$: Observable<boolean>;

  /**
   * The i18n key for the tooltip message depending on the current selected state of the item
   */
  tooltip$: Observable<string>;

  /**
   * The path to the button image depending on the current selected state of the item
   */
  src$: Observable<string>;

  /**
   * The alt message for the button image depending on the current selected state of the item
   */
  alt$: Observable<string>;

  /**
   * Max amount of lists allowed for the current user
   */
  maxNbLists$: Observable<number>;

  /**
   * List of subscriptions
   */
  subs: Subscription[] = [];

  /**
   * The list name of the to be used list
   */
  listName: string;

  constructor(protected savedItemListService: AtmireSavedItemListDataService,
              protected savedItemListStoreService: AtmireSavedItemListStoreService,
              protected notificationsService: NotificationsService,
              protected translateService: TranslateService,
              protected modalService: NgbModal,
              protected authService: AuthService) { }

  ngOnInit() {
    this.hidden$ = this.authService.isAuthenticated().pipe(
      map((authenticated) => !environment.atmire.savedItemLists.anonymous && !authenticated),
    );
    this.listName = getAtmireSavedListName(this.context);
    this.tooltip$ = this.selected$.pipe(map((selected) => selected ? 'atmire.saved-item-list.remove' : 'atmire.saved-item-list.add'));
    this.src$ = this.selected$.pipe(map((selected) => `../../../../assets/images/${selected ? 'list-added.svg' : 'list-not-added.svg'}`));
    this.alt$ = this.selected$.pipe(map((selected) => selected ? 'remove-from-list' : 'add-to-list'));
    this.maxNbLists$ = this.savedItemListService.getMaxNbLists();
    this.updateOptions();

    this.subs.push(this.savedItemListStoreService.getStoredListIdsObs().subscribe(() => this.updateOptions()));
  }

  /**
   * Update the options$ and selected$
   * Populates the array in the following fashion:
   * - First it adds options for each list where the item is already selected in
   * - Secondly, an option is added for the recent list if it's not already added yet
   * - Finally, options are added for the remaining lists
   */
  updateOptions() {
    observableCombineLatest(
      this.savedItemListStoreService.getStoredListState(),
      this.savedItemListService.getRecentList(),
    ).pipe(take(1)).subscribe(([listState, recentList]) => {
      const options = [];
      const listEntries = Object.entries(hasValue(listState.currentLists) ? listState.currentLists : {});
      // Add options for lists where the item is already selected
      if (hasValue(this.item) && isNotEmpty(this.item.savedLists)) {
        this.item.savedLists.forEach((selectedList) => {
          const foundList = listEntries.find(([entryName, entryId]) => entryId === selectedList);
          if (hasValue(foundList)) {
            this.addToOptions(options, new AtmireMyListItemControlOption(foundList[1], foundList[0], true));
          }
        });
      }
      // If at this point the array contains at least one option, the item is selected in a list
      this.selected$.next(isNotEmpty(options));
      // Add an option for the most recent list
      if (hasValue(recentList)) {
        this.addToOptions(options, new AtmireMyListItemControlOption(recentList.id, recentList.name, false));
      }
      // Add the rest of the options (where the item is not selected yet)
      listEntries.forEach(([entryName, entryId]) => {
        this.addToOptions(options, new AtmireMyListItemControlOption(entryId, entryName, false));
      });
      this.options$.next(options);
    });
  }

  /**
   * Add an option to an array of options
   * Skip the addition if an option for the same list is already present in the array
   * @param options
   * @param optionToAdd
   */
  private addToOptions(options: AtmireMyListItemControlOption[], optionToAdd: AtmireMyListItemControlOption) {
    if (hasNoValue(options.find((option) => option.listId === optionToAdd.listId))) {
      options.push(optionToAdd);
    }
  }

  /**
   * Toggle the selected state of this object for the first list in the options
   */
  toggle() {
    if (isNotEmpty(this.options$.value)) {
      const option = this.options$.value[0];
      if (option.selected) {
        this.deselect(option.listId);
      } else {
        this.select(option.listId);
      }
    } else {
      this.savedItemListService.findMyList().pipe(
        getFirstSucceededRemoteDataPayload()
      ).subscribe((list) => {
        this.select(list.id);
      });
    }
  }

  /**
   * Add the object's ID to the current {@link AtmireSavedItemList}
   */
  select(listId: string) {
    this.setSelectedOption(listId, true);
    this.performAction(this.savedItemListService.addItemByListId(listId, this.item), listId, false, 'atmire.saved-item-list.control.select.error');
  }

  /**
   * Remove the object's ID from the current {@link AtmireSavedItemList}
   */
  deselect(listId: string) {
    this.setSelectedOption(listId, false);
    this.performAction(this.savedItemListService.removeItemByListId(listId, this.item), listId, true, 'atmire.saved-item-list.control.deselect.error');
  }

  /**
   * Perform an observable action, change the value of selected$ and show a notification depending on the result
   * @param action$             Action to perform (subscribe on)
   * @param setSelectedOnError  The value of selected$ in case of an error response
   * @param errorMsg            The i18n key of the message to display in case of an error response
   */
  performAction(action$: Observable<RemoteData<any>>, listId: string, setSelectedOnError: boolean, errorMsg: string) {
    action$.pipe(
      getFirstCompletedRemoteData()
    ).subscribe((rd) => {
      if (rd.hasFailed) {
        this.setSelectedOption(listId, setSelectedOnError);
        this.notificationsService.error(this.translateService.instant(`${errorMsg}.title`), rd.errorMessage);
      }
    });
  }

  /**
   * Set the option for a list (by ID) to a given selected state
   * Achieve this by adding/removing the provided list's ID to/from the item's savedLists property and updating the options$ accordingly
   * @param listId
   * @param selected
   */
  setSelectedOption(listId: string, selected: boolean) {
    if (hasValue(this.item)) {
      if (selected) {
        if (isNotEmpty(this.item.savedLists)) {
          if (this.item.savedLists.indexOf(listId) < 0) {
            this.item.savedLists = [...this.item.savedLists, listId];
          }
        } else {
          this.item.savedLists = [listId];
        }
      } else {
        if (isNotEmpty(this.item.savedLists)) {
          this.item.savedLists = this.item.savedLists.filter((id) => id !== listId);
        } else {
          this.item.savedLists = [];
        }
      }
    }
    this.updateOptions();
  }

  /**
   * Open a modal where a user can create a new list by entering a name
   * After the list is created, add the item to the new list and display a notification
   */
  addToNewList() {
    const modalRef = this.modalService.open(AtmireSavedItemListCreateModalComponent);
    modalRef.componentInstance.confirm.subscribe((list) => {
      this.notificationsService.success(this.translateService.instant('atmire.saved-item-list.control.new-list.success'));
      this.select(list.id);
    });
    modalRef.componentInstance.error.subscribe((errorMsg) => {
      this.notificationsService.error(this.translateService.instant('atmire.saved-item-list.control.new-list.error'), errorMsg);
    });
  }

  /**
   * Unsub all subscriptions
   */
  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}

import { autoserialize, autoserializeAs } from 'cerialize';

/**
 * Object containing statistics about a {@link SearchTerm}
 */
export class SearchTermValues {
  /**
   * Amount of searches for the term
   */
  @autoserialize
  searches: number;

  /**
   * Percentage of the total amount of searches within the term's scope
   */
  @autoserialize
  percentage: number;

  /**
   * Total amount of page views
   */
  @autoserialize
  pageviews: number;

  /**
   * Amount of page views per search
   */
  @autoserializeAs('pageviews_per_search')
  pageviewsPerSearch: number;
}

import {
  Component,
  ComponentFactoryResolver,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  DEFAULT_STATLET_WRAPPER_CONTEXT,
  SINGLE_STATLET_WRAPPER_COMPONENT_FACTORY
} from './single-statlet-wrapper.decorator';
import { Context } from '../../../../core/shared/context.model';
import { GenericConstructor } from '../../../../core/shared/generic-constructor';
import { SingleStatletWrapperComponent } from './single-statlet-wrapper.component';
import { SingleStatletWrapperDirective } from './single-statlet-wrapper.directive';
import { hasNoValue, hasValue } from '../../../../shared/empty.util';
import { Statlet } from '../data/models/statlet.model';
import { hasStatletData } from '../data/models/statlet.utils';

@Component({
  selector: 'ds-atmire-cua-single-statlet-wrapper-loader',
  templateUrl: './single-statlet-wrapper-loader.component.html',
})
export class SingleStatletWrapperLoaderComponent implements OnChanges, OnInit {
  /**
   * The object to display statlets for
   */
  @Input() statlet: Statlet;

  /**
   * The size the statlet is rendered in
   */
  @Input() size: string;

  @Input() context: Context = DEFAULT_STATLET_WRAPPER_CONTEXT;

  @Input() palette: string[];

  hasData = true;

  componentInstance: SingleStatletWrapperComponent;

  @ViewChild(SingleStatletWrapperDirective, { static: true }) statletWrapperDirective: SingleStatletWrapperDirective;

  constructor(protected componentFactoryResolver: ComponentFactoryResolver,
              @Inject(SINGLE_STATLET_WRAPPER_COMPONENT_FACTORY) private getComponentForSingleStatletWrapper: (context?: Context) => GenericConstructor<any>) {
  }

  ngOnInit(): void {
    this.hasData = hasStatletData(this.statlet);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (hasNoValue(this.componentInstance)) {
      if (hasValue(this.statletWrapperDirective)) {
        const component = this.getComponentForSingleStatletWrapper(this.context);
        if (hasValue(component)) {
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
          if (hasValue(componentFactory)) {
            const viewContainerRef = this.statletWrapperDirective.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(componentFactory);
            const statletInstance = componentRef.instance as any;
            if (hasValue(statletInstance)) {
              this.componentInstance = statletInstance;
              this.componentInstance.statlet = this.statlet;
              this.componentInstance.size = this.size;
              this.componentInstance.context = this.context;
              this.componentInstance.palette = this.palette;
              this.componentInstance.hasData = this.hasData;
            }
          }
        }
      }
    }
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DSpaceObject } from '../../../../core/shared/dspace-object.model';
import { Observable, Subscription } from 'rxjs';
import { StatletDataService } from '../data/services/statlet-data.service';
import { RemoteData } from '../../../../core/data/remote-data';
import { PaginatedList } from '../../../../core/data/paginated-list.model';
import { Statlet } from '../data/models/statlet.model';
import {
  ensureArrayHasValue,
  hasNoValue,
  hasValue,
  isNotEmpty
} from '../../../../shared/empty.util';
import { getFirstSucceededRemoteDataPayload } from '../../../../core/shared/operators';
import { hasStatletData } from '../data/models/statlet.utils';
import { map } from 'rxjs/operators';
import { AlertType } from '../../../../shared/alert/aletr-type';
import { getAcceptedGraphWidths } from '../single-statlet/graph-types/statlet-graph-types.model';
import { Context } from '../../../../core/shared/context.model';
import { StatletPosition } from '../data/models/statlet-position.model';

@Component({
  selector: 'ds-atmire-cua-statlets-section',
  templateUrl: './statlets-section.component.html',
  styleUrls: ['./statlets-section.component.scss']
})
/**
 * Component displaying all Statlets for a given DSpaceObject
 */
export class StatletsSectionComponent implements OnInit, OnDestroy {
  /**
   * The object to display statlets for
   */
  @Input() dso: DSpaceObject;

  @Input() context: Context;

  @Input() displayShowHideButton = true;

  isInlineContext = false;

  /**
   * The statlets to display for the object
   * Will only be initialised the first time statletsShown$ emits true. This is to avoid unnecessary calls when the
   * statlets are still hidden.
   */
  statletsRD$: Observable<RemoteData<PaginatedList<Statlet>>>;

  /**
   * Should we display the statlets?
   * The fist time this observable emits true, the statlets will be requested
   */
  statletsShown$: Observable<boolean>;

  /**
   * Does the object contain any Statlets?
   * If false, the "show statistical information" button will be hidden
   */
  hasStatlets$: Observable<boolean>;

  /**
   * Does any of the statlets have any data?
   */
  hasData$: Observable<boolean>;

  /**
   * The list of Subscription
   * @type {Array}
   */
  protected subs: Subscription[] = [];

  /**
   * The AlertType enumeration
   * @type {AlertType}
   */
  public AlertTypeEnum = AlertType;

  // w2p-88791 Temp disable 'Show statistical information' button on item page
  renderStatletsSection = true;

  constructor(protected statletService: StatletDataService) { }

  ngOnInit(): void {
    // w2p-88791 Temp disable 'Show statistical information' button on item page
    if (this.renderStatletsSection) {
      this.initialiseComponent();
    }
  }

  // w2p-88791 Temp disable 'Show statistical information' button on item page
  private initialiseComponent() {
    this.isInlineContext = this.context === Context.StatletInline;
    this.hasStatlets$ = this.statletService.searchByObject(this.dso._links.self.href, StatletPosition.BelowTheFold, true).pipe(
      getFirstSucceededRemoteDataPayload(),
      map((statlets: PaginatedList<Statlet>) => isNotEmpty(statlets.page))
    );
    this.statletsShown$ = this.statletService.statletsShown();
    this.subs.push(
      this.statletsShown$.subscribe((shown) => {
        if (hasNoValue(this.statletsRD$) && shown) {
          this.statletsRD$ = this.statletService.searchByObject(this.dso._links.self.href, StatletPosition.BelowTheFold);
          this.hasData$ = this.statletsRD$.pipe(
            getFirstSucceededRemoteDataPayload(),
            map((statlets) => statlets.page),
            ensureArrayHasValue(),
            map((statlets) => statlets.some(hasStatletData))
          );
          this.subs.push(
            this.statletsRD$.pipe(getFirstSucceededRemoteDataPayload()).subscribe((statlets) => {
              statlets.page.forEach((statlet) => {
                if (statlet.style.width === null) {
                  console.warn(`An incorrect width was defined for statlet \"${statlet.shortName}\". Accepted widths: ${getAcceptedGraphWidths()}`);
                }
              });
            })
          );
        }
      }),
    );
  }

  /**
   * Unsubscribe from all open subscriptions
   */
  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}

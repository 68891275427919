import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { find } from 'rxjs/operators';
import { RemoteData } from '../../core/data/remote-data';
import { TakeDown } from '../../core/shared/takedown.model';
import { TakedownService } from '../../core/data/takedown.service';

/**
 * This class represents a resolver that requests a specific takedown before the route is activated
 */
@Injectable()
export class TakedownResolver implements Resolve<RemoteData<TakeDown>> {

  constructor(private takedownService: TakedownService) {
  }

  /**
   * Method for resolving a takedown based on the parameters in the current route
   * @param {ActivatedRouteSnapshot} route The current ActivatedRouteSnapshot
   * @param {RouterStateSnapshot} state The current RouterStateSnapshot
   * @returns Observable<<RemoteData<TakeDown>> Emits the found takedown based on the parameters in the current route,
   * or an error if something went wrong
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RemoteData<TakeDown>> {
    return this.takedownService.findById(route.params.id)
      .pipe(
        find((RD: RemoteData<TakeDown>) => RD.hasFailed || RD.hasSucceeded),
      );
  }
}

import { Component, ElementRef, ViewChild } from '@angular/core';
import { DatasetComponent as BaseComponent } from '../../../../../../../app/entity-groups/research-entities/item-pages/dataset/dataset.component';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { Context } from '../../../../../../../app/core/shared/context.model';

@listableObjectComponent('Dataset', ViewMode.StandalonePage, Context.Any, 'unsw')
@Component({
  selector: 'ds-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss'],
})
export class DatasetComponent extends BaseComponent {
  showAltmetricDonut = false;
  @ViewChild('statletsSection') statletsSectionRef: ElementRef;
}

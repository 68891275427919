import * as moment from 'moment';
import { DateRange } from './date-range.model';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export class RelativeDateRange implements DateRange {
  constructor(
    public name: string,
    private timeUnion: DurationConstructor,
    private negativeOffset: number = 0, // how many `timeUnions` ago did the range end
    private length: number = 1, // how many `timeUnions` is the range long
  ) {
  }

  get startDate(): Date {
    return moment().subtract(this.negativeOffset + this.length - 1, this.timeUnion).startOf(this.timeUnion).toDate();
  }

  get endDate(): Date {
    return moment().subtract(this.negativeOffset, this.timeUnion).endOf(this.timeUnion).toDate();
  }
}

// TODAY
export const TODAY: DateRange = new RelativeDateRange('today', 'day');

// YESTERDAY
export const YESTERDAY: DateRange = new RelativeDateRange('yesterday', 'day', 1);

// THIS WEEK
export const THIS_WEEK: DateRange = new RelativeDateRange('this_week', 'week');

// LAST WEEK
export const LAST_WEEK: DateRange = new RelativeDateRange('last_week', 'week', 1);

// THIS MONTH
export const THIS_MONTH: DateRange = new RelativeDateRange('this_month', 'month');

// LAST WEEK
export const LAST_MONTH: DateRange = new RelativeDateRange('last_month', 'month', 1);

// THIS QUARTER
export const THIS_QUARTER: DateRange = new RelativeDateRange('this_quarter', 'quarter', 0);

// LAST QUARTER
export const LAST_QUARTER: DateRange = new RelativeDateRange('last_quarter', 'quarter', 1);

// THIS YEAR
export const THIS_YEAR: DateRange = new RelativeDateRange('this_year', 'year');

// LAST YEAR
export const LAST_YEAR: DateRange = new RelativeDateRange('last_year', 'year', 1);

import { DynamicSingleStatletComponent } from '../dynamic-single-statlet.component';
import { Component, Inject, Injector } from '@angular/core';
import { zip as observableZip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AtmireCuaColorService } from '../../../../../shared/atmire-cua-color.service';
import { AlternativeLabelService } from '../../../../../shared/alternative-label.service';
import { HostWindowService } from '../../../../../../shared/host-window.service';
import { STATLET_DATA_TYPE_SERVICE_FACTORY } from '../../data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../core/shared/generic-constructor';

@Component({
  selector: 'ds-atmire-cua-abstract-merged-single-statlet',
  template: ''
})
/**
 * A component displaying a single statlet for which the point values are merged together
 * Usually, these types should only contain one point value for "any", this component ensures that all values are combined
 * when this is not the case.
 */
export class MergedSingleStatletComponent extends DynamicSingleStatletComponent {
  constructor(protected parentInjector: Injector,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string, context: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

  loadData(): void {
    this.data$ = this.windowService.widthCategory.pipe(
      switchMap((width) => observableZip(...this.statlet.points.map((point) => {
        let value = 0;
        Object.values(point.values).forEach((v) => value += v);
        const header = this.getHeaderById(this.statlet.rowHeaders, point.id);
        const dataService = this.getStatletDataTypeServiceForHeader(header);
        return this.getHeaderName(header, dataService, point.label, width).pipe(
          map((name) => Object.assign({ name, value }))
        );
      })))
    );
  }

  loadColors() {
    this.colors$ = this.data$.pipe(
      map((data) => {
        return Object.assign({ domain: this.colorService.getColorSet(data.length, this.statlet.style.palette ? this.statlet.style.palette : this.palette) });
      })
    );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { getFilterByRelation } from '../../../../shared/utils/relation-query.utils';
import { SearchService } from '../../../../core/shared/search/search.service';
import { Item } from '../../../../core/shared/item.model';
import { BehaviorSubject } from 'rxjs';
import { RemoteData } from '../../../../core/data/remote-data';
import { SearchResult } from '../../../../shared/search/search-result.model';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { filter, take } from 'rxjs/operators';
import { hasValue, isNotEmpty } from '../../../../shared/empty.util';
import { PaginatedSearchOptions } from '../../../../shared/search/paginated-search-options.model';
import { PaginationComponentOptions } from '../../../../shared/pagination/pagination-component-options.model';
import { PaginatedList } from '../../../../core/data/paginated-list.model';
import { SearchObjects } from '../../../../shared/search/search-objects.model';

@Component({
  selector: 'ds-related-entities-metadata-via-search',
  templateUrl: './related-entities-metadata-via-search.component.html',
  styleUrls: ['./related-entities-metadata-via-search.component.scss']
})
export class RelatedEntitiesMetadataViaSearchComponent implements OnInit {

  /**
   * The item to render relationships for
   */
  @Input() item: Item;

  /**
   * The types of relationship to fetch items for
   * e.g. 'isAuthorOfPublication'
   */
  @Input() relationType: {
    label: string,
    filter: string,
    configuration?: string
  };

  items$: BehaviorSubject<Item[]>;

  viewMode = ViewMode.ListElement;

  currentPage: number;

  isLoading: boolean;

  isLastPage: boolean;

  pageSize = 5;

  constructor(private searchService: SearchService) { }

  ngOnInit() {
    this.getNextPage();
  }

  getNextPage(): void {
    this.isLoading = true;
    if (this.currentPage === undefined) {
      this.currentPage = 1;
      this.items$ = new BehaviorSubject([]);
    } else {
      this.currentPage++;
    }
    const pagination = new PaginationComponentOptions();
    pagination.pageSize = this.pageSize;
    pagination.currentPage = this.currentPage;
    this.searchService.search(new PaginatedSearchOptions({
      pagination,
      configuration: this.relationType.configuration,
      fixedFilter: getFilterByRelation(this.relationType.filter, this.item.uuid)
    })).pipe(
      filter((itemsRD: RemoteData<SearchObjects<Item>>) => hasValue(itemsRD.payload)),
      take(1),
    ).subscribe((itemsRD: RemoteData<SearchObjects<Item>>) => {
      if (hasValue(itemsRD.payload) && isNotEmpty(itemsRD.payload.page)) {
        const current: Item[] = this.items$.getValue();
        const nextPage = itemsRD.payload.page.map((result: SearchResult<Item>) => result.indexableObject);
        this.items$.next([...current, ...nextPage]);
        this.isLastPage = this.currentPage === itemsRD.payload.totalPages;
      }
      this.isLoading = false;
    });
  }
}

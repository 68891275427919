import { Component, Injector } from '@angular/core';
import { NavbarComponent as BaseComponent } from '../../../../app/navbar/navbar.component';
import { slideMobileNav } from '../../../../app/shared/animations/slide';
import { MenuItemType } from '../../../../app/shared/menu/initial-menus-state';
import { MenuService } from '../../../../app/shared/menu/menu.service';
import { HostWindowService } from '../../../../app/shared/host-window.service';
import { LinkMenuItemModel } from '../../../../app/shared/menu/menu-item/models/link.model';
import { TextMenuItemModel } from '../../../../app/shared/menu/menu-item/models/text.model';
import { environment } from '../../../../environments/environment';
import { ConfigurationDataService } from '../../../../app/core/data/configuration-data.service';
import { getFirstSucceededRemoteDataPayload } from '../../../../app/core/shared/operators';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { ConfigurationProperty } from '../../../../app/core/shared/configuration-property.model';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { ExternalLinkMenuItemModel } from '../../../../app/shared/menu/menu-item/models/external-link.model';
import { StatisticsMenuService } from '../../../../app/shared/menu/statistics-menu/statistics-menu.service';
import { AuthService } from '../../../../app/core/auth/auth.service';

/**
 * Component representing the public navbar
 */
@Component({
    selector: 'ds-navbar',
    styleUrls: ['./navbar.component.scss'],
    // styleUrls: ['../../../../app/navbar/navbar.component.scss'],
    templateUrl: './navbar.component.html',
    // templateUrl: '../../../../app/navbar/navbar.component.html',
    animations: [slideMobileNav]
})
export class NavbarComponent extends BaseComponent {
    noSearchInHeaderPages: RegExp[] = [/^\/home/g, /^\/search/g];
    showSearchBar$: Observable<boolean>;

    constructor(
      protected menuService: MenuService,
      protected injector: Injector,
      public windowService: HostWindowService,
      public router: Router
    ) {
        super(menuService, injector, windowService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.showSearchBar$ = this.router.events.pipe(
            filter((val) => val instanceof NavigationEnd),
            startWith({urlAfterRedirects: this.router.url} as NavigationEnd),
            map((val: NavigationEnd) => {
                return !this.noSearchInHeaderPages.some((pageRegex: RegExp) => val.urlAfterRedirects.match(pageRegex));
        }));
    }

    public toggleNavbar(): void {
        this.menuService.toggleMenu(this.menuID);
    }
}

<div class="w-100 pt-3 pb-3">
  <div class="row">
    <form class="w-100 pt-3 pb-3" [formGroup]="formData" (ngSubmit)="submitForm(formData.value)">
    <div class="form-group input-group col-12 search-form">
      <input class="form-control" placeholder="{{'browse.startsWith.type_text' | translate}}" type="text" name="startsWith" formControlName="startsWith" [value]="getStartsWith()" />
      <span class="input-group-append">
        <button class="btn btn-dark" type="submit">
          <img src="assets/images/search.svg"> {{'browse.startsWith.submit' | translate}}
        </button>
      </span>
    </div>
    <div class="col-12 d-none d-sm-inline-block text-center">
      <ul class="list-inline">
        <li class="list-inline-item" *ngFor="let option of startsWithOptions">
          <a [routerLink]="[]" (click)="setStartsWith(option)" [ngClass]="{ 'selected': isSelectedOption(option) }"
          >{{option}}</a>
        </li>
      </ul>
    </div>
    </form>
    <div class="col-6 col-sm-12 d-sm-none">
      <div ngbDropdown placement="bottom-left" class="form-group">
        <button class="btn btn-outline-light" id="chooseStart" ngbDropdownToggle
                [title]="'browse.startsWith.choose_start' | translate"
                [attr.aria-label]="'browse.startsWith.choose_start' | translate"
        >
          {{ startsWith ? startsWith : 'browse.startsWith.choose_start' | translate}}
          <img src="assets/images/chevron-down.png" alt="">
        </button>
        <div id="chooseStartDropdown" aria-labelledby="chooseStart" ngbDropdownMenu>
          <button class="dropdown-item btn-light" (click)="setStartsWith(undefined)">
            <i [ngClass]="{'invisible': startsWith}" class="fas fa-check" aria-hidden="true"></i>
            {{ 'browse.startsWith.choose_start' | translate }}
          </button>
          <button class="dropdown-item btn-light" *ngFor="let option of startsWithOptions" (click)="setStartsWith(option)">
            <i [ngClass]="{'invisible': option != startsWith}" class="fas fa-check" aria-hidden="true"></i>
            {{ option }}
          </button>
        </div>
      </div>
    </div>
  </div>

</div>


import { autoserialize } from 'cerialize';
import { isNotEmpty } from '../../../../../shared/empty.util';
import { EquatableObject } from '../../../../../core/utilities/equatable';
import { excludeFromEquals } from '../../../../../core/utilities/equals.decorators';

export class StatletFilter extends EquatableObject<StatletFilter> {
  static VALUE_SEPARATOR = '||';

  @autoserialize
  category: string;

  @autoserialize
  type: string;

  @autoserialize
  values: string[];

  @autoserialize
  @excludeFromEquals
  information: string;

  @autoserialize
  filterField: string;

  get firstValue(): string {
    return isNotEmpty(this.values) ? this.values[0] : null;
  }

  get hasValue(): boolean {
    return isNotEmpty(this.firstValue);
  }

  get valueString(): string {
    if (this.hasValue) {
      return this.values.join(StatletFilter.VALUE_SEPARATOR);
    } else {
      return null;
    }
  }

  equals(other: StatletFilter): boolean {
    if (other.valueString !== this.valueString) {
      return false;
    }
    return super.equals(other);
  }

  equalsWithoutValue(other: StatletFilter): boolean {
    return super.equals(other);
  }
}

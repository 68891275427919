<!--<div class="w-100 alert alert-danger">-->
  <!-- xs and sm -->
<!--  <div class="container d-block d-md-none">-->
<!--    <p class="mb-0">{{'unsw.header.text.xs.p1' | translate}} <a href='https://unsw-primo.hosted.exlibrisgroup.com/primo-explore/search?vid=UNSWORKS' target="_blank">{{'unsw.header.text.link' |translate}}</a> {{'unsw.header.text.xs.p2' | translate}}</p>-->
<!--  </div>-->
  <!-- md and up -->
<!--  <div class="container">-->
<!--    <p class="mb-2">{{'unsw.header.text.p1' | translate}}</p>-->
<!--    <p class="mb-0">{{'unsw.header.text.p2' | translate}} <a href='https://unsw-primo.hosted.exlibrisgroup.com/primo-explore/search?vid=UNSWORKS' target="_blank">{{'unsw.header.text.link' |translate}}</a>.</p>-->
<!--  </div>-->
<!--</div>-->
<header class="header" xmlns="http://www.w3.org/1999/html">
    <div class="header-top container d-flex align-items-center">
        <div>
            <a class="navbar-brand mr-4" href="https://www.unsw.edu.au/" target="_blank">
                <img src="assets/images/logo.png" alt="logo"/>
            </a>
        </div>
        <a href="https://www.library.unsw.edu.au/" class="text-decoration-none" target="_blank">
            <h3 class=" d-none d-md-block header-label m-0 font-weight-normal">{{ 'unsw.header.library' | translate }}</h3>
        </a>
    </div>
</header>

<h3>{{"search.filters.head" | translate}}</h3>
<div *ngIf="(filters | async)?.hasSucceeded">
    <div *ngFor="let filter of (filters | async)?.payload; trackBy: trackUpdate">
        <ds-search-filter [filter]="filter" [inPlaceSearch]="inPlaceSearch"></ds-search-filter>
    </div>
</div>
<a class="btn btn-outline-light w-100 py-2"
   [routerLink]="[searchLink]"
   [queryParams]="clearParams | async"
   queryParamsHandling="merge" role="button">
  {{"search.filters.reset" | translate}}
</a>

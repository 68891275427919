import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ExternalSourceEntry } from '../../../../../../../../core/shared/external-source-entry.model';
import { ListableObject } from '../../../../../../../object-collection/shared/listable-object.model';
import { NotificationsService } from '../../../../../../../notifications/notifications.service';
import { Metadata } from '../../../../../../../../core/shared/metadata.utils';
import { MetadataValue } from '../../../../../../../../core/shared/metadata.models';
import { VocabularyService } from '../../../../../../../../core/submission/vocabularies/vocabulary.service';
import { getFirstCompletedRemoteData } from '../../../../../../../../core/shared/operators';
import { VocabularyEntryDetail } from '../../../../../../../../core/submission/vocabularies/models/vocabulary-entry-detail.model';
import { RemoteData } from '../../../../../../../../core/data/remote-data';

@Component({
  selector: 'ds-atmire-search-external-source-entry-import-modal',
  styleUrls: ['./atmire-search-external-source-entry-import-modal.component.scss'],
  templateUrl: './atmire-search-external-source-entry-import-modal.component.html'
})
export class AtmireSearchExternalSourceEntryImportModalComponent implements OnInit {

  externalSourceEntry: ExternalSourceEntry;

  vocabulary: string;

  /**
   * The metadata value for the entry's uri
   */
  uri: MetadataValue;

  /**
   * An object has been imported, send it to the parent component
   */
  importedObject: EventEmitter<VocabularyEntryDetail> = new EventEmitter<VocabularyEntryDetail>();

  labelPrefix = 'atmire.submission.search-modal.external-source.import.';

  constructor(public modal: NgbActiveModal,
              protected vocabularyService: VocabularyService,
              protected notificationsService: NotificationsService,
              protected translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.uri = Metadata.first(this.externalSourceEntry.metadata, 'dc.identifier.uri');
  }

  /**
   * Close the window
   */
  close() {
    this.modal.close();
  }

  /**
   * Perform the import of the external entry
   */
  import() {
    const selfLink = this.externalSourceEntry._links.self.href;
    this.vocabularyService.createVocabularyEntryDetailFromExternalSource(this.vocabulary, selfLink.substring(selfLink.indexOf('/api'))).pipe(
      getFirstCompletedRemoteData()
    ).subscribe((rd: RemoteData<VocabularyEntryDetail>) => {
      if (rd.hasSucceeded) {
        this.notificationsService.success(this.translateService.instant(`${this.labelPrefix}success.title`), this.translateService.instant(`${this.labelPrefix}success.content`));
        this.importedObject.emit(rd.payload);
      } else if (rd.hasFailed) {
        this.notificationsService.error(this.translateService.instant(`${this.labelPrefix}error.title`), rd.errorMessage);
      }
    });
    this.close();
  }
}

import { filter, map, mergeMap, take } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { ItemPageComponent } from '../simple/item-page.component';
import { MetadataMap } from '../../core/shared/metadata.models';
import { ItemDataService } from '../../core/data/item-data.service';

import { RemoteData } from '../../core/data/remote-data';
import { Item } from '../../core/shared/item.model';

import { fadeInOut } from '../../shared/animations/fade';
import { hasValue } from '../../shared/empty.util';
import { AuthService } from '../../core/auth/auth.service';
import { Location } from '@angular/common';

import { StatisticsMenuService } from '../../shared/menu/statistics-menu/statistics-menu.service';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../../core/data/feature-authorization/feature-id';

/**
 * This component renders a full item page.
 * The route parameter 'id' is used to request the item it represents.
 */

@Component({
  selector: 'ds-full-item-page',
  styleUrls: ['./full-item-page.component.scss'],
  templateUrl: './full-item-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut]
})
export class FullItemPageComponent extends ItemPageComponent implements OnInit, OnDestroy {

  itemRD$: BehaviorSubject<RemoteData<Item>>;

  metadata$: Observable<MetadataMap>;

  /** Defines whether or not the current user is a site wide administrator */
  isAdmin: Observable<boolean>;


  /**
   * True when the itemRD has been originated from its workflowitem, false otherwise.
   */
  fromWfi = false;

  subs = [];

  constructor(protected route: ActivatedRoute,
              router: Router,
              items: ItemDataService,
              authService: AuthService,
              statisticsMenuService: StatisticsMenuService,
              private _location: Location,
              private authorizationDataService: AuthorizationDataService) {
    super(route, router, items, authService, statisticsMenuService);
  }

  /*** AoT inheritance fix, will hopefully be resolved in the near future **/
  ngOnInit(): void {
    super.ngOnInit();
    this.isAdmin = this.authorizationDataService.isAuthorized(FeatureID.AdministratorOf);
    this.metadata$ = this.itemRD$.pipe(
      map((rd: RemoteData<Item>) => rd.payload),
      filter((item: Item) => hasValue(item)),
      mergeMap((item) => {
        return this.authorizationDataService.isAuthorized(FeatureID.AdministratorOf, item._links.self.href).pipe(
          map((isAdmin: boolean) => {
            const metadata: MetadataMap = {};
            Object.keys(item.metadata).forEach((key) => {
              if (!key.startsWith('relation.') || isAdmin) {
                metadata[key] = item.metadata[key];
              }
            });
            return metadata;
          })
        );
      })
    );

    this.subs.push(this.route.data.subscribe((data: Data) => {
        this.fromWfi = hasValue(data.wfi);
      })
    );
  }

  /**
   * Navigate back in browser history.
   */
  back() {
    this._location.back();
  }

  ngOnDestroy() {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }
}

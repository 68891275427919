<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'journal.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'journal.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-generic-item-page-field class="item-page-fields" [item]="object"
      [fields]="['creativeworkseries.issn']"
      [label]="'journal.page.issn'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field class="item-page-fields" [item]="object"
      [fields]="['creativework.publisher']"
      [label]="'journal.page.publisher'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['creativework.editor']"
      [label]="'journal.page.editor'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isVolumeOfJournal'"
      [label]="'relationships.isVolumeOf' | translate">
    </ds-related-items>
    <ds-generic-item-page-field class="item-page-fields" [item]="object"
      [fields]="['dc.description']"
      [label]="'journal.page.description'">
    </ds-generic-item-page-field>
    <div>
      <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.license-files" bundle="LICENSE" [item]="object"></ds-item-page-file-section>
      <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.administration-files" bundle="ADMINISTRATION" [item]="object"></ds-item-page-file-section>

      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isJournalOfPublication',
                                          filter: 'isJournalOfPublication'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>

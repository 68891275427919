import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AtmireSearchTabComponent } from './atmire-search-tab.component';
import { SEARCH_CONFIG_SERVICE } from '../../../../../../my-dspace-page/my-dspace-page.component';
import { SearchConfigurationService } from '../../../../../../core/shared/search/search-configuration.service';
import { PaginationComponentOptions } from '../../../../../pagination/pagination-component-options.model';
import { PaginationService } from '../../../../../../core/pagination/pagination.service';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../../../../../../core/data/remote-data';
import { PaginatedList } from '../../../../../../core/data/paginated-list.model';
import { FormFieldMetadataValueObject } from '../../../models/form-field-metadata-value.model';
import { AtmireSearchSelectableListElement } from './models/atmire-search-selectable-list-element';
import { hasNoValue, hasValue, hasValueOperator, isNotEmpty } from '../../../../../empty.util';
import { getAllSucceededRemoteDataPayload } from '../../../../../../core/shared/operators';
import { filter, map, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'ds-atmire-search-pagination-tab-contents',
  template: '',
  providers: [
    {
      provide: SEARCH_CONFIG_SERVICE,
      useClass: SearchConfigurationService
    }
  ]
})
export class AtmireSearchPaginationTabComponent<T> extends AtmireSearchTabComponent implements OnInit, OnChanges {
  RD$: Observable<RemoteData<PaginatedList<T>>>;
  elements$: Observable<AtmireSearchSelectableListElement<T>[]>;

  defaultPagination: PaginationComponentOptions;
  query$: Observable<string>;

  updateElements$: BehaviorSubject<boolean>;

  constructor(protected searchService: SearchConfigurationService,
              protected paginationService: PaginationService) {
    super();
  }

  ngOnInit(): void {
    this.defaultPagination = Object.assign(new PaginationComponentOptions(), {
      id: this.searchService.paginationID,
      pageSize: 10,
    });
    this.query$ = this.searchService.getCurrentQuery(this.query);
    this.RD$ = combineLatest(this.query$, this.searchService.getCurrentPagination(this.defaultPagination.id, this.defaultPagination)).pipe(
      switchMap(([query, pagination]) => {
        if (isNotEmpty(query)) {
          return this.findObjects(query, pagination);
        } else {
          return [null];
        }
      }),
    );
    if (hasNoValue(this.updateElements$)) {
      this.updateElements$ = new BehaviorSubject<boolean>(true);
    }
    this.elements$ = combineLatest(
      this.RD$.pipe(
        hasValueOperator(),
        getAllSucceededRemoteDataPayload()
      ),
      this.updateElements$.pipe(filter((update) => update === true)),
    ).pipe(
      map(([objects, update]) => objects.page.map((object) => {
        const selected = this.getSelectedObjects(object);
        const values = [];
        selected.map((o) => o.value).filter((value) => isNotEmpty(value)).forEach((value) => {
          if (values.indexOf(value) < 0) {
            values.push(value);
          }
        });
        if (values.indexOf(this.getValueFromObject(object)) < 0) {
          values.push(this.getValueFromObject(object));
        }
        return this.createListElement(object, values, selected.length > 0);
      })),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (hasNoValue(this.updateElements$)) {
      this.updateElements$ = new BehaviorSubject<boolean>(true);
    }
    if (hasValue(changes.selectedValues)) {
      this.updateElements$.next(true);
    }
  }

  /**
   * Abstract method: Should be overwritten in the sub class
   */
  findObjects(query: string, pagination: PaginationComponentOptions): Observable<RemoteData<PaginatedList<T>>> {
    return null;
  }

  /**
   * Abstract method: Should be overwritten in the sub class
   */
  getSelectedObjects(object: T): FormFieldMetadataValueObject[] {
    return [];
  }

  /**
   * Abstract method: Should be overwritten in the sub class
   */
  getValueFromObject(object: T): string {
    return '';
  }

  /**
   * Abstract method: Should be overwritten in the sub class
   */
  createListElement(object: T, values: string[], selected: boolean): AtmireSearchSelectableListElement<T> {
    return null;
  }

  onPageChange(page: number) {
    this.paginationService.updateRoute(this.defaultPagination.id, { page });
  }
}

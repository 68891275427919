<ds-pagination [paginationOptions]="initialPaginationOptions"
               [sortOptions]="initialSortOptions"
               [pageInfoState]="(resultsRD$ | async)?.payload"
               [collectionSize]="(resultsRD$ | async)?.payload?.totalElements"
               [hideGear]="hideGear"
               [hidePaginationDetail]="hidePaginationDetail"
               [hidePagerWhenSinglePage]="hidePagerWhenSinglePage"
               [hidePaginationDetailWhenSinglePage]="hidePaginationDetailWhenSinglePage"
>
  <ds-atmire-object-collection [objects$]="results$"
                               [error$]="error$"
                               [isLoading$]="isLoading$"
                               [viewMode]="viewMode"
                               [context]="context"
                               [linkType]="linkType"
                               [noResultsMsg]="noResultsMsg"
  ></ds-atmire-object-collection>
</ds-pagination>

<ds-configuration-search-page
  [fixedFilterQuery]="fixedFilter"
  [configuration]="configuration"
  [configuration$]="configuration$"
  [searchEnabled]="searchEnabled"
  [sideBarWidth]="sideBarWidth"
  [placeholderScope]="nameService.getName(this.item)"
  [placeholderType]="'item.page.relationships.search.' + relationType | translate"
  [scopeDisabled]="true">
</ds-configuration-search-page>

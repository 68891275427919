import { autoserialize } from 'cerialize';

export class StatletSortOption {
  @autoserialize
  query: string;

  @autoserialize
  category: string;

  @autoserialize
  filterType: string;

  @autoserialize
  value: string;
}

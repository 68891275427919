import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { FollowLinkConfig } from '../../shared/utils/follow-link-config.model';
import { dataService } from '../cache/builders/build-decorators';
import { RemoteDataBuildService } from '../cache/builders/remote-data-build.service';
import { ObjectCacheService } from '../cache/object-cache.service';
import { CoreState } from '../core.reducers';
import { HALEndpointService } from '../shared/hal-endpoint.service';
import { DataService } from './data.service';
import { RemoteData } from './remote-data';
import { RequestService } from './request.service';
import { FindListOptions } from './request.models';
import { PaginatedList } from './paginated-list.model';
import { ATMIRE_AUTHORITY_DETAIL } from '../atmire-authorities/atmire-authority-detail.resource-type';
import { hasValue } from '../../shared/empty.util';
import { AtmireAuthorityDetailModel } from '../atmire-authorities/atmire-authority-detail.model';
import { DefaultChangeAnalyzer } from './default-change-analyzer.service';
import { RequestParam } from '../cache/models/request-param.model';

/* tslint:disable:max-classes-per-file */
class DataServiceImpl extends DataService<AtmireAuthorityDetailModel> {
  protected linkPath = 'authoritydetails';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<AtmireAuthorityDetailModel>) {
    super();
  }

}

@Injectable()
@dataService(ATMIRE_AUTHORITY_DETAIL)
export class AtmireAuthorityDetailDataService {
  protected linkPath = 'authoritydetails';
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<AtmireAuthorityDetailModel>) {
    this.dataService = new DataServiceImpl(requestService, rdbService, null, objectCache, halService, notificationsService, http, comparator);
  }


  /**
   * Returns an observable of {@link RemoteData} of an object, based on an href, with a list of {@link FollowLinkConfig},
   * to automatically resolve {@link HALLink}s of the object
   * @param href                        The url of object we want to retrieve
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param linksToFollow               List of {@link FollowLinkConfig} that indicate which
   *                                    {@link HALLink}s should be automatically resolved
   */
  findByHref(href: string, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: FollowLinkConfig<AtmireAuthorityDetailModel>[]): Observable<RemoteData<AtmireAuthorityDetailModel>> {
    return this.dataService.findByHref(href, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }

  /**
   * Returns a list of observables of {@link RemoteData} of objects, based on an href, with a list of {@link FollowLinkConfig},
   * to automatically resolve {@link HALLink}s of the object
   * @param href                        The url of object we want to retrieve
   * @param findListOptions             Find list options object
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param linksToFollow               List of {@link FollowLinkConfig} that indicate which
   *                                    {@link HALLink}s should be automatically resolved
   */
  findAllByHref(href: string, findListOptions: FindListOptions = {}, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: FollowLinkConfig<AtmireAuthorityDetailModel>[]): Observable<RemoteData<PaginatedList<AtmireAuthorityDetailModel>>> {
    return this.dataService.findAllByHref(href, findListOptions, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }

  /**
   * Make a new FindListRequest with given search method
   *
   * @param searchMethod                The search method for the object
   * @param options                     The [[FindListOptions]] object
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param linksToFollow               List of {@link FollowLinkConfig} that indicate which
   *                                    {@link HALLink}s should be automatically resolved
   * @return {Observable<RemoteData<PaginatedList<T>>}
   *    Return an observable that emits response from the server
   */
  searchBy(searchMethod: string, options: FindListOptions = {}, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: FollowLinkConfig<AtmireAuthorityDetailModel>[]): Observable<RemoteData<PaginatedList<AtmireAuthorityDetailModel>>> {
    return this.dataService.searchBy(searchMethod, options, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }

  /**
   * Perform a search request with the byAuthority search method for the provided authorityID and options
   * @param authorityId               The authority ID to look for
   * @param options                   Addition FindListOptions
   */
  byAuthorityField(authorityId: string, options: FindListOptions = {}): Observable<RemoteData<PaginatedList<AtmireAuthorityDetailModel>>> {
    const findAllOptions = Object.assign({}, options);
    const requestParam = new RequestParam('authority', authorityId);
    if (hasValue(findAllOptions.searchParams)) {
      findAllOptions.searchParams.push(requestParam);
    } else {
      findAllOptions.searchParams = [requestParam];
    }
    return this.searchBy('byAuthority', findAllOptions);
  }


}

/* tslint:enable:max-classes-per-file */

import { isNotEmpty } from '../shared/empty.util';
import { Context } from '../core/shared/context.model';
import { AtmireSavedItemListTypes } from './atmire-saved-item-list-types';

/**
 * Create URL arguments for the SavedItemLists projection
 * @param listIDs Array of list IDs to create a projection for
 */
export function createSavedItemListsProjectionArgs(...listIDs: string[]): string[] {
  const args = [];
  if (isNotEmpty(listIDs)) {
    listIDs.forEach((listID) => {
      if (isNotEmpty(listID)) {
        args.push(`savedList=${listID}`);
      }
    });
  }
  if (isNotEmpty(args)) {
    args.push('projection=SavedItemLists');
  }
  return args;
}

/**
 * Check if a URL contains the SavedItemLists projection
 * @param href  URL to check
 */
export function hrefContainsSavedItemListsProjection(href: string): boolean {
  return href.indexOf('projection=SavedItemLists') > -1;
}

export function getAtmireSavedListName(context: Context) {
  let listName = AtmireSavedItemListTypes.Default;
  if (context === Context.Workspace) {
    listName = AtmireSavedItemListTypes.Workspace;
  } else if (context === Context.Workflow) {
    listName = AtmireSavedItemListTypes.Workflow;
  }
  return listName;
}

import { autoserializeAs } from 'cerialize';

export class UsageStatisticsHeader {
  @autoserializeAs('headerCategory')
  category: string;

  @autoserializeAs('headerType')
  type: string;

  @autoserializeAs('headerId')
  id: string;

  @autoserializeAs('headerName')
  name: string;
}

<h2 class="item-page-title-field">
  {{'orgunit.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['organization.legalName'])"></ds-metadata-values>
</h2>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper>
      <ds-thumbnail [thumbnail]="object?.thumbnail | async" [defaultImage]="'assets/images/orgunit-placeholder.svg'"></ds-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-generic-item-page-field [item]="object"
      [fields]="['organization.foundingDate']"
      [label]="'orgunit.page.dateestablished'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['organization.address.addressLocality']"
      [label]="'orgunit.page.city'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['organization.adress.addressCountry']"
      [label]="'orgunit.page.country'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.identifier']"
      [label]="'orgunit.page.id'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isPublicationOfOrgUnit'"
      [label]="'relationships.isPublicationOf' | translate">
    </ds-related-items>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'orgunit.page.description'">
    </ds-generic-item-page-field>
    <ds-related-items
            [parentItem]="object"
            [relationType]="'isOrgUnitOfRoot'"
            [label]="'relationships.isOrgUnitOfRoot' | translate">
    </ds-related-items>
    <div>
      <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.license-files" bundle="LICENSE" [item]="object"></ds-item-page-file-section>
      <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.administration-files" bundle="ADMINISTRATION" [item]="object"></ds-item-page-file-section>

      <a class="btn btn-outline-primary" [routerLink]="['/items/' + object.id + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isOrgUnitOfPerson',
                                          filter: 'isOrgUnitOfPerson',
                                          configuration: 'person'
                                        },
                                        {
                                          label: 'isOrgUnitOfProject',
                                          filter: 'isOrgUnitOfProject',
                                          configuration: 'project'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>

import { autoserialize } from 'cerialize';

export class UsageStatisticsTableGroupingConfig {
  @autoserialize
  group: string;

  @autoserialize
  category: string;

  @autoserialize
  groupType: string;

  @autoserialize
  valueType: string;

  @autoserialize
  parameters: string[];
}

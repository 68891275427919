import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../../core/shared/item.model';
import { AlertType } from '../../alert/aletr-type';
import { Observable, of as observableOf } from 'rxjs';
import { TakedownService } from '../../../core/data/takedown.service';
import { getItemTakeDownPath } from '../../../item-page/item-page-routing-paths';

@Component({
  selector: 'ds-item-alerts',
  templateUrl: './item-alerts.component.html',
  styleUrls: ['./item-alerts.component.scss']
})
/**
 * Component displaying alerts for an item
 */
export class ItemAlertsComponent implements OnInit {
  /**
   * The Item to display alerts for
   */
  @Input() item: Item;

  /**
   * Whether or not the "Taken down" alert should be displayed for this listable object
   */
  itemIsTakenDown$: Observable<boolean> = observableOf(false);
  itemTakenDownPath: string;

  /**
   * The AlertType enumeration
   * @type {AlertType}
   */
  public AlertTypeEnum = AlertType;

  constructor(private takedownService: TakedownService) {}

  /**
   * Setup the dynamic child component
   */
  ngOnInit(): void {
    if (this.item && this.item.isWithdrawn) {
      this.itemIsTakenDown$ = this.takedownService.isTakenDown(this.item.id);
      this.itemTakenDownPath = getItemTakeDownPath(this.item.id);
    }
  }

}

<div class="item-page-graphic" [style.background-image]="'url(/assets/images/item-view-page-header-graphic-left-aligned.png)'">
  <div class="item-page-header d-flex flex-column justify-content-end">
    <div class="container">
      <div class="d-flex flex-row">
        <h2 class="item-page-title-field mr-auto">
          <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
        </h2>
        <div class="pl-2 d-flex flex-row">
          <ds-atmire-saved-item-list-control [item]="object"></ds-atmire-saved-item-list-control>
          <ds-dso-page-edit-button class="pl-1" [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="item-page-content-a">
  <div class="container">
    <div class="row">
      <div class="item-page-left col-xs-12 col-md">
        <a *ngIf="hasFiles() | async" [routerLink]=""
           (click)="onClick('file-section')"
           class="btn btn-lg btn-dark download-button mb-3"
        >
          <img src="assets/images/files-download.svg" alt="">
          {{"item.page.link.download" | translate}}
        </a>
        <ng-container *ngIf="!mediaViewer.image">
          <ds-item-page-rights-field [item]="object"
                                     [fields]="['dcterms.accessRights', 'unsw.description.embargoNote', 'unsw.description.publisherLicense', 'dcterms.rights', 'dcterms.rightsHolder']"
                                     [label]="'item.page.accessTerms'">
          </ds-item-page-rights-field>
          <ds-item-page-uri-separate-value-field [item]="object"
                                                 [fieldURI]="'dc.rights.ur'"
                                                 [fieldValue]="'dc.rights'"
                                                 [label]="">
          </ds-item-page-uri-separate-value-field>
        </ng-container>
        <ng-container *ngIf="mediaViewer.image">
          <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
        </ng-container>
        <div class="mb-2" [class.d-none]="!showAltmetricDonut">
          <ds-altmetric-donut (altMetricDonutRenderedEmitter)="showAltmetricDonut = $event[0]"
                              [doiIdentifierMdFields]="['unsw.identifier.doi', 'unsw.identifier.doiPublisher']"
                              [smallView]="false"
                              [item]="object">
          </ds-altmetric-donut>
        </div>
        <div class="mb-2">
          <ds-atmire-cua-statlets-show-hide-button (show)="statletsSectionRef.nativeElement.scrollIntoView({ behavior: 'smooth' })">
          </ds-atmire-cua-statlets-show-hide-button>
        </div>
      </div>
      <div class="col-xs-12 col-md">
        <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['dc.description']"
                                    [label]="'item.page.description'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['dc.identifier.citation']"
                                    [label]="'item.page.citation'">
        </ds-generic-item-page-field>
        <ds-item-page-uri-field [item]="object"
                                [fields]="['dc.identifier.uri']"
                                [label]="'item.page.uri'">
        </ds-item-page-uri-field>
      </div>
    </div>
  </div>
</div>
<div class="item-page-content-b">
  <div class="container">
    <div class="row">
      <div class="item-page-left col-xs-12 col-md">
        <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
        <ds-item-page-author-field [item]="object"></ds-item-page-author-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['journal.title']"
                                    [label]="'item.page.journal-title'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['journal.identifier.issn']"
                                    [label]="'item.page.journal-issn'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['journalvolume.identifier.name']"
                                    [label]="'item.page.volume-title'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object"
                                    [fields]="['dc.publisher']"
                                    [label]="'item.page.publisher'">
        </ds-generic-item-page-field>
      </div>
      <div class="col-xs-12 col-md">
        <ds-metadata-representation-list
          [parentItem]="object"
          [itemType]="'Person'"
          [metadataFields]="['dc.contributor.author']"
          [label]="'relationships.isAuthorOf' | translate">
        </ds-metadata-representation-list>

        <ds-generic-item-page-field [item]="object"
                                    [fields]="['dc.subject']"
                                    [separator]="','"
                                    [label]="'item.page.subject'">
        </ds-generic-item-page-field>

        <ds-item-page-collections [item]="object"></ds-item-page-collections>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-4">
        <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
      </div>

      <div class="d-flex flex-row justify-content-center flex-nowrap flex-grow-0 w-100 mt-3 mb-5">
        <a class="btn btn-lg btn-dark mx-3" role="button" [routerLink]="[itemPageRoute + '/full']">
          {{"item.page.link.full" | translate}}
        </a>
        <a [routerLink]="[itemPageRoute + '/statistics']" class="btn btn-lg btn-outline-light mx-3 statistics-button-item-page"
           role="button">
          {{"item.page.link.statistics" | translate}}
        </a>
      </div>
    </div>
    <div class="row mt-4" #statletsSection>
      <div class="col-12">
        <ds-atmire-cua-statlets-section [dso]="object" [displayShowHideButton]="false"></ds-atmire-cua-statlets-section>
      </div>
    </div>
  </div>
</div>

import {
  AtmireSavedItemListAction,
  AtmireSavedItemListActionTypes, AtmireSavedItemListDeleteAction,
  AtmireSavedItemListSetAction
} from './atmire-saved-item-list.actions';
import { hasValue, isNotEmpty } from '../../shared/empty.util';

/**
 * The current state of our item list
 * Contains the ID of the current list
 * Contains the ID of the user that belongs to the current list (or null for anonymous). This is used to avoid REST calls
 * when the list ID is out of sync with the currently authenticated user, which would result in 404 errors.
 */
export interface AtmireSavedItemListState {
  currentLists?: {
    [key: string]: string
  };
  currentUserID?: string;
}

/**
 * Actions to perform on the {@link AtmireSavedItemListState}
 * @param state
 * @param action
 */
export function atmireSavedItemListReducer(state: AtmireSavedItemListState = {}, action: AtmireSavedItemListAction): AtmireSavedItemListState {
  switch (action.type) {
    case AtmireSavedItemListActionTypes.SET: {
      let lists;
      if (hasValue(state.currentLists)) {
        lists = Object.assign({}, state.currentLists);
        lists[(action as AtmireSavedItemListSetAction).name] = (action as AtmireSavedItemListSetAction).id;
      } else {
        lists = {[(action as AtmireSavedItemListSetAction).name]: (action as AtmireSavedItemListSetAction).id};
      }
      const userId = (action as AtmireSavedItemListSetAction).userId;
      return Object.assign({}, state, {
        currentLists: lists,
        currentUserID: userId === undefined ? state.currentUserID : userId,
      });
    }
    case AtmireSavedItemListActionTypes.CLEAR: {
      return Object.assign({}, state, {currentLists: {}});
    }
    case AtmireSavedItemListActionTypes.DELETE: {
      let lists = Object.assign({});
      if (hasValue(state.currentLists)) {
        lists = Object.assign({}, state.currentLists);
        const listId = (action as AtmireSavedItemListDeleteAction).id;
        let listName = null;
        Object.entries(lists).forEach(([name, id]) => {
          if (id === listId) {
            listName = name;
          }
        });
        if (isNotEmpty(listName)) {
          delete lists[listName];
        }
      }
      return Object.assign({}, state, {
        currentLists: lists,
      });
    }
    default: {
      return state;
    }
  }
}

import { FormFieldMetadataValueObject } from '../../models/form-field-metadata-value.model';

export class AtmireSearchEditValueAction {
  value: FormFieldMetadataValueObject;
  index: number;

  constructor(value: FormFieldMetadataValueObject, index: number) {
    this.value = value;
    this.index = index;
  }
}

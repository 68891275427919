import { ResourceType } from '../../core/shared/resource-type';
/**
 * The resource type for UsageStatisticsTableModel.
 *
 * Needs to be in a separate file to prevent circular
 * dependencies in webpack.
 */

export const USAGE_STATISTICS_TABLE = new ResourceType('usagestatisticstable');
export const USAGE_STATISTICS_TABLE_CONFIG = new ResourceType('usagestatisticstableconfig');

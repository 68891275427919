import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNotEmpty } from '../../../shared/empty.util';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ItemOperation } from './itemOperation.model';

@Component({
  selector: 'ds-item-operation',
  templateUrl: './item-operation.component.html'
})
/**
 * Operation that can be performed on an item
 */
export class ItemOperationComponent {

  @Input() operation: ItemOperation;

  constructor(protected notificationsService: NotificationsService,
              protected translateService: TranslateService) {
  }

  showDisabledReasonNotification(operation: ItemOperation) {
    if (isNotEmpty(operation.disabledReasonLabel)) {
      this.notificationsService.error(this.translateService.get(operation.disabledReasonLabel));
    }
  }

}

<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ 'atmire.submission.search-modal.title.' + model.name | translate }}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ul ngbNav #nav="ngbNav" class="nav-tabs mb-2" [activeId]="initialTab">
    <li ngbNavItem>
      <a ngbNavLink>{{ 'atmire.submission.search-modal.tabs.local-authority' | translate:{ count: localAuthoritiesCount$ | async } }}</a>
      <ng-template ngbNavContent>
        <ds-atmire-search-local-authority-tab [query]="query"
                                              [selectedValues]="selectedValues"
                                              [model]="model"
                                              [clickedValue]="clickedValue"
                                              (selectObject)="select($event)"
                                              (editObject)="edit($event)"
                                              (deselectObject)="deselect($event)">
        </ds-atmire-search-local-authority-tab>
      </ng-template>
    </li>
    <li ngbNavItem *ngFor="let externalSource of model.externalSources" [domId]="externalSource">
      <a ngbNavLink>{{ ('atmire.submission.search-modal.tabs.external-source.' + externalSource) | translate:{ count: (externalSourceCount$[externalSource] | async) } }}</a>
      <ng-template ngbNavContent>
        <ds-atmire-search-external-source-tab [externalSource]="externalSource"
                                              [query]="query"
                                              [selectedValues]="selectedValues"
                                              [clickedValue]="clickedValue"
                                              [model]="model"
                                              (selectObject)="select($event)"
                                              (editObject)="edit($event)"
                                              (deselectObject)="deselect($event)">
        </ds-atmire-search-external-source-tab>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger mr-auto" (click)="modal.close()">{{ ('atmire.submission.search-modal.buttons.cancel' | translate) }}</button>
</div>

import { Component, Input } from '@angular/core';
import {
  MetadataRepresentationListComponent
} from '../../../../../../app/item-page/simple/metadata-representation-list/metadata-representation-list.component';

@Component({
  selector: 'ds-apa-metadata-representation-list',
  templateUrl: './apa-metadata-representation-list.component.html',
  styleUrls: ['./apa-metadata-representation-list.component.scss']
})
export class ApaMetadataRepresentationListComponent extends MetadataRepresentationListComponent {

  @Input() separator: string;

  @Input() maxAmount = 10;

  // Needs to be set to a high number because otherwise the last item won't be retrieved due to pagination
  incrementBy = 1000;

}

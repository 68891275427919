<div class="container">
    <div class="row">
        <div class="col-12">
            <h2>{{'item.edit.take-down.head' | translate: {id: (itemRD$ | async)?.payload?.handle} }}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="reason">{{'item.edit.take-down.label' | translate}}</label>
            <textarea [(ngModel)]="reason" id="reason" class="form-control"></textarea>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <button (click)="submit()" class="btn btn-primary">
                {{'item.edit.take-down.submit' | translate}}
            </button>
            <button [routerLink]="['/items/', (itemRD$ | async)?.payload?.id, 'edit']"
                    class="btn btn-outline-secondary">
                {{'item.edit.take-down.cancel' | translate}}
            </button>       
        </div>
    </div>




</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AtmireSavedItemListLinkComponent } from './atmire-saved-item-list-link/atmire-saved-item-list-link.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    AtmireSavedItemListLinkComponent,
  ],
  exports: [
    AtmireSavedItemListLinkComponent,
  ],
})
export class AtmireSavedItemListOtherModule {

}

<div class="show-more">
  <ds-atmire-object-collection [objects$]="results$"
                               [error$]="error$"
                               [isLoading$]="isLoading$"
                               [viewMode]="viewMode"
                               [context]="context"
                               [linkType]="linkType"
                               [noResultsMsg]="noResultsMsg"
  ></ds-atmire-object-collection>
  <div *ngIf="!(isLoading$ | async) && !(error$ | async) && (results$ | async).length"
       class="row show-more-buttons ml-0"
  >
    <div *ngIf="!isLastPage"
         class="mt-1 mr-1">
      <a class="btn btn-outline-secondary btn-sm aoc-show-more"
         href="javascript:void(0);"
         (click)="showMore()">{{'atmire.object-collection.button.show-more' | translate}}</a>
    </div>
    <div *ngIf="currentPage != 1"
         class="mt-1 mr-1">
      <a class="btn btn-outline-secondary btn-sm aoc-collapse"
         href="javascript:void(0);"
         (click)="collapse()">{{'atmire.object-collection.button.collapse' | translate}}</a>
    </div>
  </div>
</div>

<div class="area-chart">
  <ngx-charts-area-chart-stacked *ngIf="data$ | async"
                         [results]="data$ | async"
                         [view]="view"
                         [xAxis]="true"
                         [yAxis]="true"
                         [xAxisLabel]="'statlets.type.' + statlet.shortName + '.x-axis' | translate"
                         [yAxisLabel]="'statlets.type.' + statlet.shortName + '.y-axis' | translate"
                         [showXAxisLabel]="true"
                         [showYAxisLabel]="true"
                         [scheme]="colors$ | async"
  ></ngx-charts-area-chart-stacked>
</div>

<footer>
  <div class="unsw-top-footer">
    <div class="container unsw-footer-partners py-5">
      <div class="row d-flex justify-content-around align-items-baseline m-n4">
        <a href="https://go8.edu.au/" target="_blank" class="m-4" [attr.aria-label]="'unsw.partner.go8' | translate">
          <img src="assets/images/logo-go8.svg" class="my-auto"
               [alt]="'unsw.footer.partner-logo-alt' | translate: { partner: ('unsw.partner.go8' | translate) }"/>
        </a>
        <a href="https://universitas21.com/" target="_blank" class="m-4" [attr.aria-label]="'unsw.partner.u21' | translate">
          <img src="assets/images/logo-u21.svg" class="my-auto u21-logo"
               [alt]="'unsw.footer.partner-logo-alt' | translate: { partner: ('unsw.partner.u21' | translate) }"/>
        </a>
        <a href="https://apru.org/" target="_blank" class="m-4" [attr.aria-label]="'unsw.partner.apru' | translate">
          <img src="../../../../assets/images/logo-apru.svg"
               [alt]="'unsw.footer.partner-logo-alt' | translate: { partner: ('unsw.partner.apru' | translate) }"
          />
        </a>
        <a href="http://globaltechalliance.org/" target="_blank" class="m-4" [attr.aria-label]="'unsw.partner.gatu' | translate">
          <img src="assets/images/logo-gatu.svg" class="my-auto gatu-logo"
               [alt]="'unsw.footer.partner-logo-alt' | translate: { partner: ('unsw.partner.gatu' | translate) }"
          />
        </a>
        <a href="https://www.plusalliance.org/" target="_blank" class="m-4" [attr.aria-label]="'unsw.partner.plus' | translate">
          <img src="assets/images/logo-plus.svg" class="my-auto"
               [alt]="'unsw.footer.partner-logo-alt' | translate: { partner: ('unsw.partner.plus' | translate) }"
          />
        </a>
      </div>
    </div>
  </div>
  <div class="unsw-bottom-footer">
    <div class="container pt-4">
      <div class="row">
        <div class="col-12 col-sm-6 unsw-footer-left-column">
          <a href="https://www.library.unsw.edu.au/" target="_blank" class="unsw-link mx-auto" [attr.aria-label]="'unsw.footer.links.website-library' | translate">
            <img src="assets/images/unsw_0.png" [alt]="'unsw.footer.logo-alt' | translate"/>
            <p class="w-100 mt-2">
              library.unsw.edu.au
            </p>
          </a>
          <p class="w-100">
            {{ 'unsw.footer.address.line1' | translate }}<br/>
            {{ 'unsw.footer.address.line2' | translate }}<br/>
            {{ 'unsw.footer.telephone' | translate: { number: '+61 2 9065 9444' } }}
          </p>
          <p class="w-100">
            {{ 'unsw.footer.info.cricos' | translate: { code: '00098G'} }}<br/>
            {{ 'unsw.footer.info.teqsa' | translate: { id: 'PRV12055' } }}<br/>
            {{ 'unsw.footer.info.abn' | translate: { number: '57 195 873 179' } }}
          </p>
          <p class="w-100">
            {{ 'unsw.footer.authorised' | translate }}
          </p>
        </div>
        <div class="col-12 col-sm-6 unsw-footer-right-column text-monospace">
          <div class="row unsw-flags d-flex flex-row flex-shrink-1 flex-grow-1 flex-nowrap">
            <img src="assets/images/flag-aboriginal.svg"
                 [alt]="'unsw.footer.flag-alt' | translate: { peoples: ('unsw.peoples.aboriginals' | translate) }"/>
            <img src="assets/images/flag-torres-strait-islanders.svg"
                 [alt]="'unsw.footer.flag-alt' | translate: { peoples: ('unsw.peoples.torres-strait-islanders' | translate) }"/>
          </div>
          <h4 class="my-3">
            {{ 'unsw.footer.acknowledgement.head' | translate }}
          </h4>
          <p>
            {{ 'unsw.footer.acknowledgement.body' | translate }}
          </p>
          <a href="https://ulurustatement.org/the-statement" target="_blank"
             [attr.aria-label]="'unsw.footer.acknowledgement.uluru-statement' | translate">
            {{ 'unsw.footer.acknowledgement.uluru-statement' | translate }}
          </a>
        </div>
      </div>
      <hr class="m-0"/>
      <div class="row">
        <div class="container">
          <div class="unsw-social float-left d-flex flex-row flex-wrap justify-content-start align-items-center">
            <span>{{ 'unsw.footer.social.follow-us' | translate }}</span>
            <a href="https://www.facebook.com/UNSWLibrary/" target="_blank" [attr.aria-label]="'unsw.footer.social.facebook' | translate">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com/UNSWLibrary" target="_blank" [attr.aria-label]="'unsw.footer.social.twitter' | translate">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com/unswlibrary/" target="_blank" [attr.aria-label]="'unsw.footer.social.instagram' | translate">
              <i class="fab fa-instagram"></i>
            </a>
          </div>
          <div class="unsw-links float-right d-flex flex-row flex-wrap justify-content-end align-content-center my-auto h-100">
            <a href="https://www.library.unsw.edu.au/contact-us" target="_blank" [attr.aria-label]="'unsw.footer.links.contact' | translate">
              {{ 'unsw.footer.links.contact' | translate }}
            </a>
            <a href="https://www.unsw.edu.au/privacy" target="_blank" [attr.aria-label]="'unsw.footer.links.privacy' | translate">
              {{ 'unsw.footer.links.privacy' | translate }}
            </a>
            <a href="https://www.unsw.edu.au/copyright-disclaimer" target="_blank" [attr.aria-label]="'unsw.footer.links.copyright' | translate">
              {{ 'unsw.footer.links.copyright' | translate }}
            </a>
            <a href="https://www.unsw.edu.au/accessibility" target="_blank" [attr.aria-label]="'unsw.footer.links.accessibility' | translate">
              {{ 'unsw.footer.links.accessibility' | translate }}
            </a>
            <a href="https://unswinsight.microsoftcrmportals.com/library-ask-us/?topic=general" target="_blank" [attr.aria-label]="'unsw.footer.links.feedback' | translate">
              {{ 'unsw.footer.links.feedback' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

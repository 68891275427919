import { DSpaceObject } from '../../../core/shared/dspace-object.model';
import { link, typedObject } from '../../../core/cache/builders/build-decorators';
import { autoserialize, deserialize, inheritSerialization } from 'cerialize';
import { StorageReportStatistic } from './storage-report-statistic.model';
import { STORAGE_REPORT } from './storage-report.resource-type';
import { HALLink } from '../../../core/shared/hal-link.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../core/data/remote-data';
import { ITEM } from '../../../core/shared/item.resource-type';
import { EPerson } from '../../../core/eperson/models/eperson.model';
import { EPERSON } from '../../../core/eperson/models/eperson.resource-type';
import { COLLECTION } from '../../../core/shared/collection.resource-type';
import { HALResource } from '../../../core/shared/hal-resource.model';

/**
 * Class representing a DSpace StorageReport
 */
@typedObject
@inheritSerialization(HALResource)
export class StorageReport extends HALResource {

  static type = STORAGE_REPORT;

  type = STORAGE_REPORT;
  /**
   * The identifier of this StorageReport
   */
  @autoserialize
  id: string;

  /**
   * List of statistics containing information about the StorageReport
   */
  @autoserialize
  statistics: StorageReportStatistic[];

  @deserialize
  _links: {
    self: HALLink;
    dspaceObject: HALLink;
    submitter: HALLink;
    collection: HALLink;
  };

  /**
   * The DSpaceObject this StorageReport contains statistics for
   */
  @link(ITEM)
  dspaceObject?: Observable<RemoteData<DSpaceObject>>;

  /**
   * The EPerson linked to this StorageReport
   */
  @link(EPERSON)
  submitter?: Observable<RemoteData<EPerson>>;

  /**
   * The Collection linked to this StorageReport
   */
  @link(COLLECTION)
  collection?: Observable<RemoteData<EPerson>>;
}

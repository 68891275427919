import { Component, Inject, OnInit } from '@angular/core';
import { LinkMenuItemModel } from './models/link.model';
import { MenuItemType } from '../initial-menus-state';
import { rendersMenuItemForType } from '../menu-item.decorator';
import { isNotEmpty } from '../../empty.util';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { MenuItemComponent } from './menu-item.component';
import { ExternalLinkMenuItemModel } from './models/external-link.model';

/**
 * Component that renders a menu section of type EXTERNAL
 */
@Component({
    selector: 'ds-external-link-menu-item',
    templateUrl: './external-link-menu-item.component.html'
})
@rendersMenuItemForType(MenuItemType.EXTERNAL)
export class ExternalLinkMenuItemComponent extends MenuItemComponent<ExternalLinkMenuItemModel> implements OnInit {
    hasLink: boolean;

    constructor(
        @Inject('itemModelProvider') item: ExternalLinkMenuItemModel,
        public router: Router
    ) {
        super(item, router);
    }

    ngOnInit() {
        super.ngOnInit();
        this.hasLink = isNotEmpty(this.item.href);
    }

    getHref() {
        if (this.hasLink) {
            return this.item.href;
        }
        return undefined;
    }

}

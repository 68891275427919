import { TypedObject } from '../../core/cache/object-cache.reducer';
import { typedObject } from '../../core/cache/builders/build-decorators';
import { excludeFromEquals } from '../../core/utilities/equals.decorators';
import { autoserialize, deserialize } from 'cerialize';
import { ResourceType } from '../../core/shared/resource-type';
import { ATMIRE_SAVED_ITEM_LIST } from './atmire-saved-item-list.resource-type';
import { HALLink } from '../../core/shared/hal-link.model';

/**
 * Class object representing a saved item list
 */
@typedObject
export class AtmireSavedItemList implements TypedObject {
  static type = ATMIRE_SAVED_ITEM_LIST;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  /**
   * Unique identifier for this list
   */
  @autoserialize
  id: string;

  /**
   * The name of the list
   */
  @autoserialize
  name: string;

  /**
   * Whether or not this list is public
   */
  @autoserialize
  public: boolean;

  @deserialize
  _links: {
    self: HALLink;
    items: HALLink;
    search: HALLink;
  };
}

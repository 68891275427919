import { Component } from '@angular/core';
import { ItemComponent } from '../../../../../../../app/item-page/simple/item-types/shared/item.component';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { getFirstSucceededRemoteDataPayload } from '../../../../../../../app/core/shared/operators';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@listableObjectComponent('OrgUnit', ViewMode.StandalonePage, Context.Any, 'unsw')
@Component({
  selector: 'ds-org-unit-unsw',
  styleUrls: ['./org-unit.component.scss'],
  templateUrl: './org-unit.component.html'
})
/**
 * The component for displaying metadata and relations of an item of the type Organisation Unit
 */
export class OrgUnitComponent extends ItemComponent {

  /**
   * The banner image for this OrgUnit
   *   → If OrgUnit has no banner: return null
   *   → If OrgUnit has a banner: return <banner bitstream>._links.content.href
   */
  get bannerSrc(): Observable<string> {
    return this.bundleService.findByItemAndName(this.object, 'COVER').pipe(
      getFirstSucceededRemoteDataPayload(),
      switchMap(bundle => this.bitstreamDataService.findAllByBundle(bundle)),
      getFirstSucceededRemoteDataPayload(),
      map(bitstreams => bitstreams.page.length > 0 ?
        bitstreams.page[0]._links.content.href : null
      ),
    );
  }
}

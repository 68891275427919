import { equalsByFields, EquatableObject } from '../../../core/utilities/equatable';
import { isNotEmpty } from '../../../shared/empty.util';
import { Params } from '@angular/router';

export const REPORT_FILTER_VALUE_SEPARATOR = '||';

export class ReportConfigFilter {
  [key: string]: string[];
}

/* tslint:disable:max-classes-per-file */
export abstract class ReportConfig<T extends ReportConfig<T>> extends EquatableObject<T> {
  filters?: ReportConfigFilter = new ReportConfigFilter();

  /**
   * Transform this configuration into a search link containing all options as parameters
   * @param href
   */
  abstract toSearchHref(href: string): string;

  /**
   * Transform this configuration into a link containing all options as parameters
   * @param href
   * @param params
   */
  protected toHrefWithParams(href: string, params: Params = {}): string {
    return `${href}${this.toQueryParamString(params)}`;
  }

  /**
   * Transform parameters and filters into a URL query params string
   * @param params
   */
  protected toQueryParamString(params: Params): string {
    const filterParams = [];
    if (isNotEmpty(this.filters)) {
      Object.entries(this.filters).forEach(([key, values]: [string, string[]]) => {
        filterParams.push(key + '=' + values.join(REPORT_FILTER_VALUE_SEPARATOR));
      });
    }
    if (isNotEmpty(filterParams) || isNotEmpty(Object.keys(params))) {
      return '?' + [...Object.entries(params).map(([key, value]: [string, string]) => key + '=' + value), ...filterParams].join('&');
    } else {
      return '';
    }
  }

  /**
   * Compare two report configurations
   * Includes the filters
   * @param other
   */
  equals(other: T): boolean {
    const thisFilterKeys = Object.keys(this.filters);
    const otherFilterKeys = Object.keys(other.filters);
    if (thisFilterKeys.length !== otherFilterKeys.length || !equalsByFields(this.filters, other.filters, thisFilterKeys)) {
      return false;
    }
    return super.equals(other);
  }
}
/* tslint:enable:max-classes-per-file */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Site } from '../core/shared/site.model';
import { ConfigurationDataService } from '../core/data/configuration-data.service';
import { RemoteData } from '../core/data/remote-data';
import { ConfigurationProperty } from '../core/shared/configuration-property.model';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { Subscription } from 'rxjs/internal/Subscription';
import { hasValue } from '../shared/empty.util';

@Component({
  selector: 'ds-home-page',
  styleUrls: ['./home-page.component.scss'],
  templateUrl: './home-page.component.html',
})
export class HomePageComponent implements OnInit, OnDestroy {

  site$: Observable<Site>;

  /**
   * Boolean indicating whether or not there is an orgUnit community to render items of
   */
  hasOrgUnitCommunityConfig$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * List of subscriptions
   */
  subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private configurationDataService: ConfigurationDataService
  ) {
  }

  ngOnInit(): void {
    this.site$ = this.route.data.pipe(
      map((data) => data.site as Site),
    );
    this.subs.push(this.configurationDataService.findByPropertyName('orgunit.community.uuid').pipe(
      catchError((err) => {
        console.log('Error: ', err);
        this.hasOrgUnitCommunityConfig$.next(false);
        return observableThrowError(err);
      }),
      map((rd: RemoteData<ConfigurationProperty>) => {
        if (rd.hasFailed) {
          this.hasOrgUnitCommunityConfig$.next(false);
        } else {
          if (rd.payload !== undefined && rd.payload.values.length > 0) {
            this.hasOrgUnitCommunityConfig$.next(true);
          } else {
            this.hasOrgUnitCommunityConfig$.next(false);
          }
        }
      })).subscribe());
  }

  /**
   * Unsub all subscriptions
   */
  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub: Subscription) => sub.unsubscribe());
  }

}

<ds-metadata-field-wrapper [label]="label">
  <ng-container *ngFor="let objectPage of objects; let i = index; let last = last">
    <ng-container *ngVar="(objectPage | async) as relatedItemPage">
      <div class="related-items-object">
          <ds-listable-object-component-loader *ngFor="let item of relatedItemPage?.items"
                                               [object]="item" [viewMode]="viewMode">
          </ds-listable-object-component-loader>
          <span *ngFor="let plainValue of relatedItemPage?.plain; let j = index;" class="plaintext-value" [ngClass]="plainClass">
            <span [innerHTML]="plainValue | linky"></span>
            <br>
          </span>
      </div>
      <ds-loading *ngIf="last && (isLoading$ | async)" message="{{'loading.default' | translate}}"></ds-loading>
      <div class="d-inline-block w-100 mt-2" *ngIf="last">
        <div *ngIf="relatedItemPage?.nextPageSize > 0" class="float-left" id="view-more">
          <a [routerLink]="" (click)="$event.preventDefault();increase()">{{'item.page.related-items.view-more' |
            translate:{ amount: relatedItemPage?.nextPageSize } }}</a>
        </div>
        <div *ngIf="objects.length > 1" class="float-right" id="view-less">
          <a [routerLink]="" (click)="$event.preventDefault();decrease()">{{'item.page.related-items.view-less' |
            translate:{ amount: relatedItemPage?.items.length + relatedItemPage?.plain.length } }}</a>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ds-metadata-field-wrapper>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../app/shared/shared.module';
import { HomeNewsComponent } from './app/home-page/home-news/home-news.component';
import { HeaderComponent } from './app/header/header.component';
import { NavbarComponent } from './app/navbar/navbar.component';
import { FooterComponent } from './app/footer/footer.component';
import { RootModule } from '../../app/root.module';
import { NavbarModule } from '../../app/navbar/navbar.module';
import { PublicationComponent } from './app/item-page/simple/item-types/publication/publication.component';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { DatasetSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/dataset/dataset-search-result-list-element.component';
import { DatasetListElementComponent } from './app/entity-groups/research-entities/item-list-elements/dataset/dataset-list-element.component';
import { ItemSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { ItemListElementComponent } from './app/shared/object-list/item-list-element/item-types/item/item-list-element.component';
import { OrgUnitSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/org-unit/org-unit-search-result-list-element.component';
import { OrgUnitListElementComponent } from './app/entity-groups/research-entities/item-list-elements/org-unit/org-unit-list-element.component';
import { OrgUnitComponent } from './app/entity-groups/research-entities/item-pages/org-unit/org-unit.component';
import { UntypedItemComponent } from './app/item-page/simple/item-types/untyped-item/untyped-item.component';
import { DatasetComponent } from './app/entity-groups/research-entities/item-pages/dataset/dataset.component';
import { AtmireAuthorityItemMetadataListElementComponent } from './app/core/atmire-authorities/atmire-authority/atmire-authority-item-metadata-list-element.component';
import { OrgUnitSearchResultGridElementComponent } from './app/entity-groups/research-entities/search-result-grid-elements/org-unit/org-unit-search-result-grid-element.component';
import { RootComponent } from './app/root/root.component';
import { HomePageComponent } from './app/home-page/home-page.component';
import { HomePageModule } from '../../app/home-page/home-page.module';
import { StatisticsModule } from '../../app/statistics/statistics.module';
import { SharedStatletsModule } from '../../app/atmire-cua/statlets/shared/shared-statlets.module';
import {
  ApaMetadataRepresentationListComponent
} from './app/item-page/simple/apa-metadata-representation-list/apa-metadata-representation-list.component';

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS.
 * This will ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  PublicationComponent,
  DatasetSearchResultListElementComponent,
  DatasetListElementComponent,
  ItemSearchResultListElementComponent,
  ItemListElementComponent,
  OrgUnitSearchResultListElementComponent,
  OrgUnitListElementComponent,
  OrgUnitComponent,
  UntypedItemComponent,
  DatasetComponent,
  AtmireAuthorityItemMetadataListElementComponent,
  OrgUnitSearchResultGridElementComponent,
];

const DECLARATIONS = [
  ...ENTRY_COMPONENTS,
  RootComponent,
  HeaderComponent,
  NavbarComponent,
  HomePageComponent,
  HomeNewsComponent,
  FooterComponent,
  ApaMetadataRepresentationListComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RootModule,
    HomePageModule,
    StatisticsModule,
    NavbarModule,
    ItemPageModule,
    SharedStatletsModule,
  ],
  declarations: DECLARATIONS,
  providers: [
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
})

/**
 * This module is included in the main bundle that gets downloaded at first page load. So it should
 * contain only the themed components that have to be available immediately for the first page load,
 * and the minimal set of imports required to make them work. Anything you can cut from it will make
 * the initial page load faster, but may cause the page to flicker as components that were already
 * rendered server side need to be lazy-loaded again client side
 *
 * Themed EntryComponents should also be added here
 */
export class EagerThemeModule {
}

<nav class="navbar navbar-light py-0" id="header-nav"
     role="navigation" [attr.aria-label]="'nav.main.description' |translate">
    <div class="container header-bottom text-monospace py-0 my-0">

        <div id="collapsingNav" class="navbar-expand-md py-0 my-0"
             [ngClass]="{'open': !(menuCollapsed | async)}"
             [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')">
            <ul class="navbar-nav py-0 my-0">
                <ng-container *ngFor="let section of (sections | async)">
                    <ng-container
                            *ngComponentOutlet="(sectionMap$ | async).get(section.id).component; injector: (sectionMap$ | async).get(section.id).injector;"></ng-container>
                </ng-container>
            </ul>
        </div>
        <h2 class="h4 header-label m-0 font-weight-normal d-block d-md-none">{{ 'unsw.header.library' | translate }}</h2>
        <div class="flex-grow-1 d-flex justify-content-end align-items-center" id="rightNav">
            <!--        <ds-search-navbar class="navbar-collapsed"></ds-search-navbar>-->
            <!--        <ds-lang-switch class="navbar-collapsed"></ds-lang-switch>-->
            <ds-search-navbar *ngIf="showSearchBar$ | async"></ds-search-navbar>
            <ds-themed-auth-nav-menu class="navbar-collapsed"></ds-themed-auth-nav-menu>
            <ds-impersonate-navbar class="navbar-collapsed"></ds-impersonate-navbar>
            <div class="pl-2 d-block d-md-none">
                <button class="navbar-toggler border-0 px-0 py-0 my-0" type="button" (click)="toggleNavbar()"
                        aria-controls="collapsingNav"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </div>
</nav>

import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SearchOptions } from '../../../../shared/search/search-options.model';
import { RemoteData } from '../../../../core/data/remote-data';
import { AtmireSavedItemList } from '../../../../atmire-saved-item-list/models/atmire-saved-item-list.model';
import {
  AtmireSavedItemListDataService,
  SavedItemListStorage
} from '../../../../atmire-saved-item-list/data-services/atmire-saved-item-list-data.service';
import { AuthService } from '../../../../core/auth/auth.service';
import { ScriptDataService } from '../../../../core/data/processes/script-data.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AtmireSelectiveExportDropdownComponent } from '../atmire-discovery-export-dropdown/atmire-selective-export-dropdown.component';
import {
  getFirstCompletedRemoteData,
  getFirstSucceededRemoteDataPayload
} from '../../../../core/shared/operators';
import { map, switchMap } from 'rxjs/operators';
import { AuthorizationDataService } from '../../../../core/data/feature-authorization/authorization-data.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { hasValue, hasValueOperator } from '../../../../shared/empty.util';

@Component({
  selector: 'ds-atmire-saved-item-list-selective-export-dropdown',
  templateUrl: './atmire-saved-item-list-selective-export-dropdown.component.html',
  styleUrls: ['../abstract-atmire-export-dropdown.component.scss']
})
/**
 * Component displaying a dropdown menu, listing export options for a discovery search
 */
export class AtmireSavedItemListSelectiveExportDropdownComponent extends AtmireSelectiveExportDropdownComponent implements OnDestroy {
  /**
   * THe prefix to use for i18n messages
   */
  @Input() msgPrefix = 'my-list';

  /**
   * The most recent item list retrieved from the store
   */
  myList$: BehaviorSubject<SavedItemListStorage> = new BehaviorSubject<SavedItemListStorage>(null);

  /**
   * The amount of items the user currently has selected in his list
   */
  count$: Observable<number>;

  /**
   * List of subscriptions to unsubscribe from on destroy
   */
  protected subs: Subscription[] = [];

  constructor(protected savedItemListService: AtmireSavedItemListDataService,
              protected scriptDataService: ScriptDataService,
              protected authService: AuthService,
              protected authorizationService: AuthorizationDataService,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService,
              protected router: Router) {
    super(scriptDataService, authService, authorizationService, notificationsService, translate, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.push(
      this.savedItemListService.getRecentList().pipe(
        switchMap((list) => {
          if (hasValue(list)) {
            return [list];
          } else {
            return this.savedItemListService.findMyList().pipe(
              getFirstSucceededRemoteDataPayload(),
              map((myList) => new SavedItemListStorage(myList.id, myList.name))
            );
          }
        })
      ).subscribe((list) => this.myList$.next(list))
    );
    this.count$ = this.myList$.pipe(
      hasValueOperator(),
      switchMap((list) => this.savedItemListService.countItemsByListId(list.id))
    );
  }

  /**
   * Add all the items present for the current discovery options
   * Display a success notification on success or an error notification on error
   */
  addAll() {
    if (hasValue(this.myList$.value)) {
      this.savedItemListService.findById(this.myList$.value.id).pipe(
        getFirstSucceededRemoteDataPayload(),
        switchMap((list) => this.savedItemListService.addDiscoveryItems(list, new SearchOptions(this.searchConfig))),
        getFirstCompletedRemoteData()
      ).subscribe((rd) => {
        if (rd.hasSucceeded) {
          this.notificationsService.success(
            this.translate.instant('atmire.selective-export-dropdown.my-list.option.add-all.success.title'),
            this.translate.instant('atmire.selective-export-dropdown.my-list.option.add-all.success.content')
          );
          this.router.navigateByUrl('/my-lists/' + this.myList$.value.name);
        } else if (rd.hasFailed) {
          this.notificationsService.error(
            this.translate.instant('atmire.selective-export-dropdown.my-list.option.add-all.error.title'),
            rd.errorMessage
          );
        }
      });
    }
  }

  /**
   * Unsubscribe from all subscriptions
   */
  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}

import { Component, Input } from '@angular/core';
import { AbstractIncrementalListComponent } from '../../abstract-incremental-list/abstract-incremental-list.component';
import { Item } from '../../../../core/shared/item.model';
import { BehaviorSubject, combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { FindListOptions } from '../../../../core/data/request.models';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { RelationshipService } from '../../../../core/data/relationship.service';
import { DSONameService } from '../../../../core/breadcrumbs/dso-name.service';
import { hasValue } from '../../../../shared/empty.util';
import { RemoteData } from '../../../../core/data/remote-data';
import { PaginatedList } from '../../../../core/data/paginated-list.model';
import { createSuccessfulRemoteDataObject$ } from '../../../../shared/remote-data.utils';
import { getFirstSucceededRemoteDataPayload } from '../../../../core/shared/operators';
import { map } from 'rxjs/operators';

/**
 * Interface for combined listable objects and plaintext entries in a single pagination
 */
interface RelatedItemPage {
  /**
   * The actual related Items on this page
   */
  items: Item[];
  /**
   * The plaintext values on this page
   */
  plain: string[];
  /**
   * The size of the next page. "Show more" should be disabled if this is equal to 0.
   */
  nextPageSize: number;
}

@Component({
  selector: 'ds-unsw-multi-relationship-related-items-temp',
  styleUrls: ['./unsw-multi-relationship-related-items-temp.component.scss'],
  templateUrl: './unsw-multi-relationship-related-items-temp.component.html'
})
/**
 * TEMP component to render relationships of 2 different labels on item under same
 * pagination
 */
export class UnswMultiRelationshipRelatedItemsTempComponent extends AbstractIncrementalListComponent<Observable<RelatedItemPage>> {
  /**
   * The parent of the list of related items to display
   */
  @Input() parentItem: Item;

  /**
   * The label of the relationship type to display
   * Used in sending a search request to the REST API
   */
  @Input() relationType: string;

  /**
   * The label of the relationship types to display (use either this or the single relationType)
   * Used in sending a search request to the REST API
   */
  @Input() relationTypes: string[];

  /**
   * The amount to increment the list by when clicking "view more"
   * Defaults to 5
   * The default can optionally be overridden by providing the limit as input to the component
   */
  @Input() incrementBy = 5;

  /**
   * Default options to start a search request with
   * Optional input
   */
  @Input() options = new FindListOptions();

  /**
   * An i18n label to use as a title for the list (usually describes the relation)
   */
  @Input() label: string;

  /**
   * If not null, any additional values of this field that are not shown as listable object related Items
   * will be shown as plain text below them.
   */
  @Input() plainField: string = null;

  /**
   * An optional list of classes to apply to plain text values
   */
  @Input() plainClass = '';

  private totalRelationships: number = null;
  private remainingPlainValuesSize: number = null;
  private allRelationshipNames: Set<string> = new Set<string>();

  /**
   * Whether to show the loading animation
   */
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * The view-mode we're currently on
   * @type {ViewMode}
   */
  viewMode = ViewMode.ListElement;

  allRelatedItems: Observable<Item[]>;

  constructor(
    public relationshipService: RelationshipService,
    public dsoNameService: DSONameService,
  ) {
    super();
  }

  getAllRelatedItems(page: number): Observable<Item[]> {
    // getRelatedItemsByLabel will fail if we request a page > totalPages, so we fake an empty RD$ in that case
    let relationshipsRD$s: Observable<RemoteData<PaginatedList<Item>> | null>[];
    if (this.totalRelationships === null || page <= Math.ceil(this.totalRelationships / this.incrementBy)) {
      relationshipsRD$s = this.relationTypes.map((relationType: string) => {
        return this.relationshipService.getRelatedItemsByLabel(this.parentItem, relationType, Object.assign(this.options, {
            elementsPerPage: Number.MAX_SAFE_INTEGER,
            currentPage: 1,
          })
        );
      });
    } else {
      relationshipsRD$s = [createSuccessfulRemoteDataObject$({ page: [] } as PaginatedList<Item>)];
    }

    // Combine the list of RemoteData<PaginatedList<Item> into one List
    const lists = relationshipsRD$s.map((relationshipsRD$: Observable<RemoteData<PaginatedList<Item>>>, i: number) => {
      return relationshipsRD$.pipe(
        getFirstSucceededRemoteDataPayload(),
        map((itemsPage: PaginatedList<Item>) => {
          return itemsPage.page;
        }));
    });
    const listOfItems: Observable<Item[]> = observableCombineLatest(...lists).pipe(
      map((listOfLists: Item[][]) => ([] as Item[]).concat(...listOfLists))
    );
    return listOfItems;
  }

  /**
   * Get a specific page
   * Can extend beyond the pagination of getRelatedItemsByLabel if {@link plainField} includes additional MDVs
   * @param page  The page to fetch
   */
  getPage(page: number): Observable<RelatedItemPage> {
    // Don't show loading animation on first render:
    this.isLoading$.next(page !== 1);

    if (!hasValue(this.relationTypes) || hasValue(this.relationType)) {
      this.relationTypes = [this.relationType];
    }

    if (this.allRelatedItems === undefined) {
      this.allRelatedItems = this.getAllRelatedItems(page);
    }

    return this.allRelatedItems.pipe(
      map((items: Item[]) => {
        this.isLoading$.next(false);

        // Keep track of the names of related Items
        for (const item of items) {
          this.allRelationshipNames.add(this.dsoNameService.getName(item));
        }
        this.totalRelationships = items.length;

        const startPosition = (page - 1) * this.incrementBy;
        const endPosition = Math.min(startPosition + this.incrementBy, items.length);
        items = items.slice(startPosition, endPosition);

        // If we have enough related Items for this page, don't bother with plaintext values
        const lastFullyRelatedItemsPage = Math.floor(this.totalRelationships / this.incrementBy);
        if (page < lastFullyRelatedItemsPage) {
          return {
            items, plain: [], nextPageSize: this.incrementBy  // here?
          } as RelatedItemPage;
        }

        // If the _next_ page may contain plaintext values, include them when counting the size of the next page
        const plainValues = this.getPlainTextValues();
        if (page === lastFullyRelatedItemsPage ) {
          const nextPageSize = Math.min(
            this.totalRelationships % this.incrementBy + this.remainingPlainValuesSize, this.incrementBy
          );
          // Assuming that plain values _must_ include all actual relationships
          return {
            items, plain: [], nextPageSize
          } as RelatedItemPage;
        } else {
          // Include plaintext values in the current page
          let plain: string[];

          // For all plain text values leading up to the current page, splice a fragment from the front of the list
          // Take into account that the first page may contain some non-plaintext values as well
          plain = plainValues.splice(0, this.incrementBy - (this.totalRelationships % this.incrementBy));
          for (let pageWithPlain = lastFullyRelatedItemsPage + 2; pageWithPlain <= page; pageWithPlain++) {
            // Subsequent pages contain only plaintext values
            plain = plainValues.splice(0, this.incrementBy);
          }
          return {
            items,
            plain,                                                           // the last fragment is the current page
            nextPageSize: Math.min(plainValues.length, this.incrementBy), // number of remaining plaintext values
          } as RelatedItemPage;
        }
      })
    );
  }

  private getPlainTextValues(): string[] {
    if (this.plainField === null) {
      this.remainingPlainValuesSize = 0;
      return [];
    }

    const allPlainValues = this.parentItem.allMetadataValues(this.plainField);

    const remainingPlainValues = allPlainValues.filter(mdv => !this.allRelationshipNames.has(mdv));

    if (this.remainingPlainValuesSize === null) {
      this.remainingPlainValuesSize = remainingPlainValues.length;
    }

    // Filter out plain text values that are already shown as actual related Items
    return remainingPlainValues;
  }
}

<svg>
<rect
  *ngFor="let c of emptySlots; trackBy: trackBy"
  class="card-empty"
  [attr.x]="c.x"
  [attr.y]="c.y"
  [style.fill]="emptyColor"
  [attr.width]="c.width"
  [attr.height]="c.height"
  rx="3"
  ry="3"
></rect>
<g *ngFor="let c of cards; trackBy: trackBy">
  <g [attr.transform]="'translate(' + c.x + ',' + c.y + ')'" class="cell" (click)="onClick(c.data)">
    <rect class="card" [style.fill]="c.color" [attr.width]="c.width" [attr.height]="c.height" rx="3" ry="3" />
    <title>{{ c.label }}</title>
    <text
      #textEl
      class="value-text"
      [attr.x]="cardX"
      [attr.y]="cardY"
      [style.fill]="textColor"
      text-anchor="start"
      alignment-baseline="hanging"
      [style.font-size.pt]="20"
    >
      {{ c.data.value }}
    </text>
  </g>
</g>
</svg>

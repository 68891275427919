import { SearchOptions } from '../shared/search/search-options.model';
import { AbstractListableObjectSourceModel, AbstractSourceKwargs } from './sources/abstract-listable-object-source';
import { AtmireHrefListableObjectSource } from './sources/atmire-href-listable-object-source';
import { AtmireSearchListableObjectSource } from './sources/atmire-search-listable-object-source';

// tslint:disable:max-classes-per-file

/**
 * An enum listing the available implementations of {@link AbstractListableObjectSource}.
 * When defining a new object source, make sure to include add an entry to this enum
 * and use that entry as the source value of its model.
 */
export enum ListableObjectSourceType {
  HREF, SEARCH,
}

/**
 * Resolves to an {@link AtmireHrefListableObjectSource}, which retrieves objects via {@link HrefOnlyDataService}.
 */
export class AtmireHrefListableObjectSourceModel extends AbstractListableObjectSourceModel {
  readonly source = ListableObjectSourceType.HREF;

  constructor(public href: string, kwargs?: AbstractSourceKwargs) {
    super(kwargs);
  }
}

/**
 * Resolves to an {@link AtmireHrefListableObjectSource}, which retrieves objects via {@link SearchService}.
 */
export class AtmireSearchListableObjectSourceModel extends AbstractListableObjectSourceModel {
  readonly source = ListableObjectSourceType.SEARCH;

  constructor(
    public searchOptions: Partial<SearchOptions>,
    public displayAsSearchResults = true,
    kwargs?: AbstractSourceKwargs
  ) {
    super(kwargs);
  }
}

/**
 * All available implementations of {@link AbstractListableObjectSourceModel}.
 * When defining a new object source, make sure to include it in this type.
 */
export type ListableObjectSourceModel
  = AtmireHrefListableObjectSourceModel
    | AtmireSearchListableObjectSourceModel;

/**
 * All available implementations of {@link AbstractListableObjectSource}.
 * When defining a new object source, make sure to include it in this type.
 */
export type ListableObjectSource
  = AtmireHrefListableObjectSource
    | AtmireSearchListableObjectSource;

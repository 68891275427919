import { isNotEmpty } from '../../shared/empty.util';

/**
 * Class representing an export option on the my-list page
 */
export class AtmireSelectiveExportOption {
  /**
   * The label to display for the export option
   */
  label: string;

  /**
   * The export format
   * This should be unique, as it's used to identify the export format
   */
  format: string;

  /**
   * A custom filename to export with
   * This is optional and will default to "export" if left empty
   */
  fileName?: string;

  /**
   * The extension of the export format
   * When not specifically provided in the constructor, the "format" property is used for the extension
   */
  extension: string;

  /**
   * Whether or not the option is disabled
   */
  disabled: boolean;

  constructor(label: string, format: string, extension?: string, fileName?: string, disabled = false) {
    this.label = label;
    this.format = format;
    if (isNotEmpty(extension)) {
      this.extension = extension;
    } else {
      this.extension = this.format;
    }
    this.fileName = fileName;
    this.disabled = disabled;
  }

  /**
   * Get the full filename to export content to
   * This combines a name (or defaults to "export") with the format's extension
   * When provided, this method's name parameter takes priority over the object's fileName property
   */
  getFileName(name?: string): string {
    return `${isNotEmpty(name) ? name : (isNotEmpty(this.fileName) ? this.fileName : 'export')}.${this.extension}`;
  }
}

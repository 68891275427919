import { Component, Input, OnDestroy } from '@angular/core';
import { SEARCH_CONFIG_SERVICE } from '../../../../../../../my-dspace-page/my-dspace-page.component';
import { SearchConfigurationService } from '../../../../../../../core/shared/search/search-configuration.service';
import { hasValue } from '../../../../../../empty.util';
import { FormFieldMetadataValueObject } from '../../../../models/form-field-metadata-value.model';
import { PaginationService } from '../../../../../../../core/pagination/pagination.service';
import { AtmireSearchPaginationTabComponent } from '../atmire-search-pagination-tab.component';
import { ExternalSourceService } from '../../../../../../../core/data/external-source.service';
import { ExternalSourceEntry } from '../../../../../../../core/shared/external-source-entry.model';
import { ExternalSourceEntryListElement } from '../models/external-source-entry-list-element.model';
import { PaginationComponentOptions } from '../../../../../../pagination/pagination-component-options.model';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../../../../../../../core/data/remote-data';
import { PaginatedList } from '../../../../../../../core/data/paginated-list.model';
import { PaginatedSearchOptions } from '../../../../../../search/paginated-search-options.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AtmireSearchExternalSourceEntryImportModalComponent } from './external-source-entry-import-modal/atmire-search-external-source-entry-import-modal.component';
import { VocabularyEntryDetail } from '../../../../../../../core/submission/vocabularies/models/vocabulary-entry-detail.model';

@Component({
  selector: 'ds-atmire-search-external-source-tab',
  styleUrls: ['./atmire-search-external-source-tab.component.scss'],
  templateUrl: './atmire-search-external-source-tab.component.html',
  providers: [
    {
      provide: SEARCH_CONFIG_SERVICE,
      useClass: SearchConfigurationService
    }
  ]
})
export class AtmireSearchExternalSourceTabComponent extends AtmireSearchPaginationTabComponent<ExternalSourceEntry> implements OnDestroy {
  @Input() externalSource: string;
  importConfig = {
    buttonLabel: 'atmire.submission.search-modal.external-source.import'
  };

  /**
   * Subscription to the modal's importedObject event-emitter
   */
  importObjectSub: Subscription;

  constructor(protected modalService: NgbModal,
              protected searchService: SearchConfigurationService,
              protected paginationService: PaginationService,
              protected externalSourceService: ExternalSourceService) {
    super(searchService, paginationService);
  }

  findObjects(query: string, pagination: PaginationComponentOptions): Observable<RemoteData<PaginatedList<ExternalSourceEntry>>> {
    return this.externalSourceService.getExternalSourceEntries(this.externalSource, new PaginatedSearchOptions({
      query,
      pagination,
    }));
  }

  getSelectedObjects(object: ExternalSourceEntry): FormFieldMetadataValueObject[] {
    return this.selectedValues.filter((value) => hasValue(value) && value.authority === object.id);
  }

  getValueFromObject(object: ExternalSourceEntry): string {
    return object.display;
  }

  createListElement(object: ExternalSourceEntry, values: string[], selected: boolean): ExternalSourceEntryListElement {
    return new ExternalSourceEntryListElement(object, values, selected);
  }

  import(element) {
    const modalRef = this.modalService.open(AtmireSearchExternalSourceEntryImportModalComponent, {
      size: 'lg',
      container: 'ds-dynamic-atmire-search-modal'
    });
    const modalComp = modalRef.componentInstance;
    modalComp.externalSourceEntry = element.object;
    modalComp.vocabulary = this.model.vocabularyOptions.name;
    this.importObjectSub = modalComp.importedObject.subscribe((entryDetail: VocabularyEntryDetail) => {
      element.authority = entryDetail.authority;
      element.selected = true;
      this.selectElement(element);
    });
  }

  ngOnDestroy(): void {
    if (hasValue(this.importObjectSub)) {
      this.importObjectSub.unsubscribe();
    }
  }
}

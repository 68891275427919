<div class="w-100 pt-3 pb-3">
  <div class="row">
  <form class="w-100 pt-3 pb-3" [formGroup]="formData" (ngSubmit)="submitForm(formData.value)">
    <div class="form-group input-group col-12 mb-2 search-form">
      <input class="form-control" placeholder="{{'browse.startsWith.type_date' | translate}}" [attr.aria-label]="'browse.startsWith.type_date.label' |translate" type="text" name="startsWith" formControlName="startsWith" [value]="getStartsWith() ? getStartsWith() : ''" />
      <span class="input-group-append">
        <button class="btn btn-dark" type="submit">
          <img src="assets/images/search.svg"> {{'browse.startsWith.submit' | translate}}
        </button>
      </span>
    </div>
  </form>
    <div class="col-6 col-md-2 pr-0">
      <div ngbDropdown placement="bottom-left">
        <button class="form-control d-flex justify-content-between" id="chooseStartYear" ngbDropdownToggle
                [title]="'browse.startsWith.choose_start' | translate"
                [attr.aria-label]="'browse.startsWith.choose_start' | translate"
        >
          {{ startsWithYear ? startsWithYear : 'browse.startsWith.choose_year' | translate}}
          <img class="align-self-center" src="assets/images/chevron-down.png" alt="">
        </button>
        <div id="chooseStartYearDropdown" aria-labelledby="chooseStartYear" ngbDropdownMenu>
          <button class="dropdown-item btn-light" (click)="setStartsWith(undefined)">
            <i [ngClass]="{'invisible': startsWithYear}" class="fas fa-check" aria-hidden="true"></i>
            {{'browse.startsWith.choose_year' | translate}}          </button>
          <button class="dropdown-item btn-light" *ngFor="let option of startsWithOptions" (click)="setStartsWithYearEvent(option)">
            <i [ngClass]="{'invisible': option != startsWithYear}" class="fas fa-check" aria-hidden="true"></i>
            {{ option }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-6 col-md-2">
      <div ngbDropdown placement="bottom-left">
        <button class="form-control d-flex justify-content-between" id="chooseStartMonth" ngbDropdownToggle
                [title]="'browse.startsWith.choose_start' | translate"
                [attr.aria-label]="'browse.startsWith.choose_start' | translate"
        >
          {{ (startsWithMonth ? 'browse.startsWith.months.' + startsWithMonth : 'browse.startsWith.months.none.label') | translate}}
          <img class="align-self-center" src="assets/images/chevron-down.png" alt="">
        </button>
        <div id="chooseStartMonthDropdown" aria-labelledby="chooseStartMonth" ngbDropdownMenu>
          <button class="dropdown-item btn-light" *ngFor="let option of monthOptions" (click)="setStartsWithMonthEvent(option)">
            <i [ngClass]="{'invisible': option != startsWithMonth}" class="fas fa-check" aria-hidden="true"></i>
            {{'browse.startsWith.months.' + option | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<nav [ngClass]="{'open': !(menuCollapsed | async)}"
     [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
     class="navbar navbar-light navbar-expand-md p-md-0 navbar-container"
     role="navigation" [attr.aria-label]="'nav.main.description' | translate">    <!-- TODO remove navbar-container class when https://github.com/twbs/bootstrap/issues/24726 is fixed -->
    <div class="container d-flex">
        <div class="reset-padding-md flex-grow-1">
            <div id="collapsingNav">
                <ul class="navbar-nav mr-auto shadow-none">
                  <ng-container *ngFor="let section of (sections | async)">
                    <ng-container
                      *ngComponentOutlet="(sectionMap$ | async).get(section.id).component; injector: (sectionMap$ | async).get(section.id).injector;"></ng-container>
                  </ng-container>
      <!--        <li class="nav-item d-flex flex-column justify-content-center h-100">-->
      <!--          <ds-atmire-saved-item-list-link></ds-atmire-saved-item-list-link>-->
      <!--        </li>-->
              </ul>
            </div>
        </div>
    </div>
</nav>


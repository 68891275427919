import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../../core/shared/item.model';

@Component({
  selector: 'ds-combined-metadata-authority-list',
  styleUrls: ['./combined-metadata-authority-list.component.scss'],
  templateUrl: './combined-metadata-authority-list.component.html'
})
export class CombinedMetadataAuthorityListComponent implements OnInit {

  @Input() item: Item;
  @Input() fields: string[];
  @Input() label: string;
  @Input() separator: string;

  combinedValues: string[];

  ngOnInit(): void {
    this.combinedValues = this.fields
      .map((field) => this.item.allMetadata(field).map((metadataValue) => metadataValue.authority))
      .reduce((existingAuthorities, newAuthorities) => [...new Set([...existingAuthorities, ...newAuthorities])])
      .map((authority) => this.fields
        .map((field) => this.item
          .allMetadata(field)
          .find((metadataValue) => metadataValue.authority === authority)
        )
        .filter((metadataValue) => !!metadataValue)
        .map((metadataValue) => metadataValue.value)
        .join(this.separator)
      );
  }
}

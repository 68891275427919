import { autoserialize, autoserializeAs, deserialize, inheritSerialization } from 'cerialize';
import { typedObject } from '../../core/cache/builders/build-decorators';
import { HALResource } from '../../core/shared/hal-resource.model';
import { USAGE_STATISTICS_TABLE_CONFIG } from './usage-report.resource-type';
import { HALLink } from '../../core/shared/hal-link.model';
import { UsageStatisticsTableFilterConfig } from './usage-statistics-table-filter-config.model';
import { UsageStatisticsTableGroupingConfig } from './usage-statistics-table-grouping-config.model';
import { UsageStatisticsTableRowTypeInformation } from './usage-statistics-table-row-type-information.model';

@typedObject
@inheritSerialization(HALResource)
export class UsageStatisticsTableConfig extends HALResource {

  static type = USAGE_STATISTICS_TABLE_CONFIG;

  type = USAGE_STATISTICS_TABLE_CONFIG;

  @autoserialize
  name: string;

  @autoserialize
  rowTypes: string[];

  @autoserializeAs(UsageStatisticsTableFilterConfig)
  filters: UsageStatisticsTableFilterConfig[];

  @autoserializeAs(UsageStatisticsTableGroupingConfig)
  groups: UsageStatisticsTableGroupingConfig[];

  @autoserializeAs(UsageStatisticsTableRowTypeInformation)
  rowTypeInformation: UsageStatisticsTableRowTypeInformation[];

  @deserialize
  _links: {
    self: HALLink;
  };
}

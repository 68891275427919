import { Component } from '@angular/core';
import { OrgUnitSearchResultListElementComponent as BaseComponent } from '../../../../../../../../app/entity-groups/research-entities/item-list-elements/search-result-list-elements/org-unit/org-unit-search-result-list-element.component';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';

@listableObjectComponent('OrgUnitSearchResult', ViewMode.ListElement)
@Component({
  selector: 'ds-org-unit-search-result-list-element-unsw',
  // styleUrls: ['../../../../../../../../app/entity-groups/research-entities/item-list-elements/search-result-list-elements/org-unit/org-unit-search-result-list-element.component.scss'],
  styleUrls: ['../../../../../shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
  // templateUrl: '../../../../../../../../app/entity-groups/research-entities/item-list-elements/search-result-list-elements/org-unit/org-unit-search-result-list-element.component.html'
  templateUrl: './org-unit-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Organisation Unit
 */
export class OrgUnitSearchResultListElementComponent extends BaseComponent {
}

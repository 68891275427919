import { DataService } from '../../../../../core/data/data.service';
import { Statlet } from '../models/statlet.model';
import { RequestService } from '../../../../../core/data/request.service';
import { RemoteDataBuildService } from '../../../../../core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../../../core/core.reducers';
import { ObjectCacheService } from '../../../../../core/cache/object-cache.service';
import { HALEndpointService } from '../../../../../core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../../../../core/data/default-change-analyzer.service';
import { Injectable } from '@angular/core';
import { dataService } from '../../../../../core/cache/builders/build-decorators';
import { STATLET } from '../models/statlet.resource-type';
import { Observable, of as observableOf } from 'rxjs';
import { FollowLinkConfig } from '../../../../../shared/utils/follow-link-config.model';
import { RemoteData } from '../../../../../core/data/remote-data';
import { FindListOptions } from '../../../../../core/data/request.models';
import { PaginatedList } from '../../../../../core/data/paginated-list.model';
import { StatletReportType } from '../models/statlet-report-type.model';
import { StatletPosition } from '../models/statlet-position.model';
import { SiteDataService } from '../../../../../core/data/site-data.service';
import { isNotEmpty } from '../../../../../shared/empty.util';
import { map, switchMap } from 'rxjs/operators';
import { RequestParam } from '../../../../../core/cache/models/request-param.model';
import { StatletConfig } from '../models/statlet-config.model';
import { HideStatletsAction, ShowStatletsAction } from '../store/statlet.actions';
import { AppState } from '../../../../../app.reducer';
import { statletStateSelector } from '../store/statlet.selector';

/**
 * A private DataService implementation to delegate specific methods to.
 */
/* tslint:disable:max-classes-per-file */
class DataServiceImpl extends DataService<Statlet> {
  protected linkPath = 'statlets';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<Statlet>) {
    super();
  }
}

@Injectable()
@dataService(STATLET)
export class StatletDataService {
  /**
   * A private DataService instance to delegate specific methods to.
   */
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected appStore: Store<AppState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<Statlet>,
    protected siteService: SiteDataService) {
    this.dataService = new DataServiceImpl(requestService, rdbService, null, objectCache, halService, notificationsService, http, comparator);
  }

  /**
   * Find a report through a configuration {@link StatletConfig}
   * The configuration contains the DSO ID and {@link StatletReportType}, as well as some other optional configuration
   * @param config
   */
  findReport(config: StatletConfig): Observable<RemoteData<Statlet>> {
    return this.halService.getEndpoint(this.dataService.getLinkPath()).pipe(
      map((href) => config.toSearchHref(href)),
      switchMap((href) => this.findByHref(href)),
    );
  }

  /**
   * Search for {@link Statlet}s by object uri
   * @param objectHref    The self-link of the object to display stats for
   * @param position      The position of the {@link Statlet} (optional)
   * @param excludePoints Whether or not to exclude points from the report
   * @param reportTypes   Allowed {@link StatletReportType}s, will return all types if omitted
   */
  searchByObject(objectHref?: string, position?: StatletPosition, excludePoints = false, ...reportTypes: StatletReportType[]): Observable<RemoteData<PaginatedList<Statlet>>> {
    let href$;
    if (isNotEmpty(objectHref)) {
      href$ = observableOf(objectHref);
    } else {
      href$ = this.siteService.find().pipe(map((site) => site.self));
    }
    return href$.pipe(
      switchMap((href) => {
        const params = [ new RequestParam('uri', href) ];
        if (excludePoints) {
          params.push(new RequestParam('excludePoints', excludePoints));
        }
        if (isNotEmpty(position)) {
          params.push(new RequestParam('position', position));
        }
        if (isNotEmpty(reportTypes)) {
          params.push(...reportTypes.map((reportType) => new RequestParam('reportType', reportType)));
        }
        return this.dataService.searchBy('object', { searchParams: params });
      })
    );
  }

  /**
   * Returns an observable of {@link RemoteData} of an object, based on an href, with a list of
   * {@link FollowLinkConfig}, to automatically resolve {@link HALLink}s of the object
   * @param href                        The url of object we want to retrieve
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param linksToFollow               List of {@link FollowLinkConfig} that indicate which
   *                                    {@link HALLink}s should be automatically resolved
   */
  findByHref(href: string | Observable<string>, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: FollowLinkConfig<Statlet>[]): Observable<RemoteData<Statlet>> {
    return this.dataService.findByHref(href, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }

  /**
   * Returns a list of observables of {@link RemoteData} of objects, based on an href, with a list
   * of {@link FollowLinkConfig}, to automatically resolve {@link HALLink}s of the object
   * @param href                        The url of object we want to retrieve
   * @param findListOptions             Find list options object
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param linksToFollow               List of {@link FollowLinkConfig} that indicate which
   *                                    {@link HALLink}s should be automatically resolved
   */
  findAllByHref(href: string | Observable<string>, findListOptions: FindListOptions = {}, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: FollowLinkConfig<Statlet>[]): Observable<RemoteData<PaginatedList<Statlet>>> {
    return this.dataService.findAllByHref(href, findListOptions, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }

  /**
   * Dispatch a ShowStatletsAction
   */
  showStatlets() {
    this.store.dispatch(new ShowStatletsAction());
  }

  /**
   * Dispatch a HideStatletsAction
   */
  hideStatlets() {
    this.store.dispatch(new HideStatletsAction());
  }

  /**
   * Are statlets currently shown?
   */
  statletsShown(): Observable<boolean> {
    return this.appStore.select(statletStateSelector).pipe(
      map((state) => state.show)
    );
  }
}
/* tslint:enable:max-classes-per-file */

<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'publication.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'publication.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-6">
    <div class="mb-2">
      <a *ngIf="hasFiles() | async" [routerLink]="" (click)="onClick('file-section')">
        {{"item.page.link.download" | translate}}
      </a>
    </div>
    <ng-container *ngIf="!mediaViewer.image">
      <ds-item-page-rights-field [item]="object"
                                 [fields]="['dcterms.accessRights', 'unsw.description.embargoNote', 'unsw.description.publisherLicense', 'dcterms.rights', 'dcterms.rightsHolder']"
                                 [label]="'item.page.accessTerms'">
      </ds-item-page-rights-field>
      <ds-item-page-uri-separate-value-field [item]="object"
                                             [fieldURI]="'dc.rights.uri'"
                                             [fieldValue]="'dc.rights'"
                                             [label]="">
      </ds-item-page-uri-separate-value-field>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.author']"
                                [label]="'item.page.author'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.advisor']"
                                [label]="'item.page.supervisor'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.creator']"
                                [label]="'item.page.creator'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.editor']"
                                [label]="'item.page.editor'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.translator']"
                                [label]="'item.page.translator'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.curator']"
                                [label]="'item.page.curator'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.designer']"
                                [label]="'item.page.designer'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.arranger']"
                                [label]="'item.page.arranger'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.composer']"
                                [label]="'item.page.composer'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.recordist']"
                                [label]="'item.page.recordist'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.confProcEditor']"
                                [label]="'item.page.confProcEditor'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.other']"
                                [label]="'item.page.otherContributor'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.contributor.corporate']"
                                [label]="'item.page.corporateContributor'">
    </ds-generic-item-page-field>
  </div>

  <div class="col-xs-12 col-md-4">
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.identifier.uri']"
                            [label]="'item.page.permalink'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object" [prefixValue]="'http://dx.doi.org/'"
                            [addPrefixIfDoesNotContains]="['doi.org']"
                            [fields]="['unsw.identifier.doi']"
                            [label]="'item.page.doi'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object" [prefixValue]="'http://dx.doi.org/'"
                            [addPrefixIfDoesNotContains]="['doi.org']"
                            [fields]="['unsw.identifier.doiPublisher']"
                            [label]="'item.page.publisherVersion'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.description.uri']"
                            [label]="'item.page.additionalLink'">
    </ds-item-page-uri-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.date.issued']"
                                [label]="'item.page.publicationDate'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.type']"
                                [label]="'item.page.type'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['unsw.thesis.degreetype']"
                                [label]="'item.page.degreeType'">
    </ds-generic-item-page-field>
      <ds-related-entities-metadata-via-search [item]="object"
                                         [relationType]="{
                                            label: 'item.page.relationships.isPublicationOfAffiliatedOrgUnit',
                                            filter: 'isPublicationOfAffiliatedOrgUnit',
                                            configuration: 'orgunit'
                                          }">
      </ds-related-entities-metadata-via-search>

  </div>

  <div class="col-12">

    <!-- Related Datasets -->

    <ds-metadata-representation-list
      [parentItem]="object"
      [itemType]="'Dataset'"
      [metadataFields]="['unsw.isDatasetRelatedToPublication']"
      [label]="'relation.isDatasetRelatedToPublication' | translate">
    </ds-metadata-representation-list>
  </div>

  <div class="col-12">
    <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
    <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.license-files" bundle="LICENSE" [item]="object"></ds-item-page-file-section>
    <ds-item-page-file-section *ngIf="isAdmin | async" label="item.page.administration-files" bundle="ADMINISTRATION" [item]="object"></ds-item-page-file-section>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
    <div class="mt-2">
      <a [href]="['/items/' + object.id + '/statistics']">
        {{"item.page.link.statistics" | translate}}
      </a>
    </div>
  </div>

</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>

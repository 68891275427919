import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a number into a percentage, displaying 3 digits after the decimal point by default
 */
@Pipe({ name: 'dsPercent' })
export class DsPercentPipe extends PercentPipe implements PipeTransform {
  transform(value: any, digitsInfo: string = '1.3-3', locale?: string): string | null {
    return super.transform(value, digitsInfo, locale);
  }
}

import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RelationshipService } from '../../../../core/data/relationship.service';
import { Item } from '../../../../core/shared/item.model';
import { AuthorizationDataService } from '../../../../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../../../../core/data/feature-authorization/feature-id';
import { getItemPageRoute } from '../../../item-page-routing-paths';
import { Observable } from 'rxjs';
import { BitstreamDataService } from '../../../../core/data/bitstream-data.service';
import { BundleDataService } from '../../../../core/data/bundle-data.service';

@Component({
  selector: 'ds-item',
  template: ''
})
/**
 * A generic component for displaying metadata and relations of an item
 */
export class ItemComponent implements OnInit {
  @Input() object: Item;

  /** Defines whether or not the current user is a site wide administrator */
  isAdmin: Observable<boolean>;

  /**
   * Route to the item page
   */
  itemPageRoute: string;

  mediaViewer = environment.mediaViewer;

  constructor(protected bitstreamDataService: BitstreamDataService,
              protected viewportScroller: ViewportScroller,
              protected relationshipService: RelationshipService,
              protected authorizationService: AuthorizationDataService,
              protected bundleService: BundleDataService,
  ) {
  }

  ngOnInit(): void {
    this.isAdmin = this.authorizationService.isAuthorized(FeatureID.AdministratorOf);
    this.itemPageRoute = getItemPageRoute(this.object);
  }

  /**
   * Scroll to anchor in tha page, requires anchorScrolling: 'enabled' in routing module
   * @param elementId id of element on page (without hash)
   */
  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  /**
   * Whether or not this item has files (i.e. bitstreams in the ORIGINAL bundle)
   */
  public hasFiles(): Observable<boolean> {
    return this.bitstreamDataService.hasFiles(this.object);
  }
}

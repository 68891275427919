import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { DatasetListElementComponent as BaseComponent } from '../../../../../../../app/entity-groups/research-entities/item-list-elements/dataset/dataset-list-element.component';

@listableObjectComponent('Dataset', ViewMode.ListElement)
@Component({
  selector: 'ds-dataset-list-element',
  // templateUrl: '../../../../../../../app/entity-groups/research-entities/item-list-elements/dataset/dataset-list-element.component.html',
  templateUrl: './dataset-list-element.component.html',
})
/**
 * The component for displaying a list element for an item of the type Dataset
 */
export class DatasetListElementComponent extends BaseComponent {
}

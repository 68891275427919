import { Component, Input, OnInit } from '@angular/core';
import { hasValue } from '../../../shared/empty.util';

import { MetadataValuesComponent } from '../metadata-values/metadata-values.component';
import { MetadataValue } from '../../../core/shared/metadata.models';

/**
 * This component renders the configured 'values' into the ds-metadata-field-wrapper component as a link.
 * It puts the given 'separator' between each two values
 * and creates an 'a' tag for each value,
 * using the 'linktext' as it's value (if it exists)
 * and using the values as the 'href' attribute (and as value of the tag when no 'linktext' is defined)
 */
@Component({
  selector: 'ds-metadata-uri-values',
  styleUrls: ['./metadata-uri-values.component.scss'],
  templateUrl: './metadata-uri-values.component.html'
})
export class MetadataUriValuesComponent extends MetadataValuesComponent implements OnInit {

  /**
   * Optional text to replace the links with
   * If undefined, the metadata value (uri) is displayed
   */
  @Input() linktext: any;

  /**
   * The metadata values to display
   */
  @Input() mdValues: MetadataValue[];

  /**
   * The separator used to split the metadata values (can contain HTML)
   */
  @Input() separator: string;

  /**
   * The label for this iteration of metadata values
   */
  @Input() label: string;

  /**
   * If the actual metadata value needs to be prefixed with something to form the url, prefix with it if original value does not contain any of addPrefixIfDoesNotContains
   * Ex: addPrefixIfDoesNotContains=['http://dx.doi.org/', 'doi.org'] => If the original value contains none of these, the prepend original value with prefix
   */
  @Input() prefixValue: string;

  /**
   * If the actual metadata value needs to be prefixed with something to form the url, prefix with first values, if none of the values are present
   * Ex: addPrefixIfDoesNotContains=['http://dx.doi.org/', 'doi.org'] => If the original value contains none of these, the prepend original value with prefix
   */
  @Input() addPrefixIfDoesNotContains: string[];

  resultingLinks: string[];

  ngOnInit(): void {
    this.resultingLinks = this.mdValues.map((mdValue: MetadataValue) => {
      return this.getUrlWithPrefix(mdValue);
    });
  }

  /**
   * The url prepended with prefix if it is allowed
   * @param mdValue MdValue whose value will  to possibly prepend with value
   */
  public getUrlWithPrefix(mdValue: MetadataValue): string {
    let resultingValue = mdValue.value;
    if (this.shouldAddPrefix(resultingValue)) {
      resultingValue = this.prefixValue + mdValue.value;
    }
    return resultingValue;
  }

  /**
   * Checks to see whether or not to prepend value with prefix; not to when:
   * - this.prefixValue is not given
   * - this.prefixValue is already included in value
   * - any of the values in this.addPrefixIfDoesNotContains is included in value
   * @param value Given value to check if to prepend with prefix
   * @return True if value should be prepended with prefix, otherwise false@
   */
  private shouldAddPrefix(value: string): boolean {
    let shouldAddPrefix = true;
    if (!hasValue(this.prefixValue) || value.includes(this.prefixValue)) {
      shouldAddPrefix = false;
    }
    if (hasValue(this.addPrefixIfDoesNotContains)) {
      this.addPrefixIfDoesNotContains.forEach((shouldNotContain: string) => {
        if (value.includes(shouldNotContain)) {
          shouldAddPrefix = false;
        }
      });
    }
    return shouldAddPrefix;
  }
}

import { Component } from '@angular/core';
import { SingleStatletWrapperComponent } from '../single-statlet-wrapper.component';
import { DEFAULT_STATLET_WRAPPER_CONTEXT, rendersSingleStatletWrapper } from '../single-statlet-wrapper.decorator';

@Component({
  selector: 'ds-atmire-cua-single-statlet-card',
  templateUrl: './single-statlet-card.component.html',
  styleUrls: ['./single-statlet-card.component.scss']
})
/**
 * Component displaying a single statlet in a card
 * This component displays a card containing an icon depending on the graph-type of the statlet and loads the correct
 * statlet component within the card's body depending on the statlet's graph-type
 */
@rendersSingleStatletWrapper(DEFAULT_STATLET_WRAPPER_CONTEXT)
export class SingleStatletCardComponent extends SingleStatletWrapperComponent {
  showListView = false;
}

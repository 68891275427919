<ng-container *ngVar="bannerSrc | async as src">
  <div [class]="src ? 'item-page-banner' : 'item-page-graphic'" [style.background-image]=" src ? 'url(' + src + ')' : 'url(/assets/images/item-view-page-header-graphic-left-aligned.png)'">
    <div class="item-page-header d-flex flex-column justify-content-end">
      <div class="container">
        <div class="d-flex flex-row">
          <h2 class="item-page-title-field mr-auto">
            <ds-metadata-values class="d-inline-block" [mdValues]="object?.allMetadata(['dc.title', 'organization.legalName'])"></ds-metadata-values>
          </h2>
          <div class="pl-2 d-flex flex-row">
            <ds-dso-page-edit-button class="pl-1" [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'orgunit.page.edit'"></ds-dso-page-edit-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="row">
  <div class="w-100">
    <ds-tabbed-related-entities-search [item]="object"
                                       [relationTypes]="[{
                                          label: 'search.isAffiliatedOrgUnitOfPublication',
                                          filter: 'isAffiliatedOrgUnitOfPublication',
                                          configuration: 'publication'
                                        },
                                        {
                                          label: 'search.isAffiliatedOrgUnitOfDataset',
                                          filter: 'isAffiliatedOrgUnitOfDataset',
                                          configuration: 'dataset'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
  <div class="mt-4">
    <ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
  </div>
</div>

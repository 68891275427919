import { AtmireSearchFieldParser } from './atmire-search-field-parser';
import { FormFieldMetadataValueObject } from '../models/form-field-metadata-value.model';
import {
  DynamicAtmireSearchNameModel,
  DynamicAtmireSearchNameModelConfig
} from '../ds-dynamic-form-ui/models/atmire-search/dynamic-atmire-search-name.model';

export class AtmireSearchNameFieldParser extends AtmireSearchFieldParser {

  public modelFactory(fieldValue?: FormFieldMetadataValueObject | any, label?: boolean): any {
    if (this.configData.selectableMetadata[0].controlledVocabulary) {
      const searchModelConfig: DynamicAtmireSearchNameModelConfig = this.initModel(null, label);
      this.setConfigOptions(searchModelConfig, fieldValue);
      return new DynamicAtmireSearchNameModel(searchModelConfig);
    }
  }

}

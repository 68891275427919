import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ReportSummaryComponent } from './report-summary/report-summary.component';
import { ValuePairsSelectorComponent } from './value-pairs/value-pairs-selector/value-pairs-selector.component';
import { AtmireValuePairDataService } from './value-pairs/atmire-value-pair-data.service';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
  ],
  declarations: [
    ReportSummaryComponent,
    ValuePairsSelectorComponent,
  ],
  exports: [
    ReportSummaryComponent,
    ValuePairsSelectorComponent,
  ],
  providers: [
    AtmireValuePairDataService,
  ],
})
export class SharedReportsModule {
}

import { Injectable } from '@angular/core';
import { combineLatest as observableCombineLatest, Observable, of as observableOf } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { TakedownService } from '../../core/data/takedown.service';
import { RemoteData } from '../../core/data/remote-data';
import { ITEM_PAGE_LINKS_TO_FOLLOW } from '../item.resolver';
import { getFirstCompletedRemoteData } from '../../core/shared/operators';
import { Item } from '../../core/shared/item.model';
import { hasValue } from '../../shared/empty.util';
import { getItemTakeDownPath } from '../item-page-routing-paths';
import { ItemDataService } from '../../core/data/item-data.service';
import { FeatureID } from '../../core/data/feature-authorization/feature-id';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';

/**
 * A guard redirecting users to the takedown page when user is not admin and the item has a takedown at
 * /server/api/core/takedowns/<:itemID>
 */
@Injectable()
export class TakedownGuard implements CanActivate {

  constructor(protected itemService: ItemDataService,
              protected takedownService: TakedownService,
              protected router: Router,
              protected authorizationService: AuthorizationDataService) {
  }

  /**
   * True when user is admin, the item is not withdrawn or has no takedown at /server/api/core/takedowns/<:itemID>
   * Redirects to takedown page if item does have a takedown and user is not admin
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return observableCombineLatest(
      this.authorizationService.isAuthorized(FeatureID.AdministratorOf),
      this.isTakenDownItem(route.params.id)
    ).pipe(
      map(([admin, isTakenDownItem]: [boolean, boolean]) => {
        if (admin) {
          return true;
        } else {
          if (isTakenDownItem) {
            // Redirect to takedown page
            this.router.navigate([getItemTakeDownPath(route.params.id)], {skipLocationChange: true});
            return false;
          } else {
            return true;
          }
        }
      })
    );
  }

  /**
   * Whether or not the item from id is taken down
   * @param id  item id to check whether or not is taken down
   */
  isTakenDownItem(id): Observable<boolean> {
    return this.itemService.findById(id, true, false, ...ITEM_PAGE_LINKS_TO_FOLLOW
    ).pipe(
      getFirstCompletedRemoteData(),
      switchMap((itemRD: RemoteData<Item>) => {
          if (itemRD.hasSucceeded && hasValue(itemRD.payload) && itemRD.payload.isWithdrawn) {
            return this.takedownService.isTakenDown(id).pipe(
              map((isTakenDown: boolean) => {
                return isTakenDown;
              })
            );
          } else {
            return observableOf(false);
          }
        }
      )
    );
  }

}

import { Component, Input, SimpleChanges } from '@angular/core';
import { PaginationComponentOptions } from '../../shared/pagination/pagination-component-options.model';
import { SortOptions } from '../../core/cache/models/sort-options.model';
import { RemoteData } from '../../core/data/remote-data';
import { ListableObject } from '../../shared/object-collection/shared/listable-object.model';
import { PaginatedList } from '../../core/data/paginated-list.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { getAllCompletedRemoteData } from '../../core/shared/operators';
import { TranslateService } from '@ngx-translate/core';
import { hasValue } from '../../shared/empty.util';
import { fadeIn } from '../../shared/animations/fade';
import { of as observableOf } from 'rxjs/internal/observable/of';
import { AbstractObjectCollectionWrapperComponent } from '../base/abstract-object-collection-wrapper.component';
import { ListableObjectSourceFactoryService } from '../sources/listable-object-source-factory.service';

const TRACK_CHANGES_TO = [
  'sourceModel',
  'paginationOptions',
  'sortOptions',
];

@Component({
  selector: 'ds-atmire-show-more',
  templateUrl: './atmire-show-more.component.html',
  styleUrls: ['./atmire-show-more.component.scss'],
  animations: [fadeIn],
})
/**
 * Shows object collections in a list or grid view with optional "show more" and "collapse" buttons for pagination.
 */
export class AtmireShowMoreComponent extends AbstractObjectCollectionWrapperComponent {
  /**
   * Configures the pagination of the component. With a maxSize of 1, pagination is disabled.
   */
  @Input() paginationOptions: Partial<PaginationComponentOptions>;

  /**
   * Configures the sorting of the component.
   */
  @Input() sortOptions: Partial<SortOptions>;

  isLastPage: boolean;
  currentPage: number;
  results$: BehaviorSubject<ListableObject[]>;

  constructor(
    protected sourceFactory: ListableObjectSourceFactoryService,
    protected translateService: TranslateService,
  ) {
    super(sourceFactory, translateService);
  }

  /**
   * This method will retrieve the next page of search results from the external SearchService#search call.
   * It'll retrieve the currentPage from the class variables and it'll add the next page to the already existing one.
   * If the currentPage variable is undefined, we'll set it to 1 and retrieve the first page.
   *
   * Adapted from FileSectionComponent#getNextPage()
   */
  showMore(): void {
    this.isLoading$.next(true);
    this.error$ = observableOf(undefined);

    if (this.currentPage === undefined) {
      this.currentPage = 1;
      this.results$ = new BehaviorSubject([]);
    } else {
      this.currentPage++;
    }

    this.subs.push(
        this.source.getList(
          { ...this.paginationOptions, currentPage: this.currentPage },
          this.sortOptions,
        ).pipe(
        getAllCompletedRemoteData(),
      ).subscribe((resultRD: RemoteData<PaginatedList<ListableObject>>) => {
        if (resultRD.errorMessage) {
          this.setError('atmire.object-collection.error.backend');
        } else if (hasValue(resultRD.payload)) {
          this.pageChange.emit(resultRD);

          const renderableResults: any[] = resultRD.payload.page.filter((listableObject: ListableObject) => {
            try {
              listableObject.getRenderTypes();
              return true;
            } catch (e) {
              return false;
            }
          });

          this.results$.next([
            ...this.results$.getValue(),
            ...renderableResults,
          ]);

          if (renderableResults.length === 0  && resultRD.payload.page.length !== 0) {
            this.setError('atmire.object-collection.error.rendering-all');
          } else if (renderableResults.length < resultRD.payload.page.length) {
            this.setError('atmire.object-collection.error.rendering-some');
          }

          this.isLastPage = this.currentPage === this.paginationOptions.maxSize
                         || this.currentPage === resultRD.payload.totalPages;

        }
        this.isLoading$.next(false);
      })
    );
  }

  collapse(): void {
    this.currentPage = undefined;
    this.showMore();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (Object.keys(changes).some(k => TRACK_CHANGES_TO.includes(k))) {
      this.collapse();
    }
  }
}

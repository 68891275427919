import { Component } from '@angular/core';
import { MetadataValuesComponent } from '../metadata-values.component';

/**
 * This component renders the configured 'values' into the ds-metadata-field-wrapper component.
 * It puts the given 'separator' between each two values.
 */
@Component({
  selector: 'ds-truncatable-metadata-values',
  styleUrls: ['../metadata-values.component.scss'],
  templateUrl: './truncatable-metadata-values.component.html'
})
export class TruncatableMetadataValuesComponent extends MetadataValuesComponent {

}

<div class="d-flex">
  <div class="flex-grow-1">
    <span class="text-muted">
        <span *ngIf="dso.allMetadata('dc.description.abstract').length > 0"
              class="item-list-description">
                    <span [innerHTML]="firstMetadataValue(['dc.description.abstract'])">
                      <span [innerHTML]="firstMetadataValue(['dc.description.abstract'])"></span>
                    </span>
          <span *ngIf="dso.allMetadata('dc.description.abstract').length > 0">, </span>
        </span>
    </span>
  </div>
</div>

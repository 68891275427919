import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ItemComponent } from '../../../../../../../app/item-page/simple/item-types/shared/item.component';

@listableObjectComponent(Item, ViewMode.StandalonePage, Context.Any, 'unsw')
@Component({
  selector: 'ds-untyped-item',
  styleUrls: ['./untyped-item.component.scss'],
  templateUrl: './untyped-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UntypedItemComponent extends ItemComponent {
  showAltmetricDonut = false;
  @ViewChild('statletsSection') statletsSectionRef: ElementRef;
}

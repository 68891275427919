import { MenuItemModel } from './models/menu-item.model';
import { Observable } from 'rxjs';
import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { isNotEmpty } from '../../empty.util';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'ds-menu-component',
    template: ''
})
export abstract class MenuItemComponent<T extends MenuItemModel> {
    isActive$: Observable<boolean>;
    item: T;

    constructor(
        @Inject('itemModelProvider') item: T,
        public router: Router
    ) {
        this.item = item;
    }

    ngOnInit(): void {
        this.isActive$ = this.router.events.pipe(
            filter((val: RouterEvent) => val instanceof NavigationEnd),
            startWith({urlAfterRedirects: this.router.url} as NavigationEnd),
            map((val: NavigationEnd) => {
                if (isNotEmpty(this.item.activeRegex)) {
                    return this.item.activeRegex.some((pageRegex: RegExp) => val.urlAfterRedirects.match(Object.assign(cloneDeep(pageRegex))));
                }
                return false;
            })
        );
    }
}

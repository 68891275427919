<div class="card" [hidden]="!statlet.graphtype || !hasData" dsExportAsImage
     [fileName]="statlet.shortName">
  <div class="card-header d-flex align-items-baseline">
    <i class="fa fa-{{ statlet.graphtype.fa }} mr-2"></i>
    <span class="flex-grow-1">{{ 'statlets.type.' + statlet.shortName + '.title' | translate }}</span>
    <div ngbDropdown placement="bottom-right">
      <button ngbDropdownToggle type="button" class="btn btn-light btn-sm px-1 no-caret">
        <i class="fas fa-fw fa-ellipsis-v"></i>
      </button>
      <div ngbDropdownMenu>
        <button dsExportAsImageTrigger
                ngbDropdownItem>{{'statlets.options.export-image' | translate}}</button>
      </div>
    </div>
  </div>
  <div class="overflow-auto" dsExportAsImageDom>
    <div class="card-body w-100" #card>
      <ds-atmire-cua-single-statlet-graph-loader [statlet]="statlet"
                                                 [view]="[card.offsetWidth, card.offsetHeight]"
                                                 [context]="context"
                                                 [palette]="palette"
                                                 [size]="size"></ds-atmire-cua-single-statlet-graph-loader>
    </div>
    <div *ngIf="statlet.graphtype.listViewOptions.enabled" class="w-100">
      <button class="btn btn-sm btn-outline-primary float-right m-2"
              (click)="showListView = !showListView">{{ (showListView ? 'statlets.hide-data' : 'statlets.show-data') | translate }}</button>
    </div>
    <div class="w-100 overflow-auto" *ngIf="showListView">
      <ds-atmire-cua-single-statlet-table [statlet]="statlet"
                                          [inverseData]="statlet.graphtype.listViewOptions.inverseData"
                                          [displayColours]="statlet.graphtype.listViewOptions.colours"
                                          [size]="size">
      </ds-atmire-cua-single-statlet-table>
    </div>
  </div>
</div>

<ds-pagination
  [paginationOptions]="config"
  [pageInfoState]="objects?.payload"
  [collectionSize]="objects?.payload?.totalElements"
  [sortOptions]="sortConfig"
  [hideGear]="hideGear"
  [hidePagerWhenSinglePage]="hidePagerWhenSinglePage"
  [hidePaginationDetail]="hidePaginationDetail"
  (pageChange)="onPageChange($event)"
  (pageSizeChange)="onPageSizeChange($event)"
  (sortDirectionChange)="onSortDirectionChange($event)"
  (sortFieldChange)="onSortFieldChange($event)"
  (paginationChange)="onPaginationChange($event)">
    <div class="card-columns row" *ngIf="objects?.hasSucceeded">
      <div class="card-column col col-sm-6 col-lg-3" *ngFor="let column of (columns$ | async)" @fadeIn>
        <div class="card-element" *ngFor="let object of column">
          <ds-listable-object-component-loader [object]="object" [viewMode]="viewMode" [context]="context" [linkType]="linkType"></ds-listable-object-component-loader>
        </div>
      </div>
    </div>
  <ds-error *ngIf="objects.hasFailed" message="{{'error.objects' | translate}}"></ds-error>
  <ds-loading *ngIf="objects.isLoading" message="{{'loading.objects' | translate}}"></ds-loading>
</ds-pagination>


<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ (labelPrefix + externalSourceEntry.externalSource + '.title') | translate }}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4>{{ (labelPrefix + externalSourceEntry.externalSource + '.head' | translate) }}</h4>

  <div id="external-source-entry-information">
    <div><span>{{externalSourceEntry.display}}</span></div>
    <div *ngIf="uri"><a href="{{uri.value}}" target="_blank">{{uri.value}}</a></div>
  </div>
</div>
<div class="modal-footer">
  <div>
    <button type="button" class="btn btn-outline-secondary" (click)="close()">{{ (labelPrefix + 'cancel' | translate) }}</button>
  </div>
  <div>
    <button type="button" class="btn btn-primary" (click)="import()">{{ (labelPrefix + 'import' | translate) }}</button>
  </div>
</div>

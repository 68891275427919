import { FollowLinkConfig } from '../../shared/utils/follow-link-config.model';
import { ListableObject } from '../../shared/object-collection/shared/listable-object.model';
import { CacheableObject } from '../../core/cache/object-cache.reducer';
import { PaginationComponentOptions } from '../../shared/pagination/pagination-component-options.model';
import { SortOptions } from '../../core/cache/models/sort-options.model';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../../core/data/remote-data';
import { PaginatedList } from '../../core/data/paginated-list.model';
import { ListableObjectSourceType } from '../listable-object-sources';

// tslint:disable:max-classes-per-file

export interface AbstractSourceKwargs {
  useCachedVersionIfAvailable?: boolean;
  reRequestOnStale?: boolean;
  linksToFollow?: FollowLinkConfig<ListableObject & CacheableObject>[];
}

/**
 * Extend this class to define object source models.
 */
export abstract class AbstractListableObjectSourceModel {
  readonly source: ListableObjectSourceType;

  useCachedVersionIfAvailable? = true;
  reRequestOnStale? = true;
  linksToFollow?: FollowLinkConfig<ListableObject & CacheableObject>[] = [];

  protected constructor(kwargs?: AbstractSourceKwargs) {
    if (kwargs !== undefined) {
      Object.keys(kwargs).filter(kw => kwargs[kw] !== undefined)
            .forEach((kw: string) => this[kw] = kwargs[kw]);
    }
  }
}

/**
 * Extend this class to define object source implementations.
 */
export abstract class AbstractListableObjectSource {
  readonly source: ListableObjectSourceType;

  protected useCachedVersionIfAvailable? = true;
  protected reRequestOnStale? = true;
  protected linksToFollow?: FollowLinkConfig<ListableObject & CacheableObject>[] = [];

  protected constructor(model?: AbstractListableObjectSourceModel) {
    this.useCachedVersionIfAvailable = model.useCachedVersionIfAvailable;
    this.reRequestOnStale = model.reRequestOnStale;
    this.linksToFollow = model.linksToFollow;
  }

  /**
   * Parameters describing this source's configuration.
   * To be used for i18n interpolation (e.g. when displaying errors to the user)
   */
  abstract get params(): object;

  /**
   * Retrieve a list of objects from an object collection.
   * @param paginationOptions
   * @param sort
   */
  abstract getList(
    paginationOptions: Partial<PaginationComponentOptions>, sort: Partial<SortOptions>,
  ): Observable<RemoteData<PaginatedList<ListableObject>>>;
}

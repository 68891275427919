import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AtmireSearchSelectableListElement } from '../models/atmire-search-selectable-list-element';

@Component({
  selector: 'ds-abstract-atmire-search-list-element',
  template: ''
})
export class AbstractAtmireSearchListElementComponent<T> {
  @Input() element: AtmireSearchSelectableListElement<T>;
  @Output() edit: EventEmitter<AtmireSearchSelectableListElement<T>> = new EventEmitter<AtmireSearchSelectableListElement<T>>();
}

import { NgModule } from '@angular/core';
import { AtmireCuaRoutingModule } from './atmire-cua-routing.module';
import { UsageStatisticsTable } from './usage-reports/usage-statistics-table.model';
import { UsageStatisticsTableConfig } from './usage-reports/usage-statistics-table-config.model';
import { PredefinedReport } from './usage-reports/data/models/predefined-report.model';
import { Statlet } from './statlets/shared/data/models/statlet.model';
import { AtmireValuePair } from './shared-reports/value-pairs/atmire-value-pair.model';

export const models = [
  UsageStatisticsTable,
  UsageStatisticsTableConfig,
  PredefinedReport,
  Statlet,
  AtmireValuePair,
];

@NgModule({
  imports: [
    AtmireCuaRoutingModule,
  ],
})

export class AtmireCuaModule {
}

<div class="d-flex align-items-center">
  <div class="flex-grow-1">
    <ds-truncatable [id]="dso.id">
      <div class="item-list-title-line">
        <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
           rel="noopener noreferrer"
           [routerLink]="['/items/' + dso.id]" class="lead item-list-title"
           [innerHTML]="firstMetadataValue('dc.title')"></a>
        <div class="ml-2">
          <ds-atmire-saved-item-list-control [item]="dso"></ds-atmire-saved-item-list-control>
        </div>
      </div>
      <span *ngIf="linkType == linkTypes.None"
            class="lead"
            [innerHTML]="firstMetadataValue('dc.title')"></span>
      <ds-item-list-repeatable-md-badge [object]="dso" [fields]="['dc.type']"></ds-item-list-repeatable-md-badge>
      <ds-truncatable-part [id]="dso.id" [minLines]="3">
                <span *ngIf="dso.allMetadata(['dc.description.abstract']).length > 0"
                      class="item-list-abstract">
                    <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                      [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
                    </ds-truncatable-part>
                </span>
      </ds-truncatable-part>
    </ds-truncatable>
  </div>
</div>
<hr/>

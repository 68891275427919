import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ThemedComponent } from '../../../../theme-support/themed.component';
import { ExistingRelationListElementComponent } from './existing-relation-list-element.component';
import { Item } from '../../../../../core/shared/item.model';
import { ReorderableRelationship } from '../existing-metadata-list-element/existing-metadata-list-element.component';
import { RelationshipOptions } from '../../models/relationship-options.model';

/**
 * Themed wrapper for ExistingRelationListElementComponent
 */
@Component({
  selector: 'ds-themed-existing-relation-list-element',
  styleUrls: [],
  templateUrl: '../../../../theme-support/themed.component.html',
})
export class ThemedExistingRelationListElementComponent extends ThemedComponent<ExistingRelationListElementComponent> {
  protected inAndOutputNames: (keyof ExistingRelationListElementComponent & keyof this)[] = ['listId', 'submissionItem', 'reoRel', 'metadataFields', 'relationshipOptions', 'submissionId', 'remove'];

  @Input() listId: string;

  @Input() submissionItem: Item;

  @Input() reoRel: ReorderableRelationship;

  @Input() metadataFields: string[];

  @Input() relationshipOptions: RelationshipOptions;

  @Input() submissionId: string;

  @Output() remove: EventEmitter<any> = new EventEmitter();

  protected getComponentName(): string {
    return 'ExistingRelationListElementComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../../../themes/${themeName}/app/shared/form/builder/ds-dynamic-form-ui/existing-relation-list-element/existing-relation-list-element.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import('./existing-relation-list-element.component');
  }
}

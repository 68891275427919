import { autoserialize, autoserializeAs } from 'cerialize';
import {
  StatletGraphWidth,
  StatletGraphWidthSerializer
} from '../../single-statlet/graph-types/statlet-graph-types.model';

export class StatletStyle {
  @autoserializeAs(StatletGraphWidthSerializer)
  width: StatletGraphWidth;

  @autoserialize
  palette: string[];
}
